import Cookies from 'universal-cookie';

import {parseISOString} from './timeHelper';

const cookies = new Cookies();

export function isLogin(reference) {
  //console.log("isLogin()");
  //console.log(reference);

  const cookieToken = cookies.get('token');
  const cookieExpire = cookies.get('expire');
  //console.log(cookieToken);
  //console.log(cookieExpire);
  if ((cookieToken!==undefined)&&(cookieExpire!==undefined)
    &&(cookieToken!=="logout")&&(cookieExpire!=="logout")
    &&(cookieToken!=="")&&(cookieExpire!=="")) {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(cookieExpire);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      reference.props.history.push("/login")
      return false;
    } else {
      return true;
    }
  }

  let login = reference.props.globalLogin;
  if ((login===undefined)
    ||(login.login===undefined)
    ||(login.login.success===false)) {
    reference.props.history.push("/login")
    return false;
  } else {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(login.login.expire);
    //console.log(timestamp);
    //console.log(expireTimestamp);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      reference.props.history.push("/login")
      return false;
    } else {
      return true;
    }
  }
}

export function getToken(reference) {
  //console.log("getToken()");
  //console.log(reference);

  const cookieToken = cookies.get('token');
  const cookieExpire = cookies.get('expire');

  //console.log(cookieToken);
  //console.log(cookieExpire);
  //if (reference===undefined) {
      //return null;
  //}

  if ((cookieToken!==undefined)&&(cookieExpire!==undefined)
    &&(cookieToken!=="logout")&&(cookieExpire!=="logout")
    &&(cookieToken!=="")&&(cookieExpire!=="")) {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(cookieExpire);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      if (reference!==undefined) {
        reference.props.history.push("/login")
      }
      return null;
    } else {
      return cookieToken;
    }
  }

  let login = undefined;
  if (reference!==undefined) {
    login = reference.props.globalLogin;
  }
  if ((login===undefined)
    ||(login.login===undefined)) {
    if (reference!==undefined) {
      reference.props.history.push("/login")
    }
    return null;
  } else {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(login.login.expire);
    //console.log(timestamp);
    //console.log(expireTimestamp);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      if (reference!==undefined) {
        reference.props.history.push("/login")
      }
      return null;
    } else {
      return login.login.token;
    }
  }
}

export function getTokenWithoutLoginRedirect(reference) {
  //console.log("getTokenWithoutLoginRedirect()");
  //console.log(reference);

  const cookieToken = cookies.get('token');
  const cookieExpire = cookies.get('expire');

  //console.log(cookieToken);
  //console.log(cookieExpire);

  if ((cookieToken!==undefined)&&(cookieExpire!==undefined)
    &&(cookieToken!=="logout")&&(cookieExpire!=="logout")
    &&(cookieToken!=="")&&(cookieExpire!=="")) {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(cookieExpire);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      return null;
    } else {
      return cookieToken;
    }
  }

  let login = reference.props.globalLogin;
  if ((login===undefined)
    ||(login.login===undefined)) {
    return null;
  } else {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);

    const expireTimestamp = parseISOString(login.login.expire);
    //console.log(timestamp);
    //console.log(expireTimestamp);
    if (timestamp>=expireTimestamp-300) {
      // expire 時間少於300 秒強制登出 //
      //console.log("expire");
      return null;
    } else {
      return login.login.token;
    }
  }
}

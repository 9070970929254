import React, { Component } from 'react';

/**
 * Renders the preloader
 */
class NewsID extends Component {

	render() {
		return (
			<div>
				<div className="f_single fx_single_tbl">
					<div className="tabset">
						<label htmlFor="tab2">
							<span className="mdc-tab__content fx_titlefont">
								<span className="mdc-tab__text-label">Announcements & News</span>
							</span>
						</label>
					</div>
				</div>

				<div className='swanky_wrapper faq'>

					<input id='latest' name='radio' type='checkbox' />
					<label htmlFor='latest'>
						<span className="th_faq">[Founder’s Notes] on March 9, 2021</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<br /><br />
								Dear Visitors and current Members!
								<br /><br />

								Upcoming! We are planning to create a “Member’s Lounge” page in mid-April soon after finishing the beta testing; and we are adding a few very interesting and “FUN” features exclusively for our members.
								<br /><br />
								It includes prizes every day without any cost to you! You only need to be a member of WREX and you only need to participate every day! So, please stay tuned for further updates on these new features in April.
								<br /><br />
								If you are interested in knowing more about WREX, any questions on WREX and what we do best, how to make best out of WREX and how to maximize your investment, you can initiate chats with me any time – add me on your telegram @wrexfounder - my name is Robert and I am at your service.

								<br /><br /><br />
								Thank you and Best Regards,
								<br /><br />
								W-Rex Management<br />

							</li></ul>
						</div>
					</label>

					<input id='nr20' name='radio' type='checkbox' />
					<label htmlFor='nr20'>
						<span className="th_faq">[Announcement] March 8, 2021 - Changes in WRex Account</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<br />
								Dear Members,
								<br /><br />
									To accommodate the increasing demand, the management has decided to make new policy changes on WRex Account as follows;
									<br /><br />
									[Free Deposit Amounts]
									<br />
									a. members are allowed to deposit any amount into the [WREX ACCOUNT] at any time
									<br /><br />
									[No Minimum Requirements]
									<br />
									b. members are allowed to have less than 1 ETH in the [WREX ACCOUNT]
									<br /><br />
									[No Restriction on Withdrawal]
									<br />
									c. members can withdraw any amount from [WREX ACCOUNT], they do not need to maintain minimum ETH amount which was 1 ETH
									<br /><br />
									So, as of today, there are no restriction on the AMOUNT of ETH for deposits, balance, withdrawals. However, large sum of withdrawals are "not" restricted but the request may take time to fullfill. This is not for us to give you hard time on your withdrawal but as we are operating a mid to long term portfolio we would like to protect everyone's interest.
									<br /><br />
									Thank you for your kind understanding.
									<br />
								<br />
									WRex Management
									<br />
							</li></ul>
						</div>
					</label>

					<input id='nr19' name='radio' type='checkbox' />
					<label htmlFor='nr19'>
						<span className="th_faq">[W-Rex Promotion] Why you should invest in W-Rex even ETH at USD 2,000</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>

							<br /><br />
							&lt;Entry price of an ETH don’t really matter, if you plan your investment with WREX&gt;
<br /><br />
							Yes, I would be lucky if I had bought ETH at USD 150 a year ago. I would be even happier if I would have foreseen this cryptocurrency 5 years ago when ETH was at 70 cents each. Today, the ETH price is closing at USD 2,000!!!
<br /><br />
A great investment, to make a greater return on your investment, in crypto that is, has two possible way. (1) buy low and sell high and (2) buy 10 ETH and sell 50 ETH. Let us explain how.
<br /><br />
You and I know that we both are not experts in trading. In addition, I don’t want to spend too much time worrying about my investment to shrink by looking at the charts all day. Worst, since I am doing the trading myself, I cannot sleep properly at night because I keep waking up and the first thing I do when I wake up is checking the ETH price because the market is running at full speed 24/7 and 365 days.
<ul className="li_simple"><li>
								(1) Buy low and sell high
	<br />
									Easier said than done. You and I cannot control the market price. So, if you are lucky, we make some money by “buy low and sell high”. Most of the time I loose big time and I am sometimes stuck because the price went so low after I made a buy. Hopeless.
</li></ul>
If you cannot control the fate, with WREX we say, let it go. It is not your game. The ETH will go up and down. Do NOT worry about the things beyond your control. Let us worry about it, not you.
<br /><br /><br />
What you and I can control is (2) buy 10 ETH but sell 40 ETH part. How we do it? If you place your ETH with WREX we will trade your ETH in the ETH pairing market. We would increase your number of ETH. If you leave the ETH with us over 24 months, you will be surprised how many you ETH would grow. We have proved it that our 3-layerd algorithm trading works by make positive return for more than 40+ months regardless whether the market is good or bad.
<br /><br />
This is what you need to do:
<br />
							<ul className="li_simple"><li>
								(1) Buy ETH today. The important part is to buy whatever you can afford. Don’t stretch out too much. ‘Afford’ means any funds that you won’t need to use for the next 5 years.
	</li>
								<li>
									(2) Make an account at WREX and place them in products we offer.
    </li>
								<li>
									(3) Come back every now and then just to check on the growth and news & announcements.
    </li>
								<li>
									(4) Let it sit in your account for the next 24 months minimum.
    </li>
							</ul>
							<br />
&lt;What will happen? How it will happen? After 24 months&gt;
<br /><br />
After 24 months, you would see all of the price history of ETH from the day you start at WREX and 24 months after. Check the ‘all-time record of the HIGHEST’ ETH PRICE during the period.
<br /><br />
That ALL TIME HIGHEST ETH PRICE should be YOUR target EXIT PRICE.
<br /><br />
So, if the ETH price hit USD 4,500, and today price after 24th months of WREX is at USD 1,500, wait for another 6 months or 12 months or longer until the day comes that ETH price would reach back to USD 4,500 again. If it takes longer time to reach the target EXIT PRICE, the better, because while you are waiting, WREX will increase your ETH number continuously.
<br /><br />
If the PRICE of the ETH reaches USD 4,500 again, your target ETH EXIT price, then you might have 40+ ETH instead of 10 ETH (Your Entry Deposit), then yes, you have reached your best possible outcome – maximizing your profit.
<br /><br />
When you look at the all-time history charts of ETH from the Genesis block to today, you would observe all the ups and downs. No one, including us, will never know the CEILING of the ETH price as it is all about Economics 101. Price is set per “Supply and Demand”.
<br /><br />
We know Supply of ETH is set at a fixed number. We know the DEMAND over time will grow. That means, yes it showed ups and downs but moving upwards. Since the DEMAND has still a HUGE room to grow, the price will exercise ups and downs but eventually moving upwards, so NO ONE would ever know what would be the REAL CEILING PRICE of ETH, yet.
<br /><br />
&lt;Start today with WREX&gt;
<br /><br />
You cannot control the price but we know it will move upwards. WREX would offer you to increase the number of ETH over time. You will enjoy as long as your ETH investments are ‘AFFORDABLE’. Be patient, wait 24 months, maybe another 24 months. But every month counts as we will increase your ETH number. So, at the end of 24 months, or 36 months, or 40 months or even longer, the longer you wait, bigger ETH number you will have to sell at the end, again, to maximize your profit.
<br /><br />
The most important part is waiting for ETH price to go down is not a bad choice. But better choice is to start ASAP with WREX today and benefit from your ETH number growth.
<br /><br />
Buy ETH now. Enroll your free membership with WREX today.
<br /><br />
Ask your friends, families to join WREX. Earn more on referral bonus. Be part of the great project we are spearheading.
<br /><br />
WREX Team
<br /><br />
Please check out [how to maximize your referral bonus] and [why WREX can be trusted] on the announcement pages of our website – to help you to reach from 10 ETH to 40 ETH in a couple of years.
<br /><br />
If you have questions, write to us, <a href="mailto:support@w-rex.com" class="animate-this">support@w-rex.com</a>


						</div>
					</label>

					<input id='nr18' name='radio' type='checkbox' />
					<label htmlFor='nr18'>
						<span className="th_faq">[Pengumuman] 3 Februari 2021</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Anggota WREX yang terhormat!
								</p>

									Akhirnya, bagian terburuk telah berakhir dan kami akan kembali untuk berlari ! Sejak pembukaan, kami memiliki keuntungan bulanan rata-rata 11,01%. Kemudian wabah COVID19 menghantam hidup dalam banyak hal dan begitu pula kami. Setelah COVID meledak, laba bulanan rata-rata kami turun menjadi 3,12%.
									<br /><br />
									Penurunan dramatis ini merupakan kombinasi dari dua faktor: satu, sejauh ini kami memiliki lebih banyak penarikan daripada penyetoran - COVID19 membuat sebagian besar dari kami membutuhkan uang cepat ! Hal ini menempatkan kami pada posisi yang sangat sulit karena portofolio kami diarahkan untuk memaksimalkan keuntungan dengan menahannya untuk jangka menengah hingga panjang, dan untuk memenuhi permintaan penarikan yang melampaui batas, kami harus menjual portofolio kami sebelum waktunya.
									<br /><br />
									Alasan lainnya adalah "lonjakan harga ETH yang belum pernah terjadi sebelumnya". Ketika kami memulai Proyek WREX kami, harga ETH hanya USD 100. Saat ini harganya nyaman di USD 1.300.
									<br /><br />
									Jadi, kedua faktor tersebut, [butuh uang cepat] karena COVID19 dan [kenaikan harga] ETH tiba-tiba sehingga membuat pilihan yang mudah bagi anggota kami untuk menarik aset mereka dan menggunakannya untuk mempertahankan gaya hidup yang menjadi 'bagian dari sifat manusia'.
									<br /><br />
									Selama proses, kami secara internal di WREX, juga mempelajari dan telah melakukan beberapa perbaikan dalam perdagangan dan kebijakan kami untuk tidak mengulangi penarikan dalam jumlah besar yang akan mempengaruhi portofolio kami. Kami berterima kasih kepada anggota yang telah menunjukkan kepercayaan mereka kepada kami dan kami akan memberi mereka imbalan yang lebih baik atas simpanan mereka.
									<br /><br />
									Selain itu, kami ingin mengingatkan Anda bahwa WREX dirancang untuk menginvestasikan hanya apa yang Anda mampu. Aset tambahan apapun yang berpotensi untuk tumbuh, dan hanya dapat tumbuh secara signifikan jika Anda memberi kami cukup waktu untuk membuahkan hasil.
									<br />
								<br />
									Mari kita lihat beberapa fakta:
									<br />
								<ol>
									<li>
										Pada 22 Mei 2010, sekitar 12 tahun yang lalu, Hanyecz melakukan transaksi komersial pertama menggunakan bitcoin. Dia membayar 10.000 bitcoin untuk membeli 2 pizza, dan ini adalah 'pizza
										<br />
										bitcoin' yang terkenal. 12 tahun kemudian, 1 BTC = USD 34.000, yang berarti 10.000 bitcoin yang dia bayarkan saat itu bernilai USD 340.000.000.
										Apakah Anda akan menghabiskan 10.000 BTC jika Anda tahu itu bisa bernilai 340 juta dolar hari ini? Tidak. Saat itu, tidak ada yang tahu.
										<ul>
											<li>
												Tidak ada yang tahu apa yang akan terjadi di masa depan. Tapi kami tahu bitcoin, ethereum, dan beberapa altcoin utama akan bersama kami untuk waktu yang lama. Harga akhirnya akan sangat layak!
											<br /><br />
											Mengapa Anda menjual jangka pendek aset Anda. TAHAN selama Anda bisa.
											<br /><br />
											Tidak ketika harganya USD 500 per Ethereum, dan tidak ketika harganya USD 1.000. Tahan selama 3 hingga 5 tahun, dan uji ketinggiannya. Mungkin akan naik hingga USD 10.000. Mengapa Anda ingin menjualnya jangka pendek. Inilah MENGAPA ANDA HARUS INVESTASI HANYA APA YANG ANDA MAMPU, jadi Anda bisa menunggu sampai hampir selamanya.
											<br />
											</li>
										</ul>
									</li>
									<li>
										Bill Gates, Jeff Bezos, Elon Musk, mengapa mereka menjadi Multi Milyuner? Karena aset utama mereka adalah saham perusahaannya sendiri. Bill Gates akan memegang sahamnya terlepas dari harga Microsoft. Mengapa Anda harus menjual ETH Anda? Tahan sampai menjadi signifikan.

										<br />
									</li>
									<li>
										Pendiri WREX, benar-benar pedagang yang buruk. Dia beli, harganya turun. Kalau dia jual, tiba-tiba harganya naik. Terdengar akrab? Jadi, dia mendirikan WREX dan bekerja dengan trader terbaik. Dia sekarang punya waktu untuk melakukan hal lain karena asetnya ada di tangan yang tepat. Anda juga harus. Santai dan nikmati.

										<br /><br />
										Pendiri telah bekerja dengan grup perdagangan sejak awal 2019 bahkan sebelum peluncuran WREX. Dia membeli 100 ETH (USD 120 pada saat itu) dan menempatkannya bersama mereka (dan akhirnya ditransfer ke WREX), sekarang 100 ETH telah berkembang menjadi 245 ETH dan sekarang 1 ETH menjadi USD 1.300. Jadi, dari investasi USD 12.000, asetnya sekarang menjadi USD 318.500 selama kurang dari 2 tahun! Apakah dia akan segera menjualnya? Tidak, dia menunggu 2-3 tahun lagi dan akan melihat bagaimana kelanjutannya. Jika 1 ETH menjadi USD 10.000 dan ETH-nya tumbuh menjadi sekitar 500 ETH, itu akan menjadi USD 5.000.000. Investasi USD 12.000 tumbuh menjadi USD 5 juta selama 5 tahun.
										<br /><br />
										Dia juga melihat beberapa temannya yang mulai berinvestasi dengannya meninggalkan WREX dan menjual ETH mereka dengan harga USD 400, kemudian sekelompok teman lainnya dengan harga USD 800. Sayang sekali. Tetapi dia tahu bahwa teman mereka membutuhkan uang tunai. Jadi, peningkatan itu akhirnya membantu sehingga baik untuk mereka. Tapi sekarang semua orang menyesal. Inilah sebabnya mengapa pendiri WREX selalu menekankan pada investasikan apa yang Anda mampu, lepaskan dan lupakan, dan kembali dalam 5 tahun. Anda akan terkejut dengan apa yang dapat diberikan 1 ETH kepada Anda. Semoga berhasil untuk kita semua!
										</li>

								</ol>

								<br />
								ETH akan membelikan Anda rumah yang Anda impikan. Duduklah dengan tenang, santai dan berhasil !
								<br />
								Manajemen WREX<br />

							</li></ul>
						</div>
					</label>

					<input id='nr17' name='radio' type='checkbox' />
					<label htmlFor='nr17'>
						<span className="th_faq">[Pengumuman] 20 Agustus 2020 </span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Pengumuman untuk Agustus
								</p>

								<ol>
									<li>
										Kinerja bulan Juli kami tercatat sebesar 3.71% untuk laba bulanan. Sejak COVID19, kami telah mencatat 'digit ganda' yang jauh di bawah yang diharapkan untuk laba bulanan disebabkan karena jumlah penarikan yang besar. Sejak April, beberapa anggota telah menarik ETH yang besar, baik modal dasar maupun laba mereka. Kami menduga karena COVID19, pendapatan bulanan mereka berkurang dan dengan menguangkan investasi WREX untuk mendukung biaya hidup adalah salah satu alasan utama penarikan tersebut.
									<br />
									Tren ini didukung fakta bahwa sebelum COVID19, semua penarikan bulanan yang digabungkan kurang dari 1% dari total ETH yang dikelola, tetapi sejak COVID19 selama empat bulan terakhir berturut-turut, kami memiliki lebih banyak penarikan daripada penyetoran.
									<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td colspan="2" scope="Ranking">PRA COVID 19</td>
													<td colspan="2" scope="Prize">SELAMA COVID 19</td>
												</tr>
												<tr>
													<td data-label="Ranking">Sep 2019</td>
													<td data-label="Prize">16.52%</td>
													<td data-label="Ranking">Feb 2020</td>
													<td data-label="Prize">3.94%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Oct 2019</td>
													<td data-label="Prize">11.72%</td>
													<td data-label="Ranking">Mar 2020</td>
													<td data-label="Prize">4.71%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Nov 2019</td>
													<td data-label="Prize">10.07%</td>
													<td data-label="Ranking">Apr 2020</td>
													<td data-label="Prize">3.31%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Dec 2019</td>
													<td data-label="Prize">6.80%</td>
													<td data-label="Ranking">May 2020</td>
													<td data-label="Prize">3.48%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Jan 2020</td>
													<td data-label="Prize">9.92</td>
													<td data-label="Ranking">June 2020</td>
													<td data-label="Prize">5.29%</td>
												</tr>
												<tr>
													<td data-label="Ranking"></td>
													<td data-label="Prize"></td>
													<td data-label="Ranking">July 2020</td>
													<td data-label="Prize">3.71%</td>
												</tr>
											</tbody>
										</table><br />
									</li>
									<li>
										Tidak usah kuatir kepada anggota kami, sistem kami dirancang sehingga kami hanya mendistribusikan LABA yang kami peroleh melalui perdagangan algoritma kami. Tidak seperti banyak platform lain, kami TIDAK membayar dari MODAL DASAR untuk menarik lebih banyak investasi atau kami menggunakan MODAL DASAR untuk memotivasi anggota agar menarik lebih banyak investasi. Secara murni, pembagian laba bulanan hanya dari pendapatan kami, artinya setiap bulan ASET kami (JUMLAH ETHEREUM) akan tumbuh karena MODAL DASAR terjamin dan pertumbuhan berdasarkan laba bulanan. Setiap bulan!
									<br /><br />
									</li>
									<li>
										Namun, sejumlah besar penarikan mempengaruhi portofolio kami dan kinerja bulanan kami. Seringkali ketika kami harus memenuhi janji kepada anggota kami yang meminta penarikan, kami mungkin harus keluar sebelum waktunya dari suatu posisi dan oleh karena itu laba kami adalah hasil langsung dari 'jumlah keluar yang prematur' tersebut.
									<br /><br />
									</li>
									<li>
										4.	Untuk melindungi klien dan portofolio kami yang ada, mempersiapkan keuntungan bulanan 'dua digit' mulai September 2020 dan untuk mempertahankan profitabilitas kami, kami telah melakukan beberapa perubahan secara internal.
									 <font className="emphsize2 boldfont">Namun perubahan tersebut tidak akan berdampak pada ANGGOTA YANG SUDAH ADA dan asetnya! </font> Hanya untuk mereka yang mengatur akun mereka mulai dari 1 Agustus 2020 yang akan diterapkan dengan syarat dan ketentuan baru:
									<br />
										<ol>
											<li>
												Akun Wrex
									<table className="simpeltable">
													<tbody>
														<tr>
															<td className="emphsize boldfont" scope="Ranking">SEBELUM</td>
															<td className="emphsize boldfont" scope="Ranking">SESUDAH</td>
														</tr>
														<tr>
															<td scope="Ranking">Minimum 1 ETH di AKUN WREX</td>
															<td scope="Ranking">HANYA 1 ETH di AKUN WREX</td>
														</tr>
													</tbody>
												</table>
									** PENTING: Anggota yang sudah ada, mulai 1 September 2020, Anda tidak akan dapat menyetor ETHEREUM baru di AKUN WREX. Anda dapat mempertahankan apa adanya dan Anda akan menikmati manfaat yang sama seperti sebelumnya.
									</li>
											<li>
												100 ETH PLAN
									<table className="simpeltable">
													<tbody>
														<tr>
															<td className="emphsize boldfont" scope="Ranking">SEBELUM </td>
															<td className="emphsize boldfont" scope="Ranking">SESUDAH (per 1 Agustus 2020)</td>
														</tr>
														<tr>
															<td scope="Ranking">Hanya dapat mulai dengan 1 setoran ETH per 100 ETH PLAN</td>
															<td scope="Ranking">Dapat mulai dengan 1 ETH hingga 100 ETH per 100 ETH PLAN</td>
														</tr>
														<tr>
															<td scope="Ranking">Kematangan 40 bulan</td>
															<td scope="Ranking">Kematangan 24 bulan</td>
														</tr>
														<tr>
															<td scope="Ranking">Tidak ada penghentian dini</td>
															<td scope="Ranking">Pinalti untuk penghentian dini<br /> (Pinalti: 75% dari LABA ANDA)</td>
														</tr>
													</tbody>
												</table>
											</li>
										</ol>
										<br />
									Dengan perubahan tersebut, kami berusaha untuk memaksimalkan laba bulanan dengan mengunci ETH yang diinvestasikan dalam 100 ETH PLAN hingga 24 bulan, sehingga kami dapat menyusun strategi dan melaksanakan rencana jangka panjang dengan portofolio kami yang cenderung memberikan kami lebih banyak keuntungan.
									</li>
								</ol>
								<br />
								Terima kasih.
								<br />
								<br />
								Manajemen WREX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr15' name='radio' type='checkbox' />
					<label htmlFor='nr15'>
						<span className="th_faq">[Pengumuman] 10 Juni 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Anggota Wrex yang terhormat,
								</p>

								<ul>
									<li>
										Sejak Senin, auditor kami telah kembali ke kantor kami untuk melakukan audit bulanan pada akun setoran/penarikan kami, catatan perdagangan, dan pendapatan laba bulanan. Karena penguncian COVID19, kami belum dapat melakukan rutinitas bulanan sehingga kami menyadari bahwa kami memiliki banyak hal untuk dibahas untuk menghasilkan laporan bulan Maret, April dan Mei.
									<br />
									</li>
									<li>
										Kami mohon maaf bahwa kami mungkin terlambat satu hari untuk mengumumkan laba bulan Mei, yang seharusnya berlangsung hari ini. Semua laporan sebelumnya juga akan tersedia untuk anggota kami di minggu ini di Halaman Dukungan.
									<br />
									</li>
								</ul>
								Terima kasih.
								<br />
								Salam Hormat,
								<br />
								Tim Manajemen WREX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr14' name='radio' type='checkbox' />
					<label htmlFor='nr14'>
						<span className="th_faq">[Pengumuman] 11 Mei 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Anggota W-Rex yang terhormat:
								</p>

								<ul>
									<li>
										Kami berharap Anda dan keluarga Anda baik-baik saja selama COVID 19 ini yang telah memengaruhi gaya hidup kita. Sesuai pengumuman terakhir kami, karena wabah COVID 19, dan "total lock out" yang terjadi di Malaysia, kami tidak punya pilihan selain menunda audit bulanan kami oleh para auditor. Karena perintah penguncian dari pemerintah ini masih berlanjut, kami juga tidak dapat melakukan audit bulanan untuk kinerja April. Kabar baiknya adalah bahwa Pemerintah telah memerintahkan beberapa kelonggaran pada lock-out dan diperlukan waktu beberapa minggu sampai total lock-out benar-benar ditarik dan hidup kita akan kembali normal.
									<br />
									</li>
									<li>
										Saat ini, kami bekerja sama dekat dengan para auditor, ketika kami benar-benar dapat melakukan audit untuk pembukuan kami untuk bulan Maret dan April. Setelah selesai, kami akan mengumumkannya dan laporan yang diaudit akan diunggah untuk Anda lihat.
									<br />
									</li>
									<li>
										Saat ini, kami bekerja sama dekat dengan para auditor, ketika kami benar-benar dapat melakukan audit untuk pembukuan kami untuk bulan Maret dan April. Setelah selesai, kami akan mengumumkannya dan laporan yang diaudit akan diunggah untuk Anda lihat.
									<br />
									</li>
									<li>
										Dukungan Anda sangat besar dan kami meminta Anda untuk menunjukkan dukungan kepada kami selama masa-masa yang sangat sulit ini.
									<br />
									</li>
								</ul>
								Terima kasih.
								<br />
								<br />
								Tim WREX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr13' name='radio' type='checkbox' />
					<label htmlFor='nr13'>
						<span className="th_faq">[Pengumuman] 10 April 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								</p>

								<ol>
									<li>
										Kami harap pengumuman ini mendapatkan Anda dan keluarga Anda baik-baik saja selama masa yang sangat sulit ini dengan Corvid 19 yang menyebar di seluruh dunia.
									<br />
									</li>
									<li>
										Seperti yang telah kami sebutkan dalam pengumuman kami sebelumnya, sayangnya, auditor tidak dapat mengunjungi kantor kami untuk memverifikasi akun dan semua data perdagangan bulanan untuk
										menghasilkan laporan bulan Maret. Kami ingin menggunakan perhitungan internal kami malam ini untuk mendistribusikan laba yang kami hasilkan di bulan Maret.
										Setelah penguncian negara dilepaskan oleh Pemerintah, kami akan melakukan proses audit dan mengunggah laporan bulanan.
									<br />
									</li>
									<li>
										Laba bulanan untuk Maret adalah 4,71%.
									<br />
									Seiring dengan kinerja bulan lalu, kinerja Maret relatif rendah menurut standar kami. Kami tidak ingin menyalahkan Corvid19 yang menghasilkan kinerja "lebih rendah dari yang diharapkan",
									tetapi ini jelas berdampak pada perdagangan reguler kami. Pedagang yang merupakan aset utama kami dalam menjalankan operasi dan staf yang menganalisis dan memasukkan data ke dalam sistem AI
									kami sangat dipengaruhi oleh peristiwa yang tidak terduga ini. Sekarang, dengan penguncian lanjutan maka semuanya berdasarkan dari perdagangan berbasis rumah yang membuat kami harus mengikuti
									protokol tambahan karena alasan keamanan, membuat operasi kami semakin sulit untuk menjadi sangat efektif seperti biasa.
									Kami terus bekerja sepanjang waktu untuk meningkatkan lingkungan kerja kami sekarang ini untuk berkinerja lebih baik bahkan dengan keterbatasan yang kami miliki saat ini.
									<br />
									Namun demikian, ketika Anda melihat kembali ke pasar lain (misalnya sekuritas, pasar saham, emas, komoditas lain, dll) mereka telah kehilangan nilai cukup banyak dalam dua bulan terakhir.
									Sedangkan kami, WRex telah melakukan pertumbuhan positif dibandingkan dengan komoditas lain, pertumbuhan 4,71% kami sebenarnya lebih besar dari sekadar angka.
									<br />
									Kami memastikan Anda bahwa kami berusaha dalam kemampuan terbaik kami untuk meningkatkan efisiensi kami setiap hari bahkan selama masa sulit ini.
									<br />
									</li>
								</ol>
								Terima kasih sebelumnya atas pengertian Anda.
								<br />
								Salam Hormat,
								<br />
								Manajemen WREX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr12' name='radio' type='checkbox' />
					<label htmlFor='nr12'>
						<span className="th_faq">[Pengumuman] 6 April 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Anggota yang terhormat,
								</p>
								<br />
								Saya harap dengan pengumuman ini Anda dan keluarga Anda dalam keadaan baik.
								<ol>
									<li>
										Virus Corona (CORVID19) telah berdampak pada kita dalam banyak hal dan itu juga terjadi pada WREX. Saat ini, Pemerintah Malaysia telah diperintahkan untuk mengunci selama dua minggu dan baru-baru
										ini telah memperpanjang penguncian tersebut untuk dua minggu berikutnya.
										Selama penguncian, tidak ada bisnis yang buka selain Rumah Sakit, Farmasi dan Supermarket. Semua bisnis lainnya ditutup dan juga meninggalkan semua gedung kantor dalam keadaan kosong.
									<br />

									</li>
									<li>
										Karena Perintah dari Pemerintah yang ketat, hal ini berdampak pada jadwal audit kami. Audit tersebut melibatkan kehadiran fisik auditor di kantor kami untuk memeriksa semua akun dan catatan
										perdagangan bulanan kami, semua kegiatan tersebut ditunda hingga pemberitahuan lebih lanjut. Jadi untuk kinerja bulan Maret, kami akan menggunakan data internal kami untuk mengumumkan dan
										memberikan pembagian laba pada tanggal 10 April sesuai jadwal, dan setelah audit yang sebenarnya selesai di kemudian hari, kami akan menyesuaikan distribusi jika diperlukan.
									<br />
									Kita semua mengalami sedikit ketidaknyamanan karena masalah Corona, tetapi kami berharap bahwa kita semua dapat mengatasi wabah tersebut dalam waktu dekat.
									<br />
									</li>
									<li>
										Kami dengan tulus berharap yang terbaik untuk Anda dan keluarga Anda.
									<br />
									Di bawah ini adalah tautan Lock Down di Malaysia untuk referensi Anda.
									<br />
										<a href="https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown">
											https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown</a>
										<br />
									</li>
								</ol>
								<br />
								Manajemen W-REX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr11' name='radio' type='checkbox' />
					<label htmlFor='nr11'>
						<span className="th_faq">[Pengumuman] 17 Maret 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Anggota WREX yang terhormat!
								</p>
								<ol>
									<li>
										Layanan kami telah mencatat laba sebesar 3,94% untuk investasi kami di bulan Februari.Sejak layanan kami dimulai pada September 2019, enam bulan telah berlalu dan sejauh ini kami telah mencatat laba rata-rata 9,828% per bulan.
									<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">Layanan WREX</td>
													<td scope="Prize">Laba Bulanan % </td>
												</tr>
												<tr>
													<td data-label="Ranking">September 2019</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">October 2019</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">November 2019</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">December 2019</td>
													<td data-label="Prize">6.80%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Janurary 2020</td>
													<td data-label="Prize">9.92</td>
												</tr>
												<tr>
													<td data-label="Ranking">February 2020</td>
													<td data-label="Prize">3.94%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
										</p>
									</li>
									<li>
										Jika Anda memiliki 1 Ethereum yang disimpan di Akun WREX dari September 2019, dengan laba bulanan rata-rata 9,828%, 1 Ethereum Anda akan tumbuh menjadi 7,6 Ethereum setelah bulan ke-48 (4 tahun) - ROI 760%)
										<br />
										Jika Anda telah mempertahankan 1 Ethereum dalam 100 ETH PLAN, persentase pertumbuhan akumulatif Anda dapat sebanyak 2.100% untuk periode yang sama.
										<br />
										Angka-angka ini tidak dapat dibandingkan dengan rencana investasi lainnya dalam industri apa pun.
									<br />
									</li>
									<li>
										Kinerja bulanan kami untuk bulan Februari berada di bawah rata-rata dalam standar kami dan kami berharap untuk kembali dengan angka yang jauh lebih baik untuk bulan Maret.
									<br />
									</li>
								</ol>
								Terima kasih atas dukungan Anda! <br />
								<br />
								Tim Manajemen WREX<br />
							</li></ul>
						</div>
					</label>

					<input id='nr10' name='radio' type='checkbox' />
					<label htmlFor='nr10'>
						<span className="th_faq">[Pengumuman] 12 Februari 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota yang terhormat!<br />
									</p>
									<ol>
										<li>
											Laba bulanan akhirnya tercatat sebesar 9,92% untuk bulan Januari. Hasil ini kembali pagi ini dari auditor Baker Tilly.
											Kami sekali lagi meminta maaf atas sedikit keterlambatan dalam mengumumkan laba bulanan.
											Laporan resmi akan dipasang segera setelah kami mendapatkan laporan dari Baker Tilly.
										<br /><br />
										</li>
										<li>
											Penempatan pertama Trust Fund HK telah kami eksekusi pada minggu pertama Februari! Jadi sekarang kami mengelola tidak hanya aset anggota kami yang berharga tetapi juga dari
											Trust Fund yang berbasis di Hong Kong. Penempatan tersebut dimasukkan ke dalam akun kami setiap minggu sehingga jumlah Ethereum mencapai senilai 100 juta USD!
										<br /><br />
										</li>
										<li>
											Ini adalah pengumuman perubahan kebijakan! Sangat penting bagi Anda untuk memahami hal ini.
											Sejak peluncuran WREX kami hanya memiliki 6 permintaan penarikan terpisah! Mereka semua dieksekusi dalam 72 jam karena itu tertulis pada kebijakan kami. Namun kami telah
											menerima kekhawatiran dari departemen penjualan kami, bahwa penarikan tersebut dapat merusak kinerja bulanan karena dalam beberapa kasus kami harus keluar dengan laba yang lebih sedikit.
											Kami ingin memastikan penarikan seperti itu tidak berdampak negatif pada kinerja bulanan secara keseluruhan.
											Oleh karena itu secara internal kami telah membahas dan harus menulis ulang kebijakan penarikan.
										<br />
										</li>
									</ol>
									<br />Sebelum: Setiap permintaan penarikan baik dari Akun WREX atau 100 Eth Plan ke dompet Anda bisa memakan waktu hingga 72 jam.<br />
									<br />Setelah: Setiap permintaan penarikan baik dari Akun WREX atau 100 Eth Plan ke dompet Anda dapat memakan waktu hingga 4 minggu.<br />
									<br />Tetapi saya ingin menekankan bahwa jika jumlah penarikan Anda kurang dari 50 Ethereum maka dapat diselesaikan dalam 72 jam.
									Jika dalam sekali permintaan Anda menarik di atas 50 Ethereum atau seluruh penarikan dalam seminggu lebih dari 100 Eth, maka mungkin diperlukan waktu hingga 4 minggu.
									Namun dalam kasus normal, itu dapat siap dalam waktu seminggu atau lebih, tetapi saya harus jujur   dengan Anda semua dalam kasus seperti dua minggu pertama bulan Februari,
									pemaksaan keluar mendesak karena permintaan penarikan dapat merusak persentase keuntungan bulanan.
									Jadi perubahan kebijakan ini adalah untuk meningkatkan protokol dalam meminimalkan kerusakan dan memaksimalkan keuntungan bagi semua orang dalam komunitas WREX.
									<br />
									<br />Dukungan pelanggan kami akan menghubungi Anda melalui email ketika Anda melakukan permintaan penarikan untuk memberi tahu Anda ketika ada pembaruan/update harian dan mingguan
									sehingga Anda tidak bingung dan bertanya-tanya kapan Anda akan mendapatkan kembali Ethereum Anda. Kepercayaan adalah nilai terpenting antara kami dan anggota kami.
									<br />
									<br />Sekali lagi  Terima kasih!<br />
									<br />
									Manajemen WREX
									<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr9' name='radio' type='checkbox' />
					<label htmlFor='nr9'>
						<span className="th_faq">[Pengumuman] 11 Februari 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota WREX yang terhormat! <br />
									</p>
									Kami mohon maaf karena laporan bulanan tertunda selama beberapa hari. Saat ini, para auditor sedang memeriksa angka-angkanya dan persentase bulanan akan diumumkan besok.
									Sekali lagi kami mohon maaf atas sedikit ketertundaan ini
									<br />
									Manajemen W-REX<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr8' name='radio' type='checkbox' />
					<label htmlFor='nr8'>
						<span className="th_faq">[Pengumuman] 15 Januari 2020</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									ADIEU 2019! HERE WE GO 2020!<br /><br />
									Baiklah, atas nama tim W-REX, kami ingin mengucapkan Selamat Tahun Baru! 2019 telah menjadi waktu yang menyenangkan bagi kami karena W-REX diluncurkan pada tanggal 1 September dan melaksanakan tugas kami di bulan-bulan berikutnya. Kami mengalami pasang surut secara internal tetapi berhasil menjadi seperti sekarang ini.<br />
									Tidak perlu dikatakan, kami juga sangat bersemangat tentang tahun 2020 karena kami senang menerima Trust Fund mulai minggu pertama bulan Februari dan kami juga sedang berdiskusi dengan banyak VC lainnya untuk mengelola dana mereka, dan banyak potensi lain yang akan bermanfaat bagi anggota kami dalam jangka panjang.<br />
								</p>
								<ol>
									<li>
										CATATAN kami, <br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">Bulan</td>
													<td scope="Prize">Laba Bulanan % </td>
												</tr>
												<tr>
													<td data-label="Ranking">September</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">October</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">November</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">December</td>
													<td data-label="Prize">6.80%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											Ini berarti jika Anda memiliki 1 setoran ETHEREUM di [WREX ACCOUNT] sejak 1 September, 1 ETH Anda tumbuh menjadi 1,182252 ETH (KEUNTUNGAN 18%)<br />
											Jika Anda memiliki 1 setoran ETHERUM di [100 ETH PLAN] sejak 1 September, 1 ETH Anda tumbuh menjadi 1,384929 (KEUNTUNGAN 38,49%)<br />
										</p>
									</li>
									<li>
										Aplikasi Seluler SEKARANG TERSEDIA untuk Diunduh<br />
										Silahkan buka Google Play Store (ANDROID) dan App Store (Apple Iphone) dan cari dengan kata WREX untuk mengunduh aplikasi Seluler kami.<br />
									</li>
									<li>
										Pada tahun 2020, WREX akan mengelola lebih dari 750.000 ETHEREUM dan berdasarkan itu bahwa perusahaan induk kami, WOTA akan meluncurkan WOTA COIN.<br />
										Pada pertengahan 2020, W-REX.com akan melayani dompet WOTA dan mulai penjualan terbatas hanya untuk ANGGOTA kami! Penjualan ini akan menjadi penjualan pra-listing karena WOTA akan terdaftar di 3 Cryptocurrency Exchange utama di Dunia. W-REX.com juga akan melayani program investasi WOTA (seperti 369 PLAN) dalam W-REX. Bersemangatlah! Ini hanya tersedia untuk anggota WREX saja!<br />
									</li>
								</ol>
								Berita yang lebih rinci akan tersedia untuk anggota kami di bulan April.<br />
								<br />
								Tim WOTA / WREX <br />
							</li></ul>
						</div>
					</label>

					<input id='nr7' name='radio' type='checkbox' />
					<label htmlFor='nr7'>
						<span className="th_faq">DAFTAR WREX - ACARA PEMBUKAAN, 2020-01-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>

								<div className="row result-wrap">
									<div className="col-full">
										<div className="result">
											<p className="crypto-word-warp">[ACARA Pertama] Referensikan teman dan terima Ethereum gratis 420 Ethereum untuk DIBAGIKAN!<br /><span>* Pembayaran sesungguhnya untuk Acara Pembukaan akan dilaksanakan pada 10 Januari 2020, bukan pada 24 Desember. Hal ini karena kemampuan pembayaran di platform terbatas karena masalah keamanan online. Terima kasih atas pengertian Anda.</span></p>

											<table className="simpeltable">
												<thead>
													<tr>
														<th scope="col">Peringkat </th>
														<th scope="col">Hadiah </th>
														<th scope="col">Pemenang (Referral) </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td data-label="Peringkat ">TOP 1</td>
														<td data-label="Hadiah ">100 Ethereum</td>
														<td data-label="Pemenang (Referral) ">mil***************@y****.com (51)</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 2</td>
														<td data-label="Hadiah ">50 Ethereum</td>
														<td data-label="Pemenang (Referral) ">pau*******@g****.com (48)</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 3</td>
														<td data-label="Hadiah ">30 Ethereum</td>
														<td data-label="Pemenang (Referral) ">chs***@g****.com (46)</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 4~5</td>
														<td data-label="Hadiah ">20 Ethereum per orang</td>
														<td data-label="Pemenang (Referral) ">
															biz********@n****.com (42)<br />
															yse****@g****.com (42)
								</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 6~10</td>
														<td data-label="Hadiah ">10 Ethereum per orang</td>
														<td data-label="Pemenang (Referral) ">
															sea*******@s*******.ca (40)<br />
															jju****@h******.net (39)<br />
															mif*****@y****.com (37)<br />
															sal*******@g****.com (37)<br />
															sam*****@h******.com (37)
								</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 11~25</td>
														<td data-label="Hadiah ">5 Ethereum per orang</td>
														<td data-label="Pemenang (Referral) ">
															coo*******@g****.com	(36)<br />
															mid*********@y****.com	(30)<br />
															ste******@a**.com	(28)<br />
															seb***********@h******.com	(26)<br />
															pat*********@y****.com	(26)<br />
															nes*******@y****.com	(25)<br />
															pat**********@y****.com	(25)<br />
															rjh*******@y****.ca	(24)<br />
															rya*****@l****.com	(24)<br />
															s_h****@h******.com	(24)<br />
															uni******@g****.com	(23)<br />
															ost*****@y****.com	(22)<br />
															pic*********@g****.com	(22)<br />
															des*********@g****.com	(22)<br />
															sar*@h******.com	(21)
								</td>
													</tr>
													<tr>
														<td data-label="Peringkat ">TOP 26~100</td>
														<td data-label="Hadiah ">1 Ethereum per orang</td>
														<td data-label="Pemenang (Referral) ">
															myf*****@h******.com	(19)<br />
															raz*@a**********.com	(18)<br />
															mic********@y****.com	(18)<br />
															chu*********@n****.com	(17)<br />
															por*******@y****.com	(17)<br />
															raq*@y****.com	(15)<br />
															ozc*****@n****.com	(15)<br />
															bli*********@y****.com	(14)<br />
															pau*****@h******.com	(14)<br />
															sdw*****@h******.com	(14)<br />
															air******@h******.net	(13)<br />
															ea-****@d***.net	(13)<br />
															dms*****@n****.com	(13)<br />
															mig************@y****.com	(13)<br />
															ren**********@g****.com	(12)<br />
															jrk**@m**.com	(12)<br />
															jes*********@g****.com	(11)<br />
															sno******@g****.com	(11)<br />
															Lil*********@g****.com	(10)<br />
															jhl**@g****.com	(10)<br />
															jom*****@d***.net 	(9)<br />
															kws****@n****.com	(8)<br />
															ssd******@g****.com	(7)<br />
															pro**************@g****.com	(7)<br />
															han*********@h******.net	(7)<br />
															sha********@g****.com	(7)<br />
															kig*******@n****.com	(6)<br />
															jin*****@n****.com	(6)<br />
															mia******@g****.com	(6)<br />
															mil*******@a**.com	(6)<br />
															sen***@y****.com	(6)<br />
															esp*******@g****.com	(6)<br />
															mot*******@g****.com	(6)<br />
															rit**********@h******.com	(6)<br />
															sal***********@y****.com	(5)<br />
															lsi**@n***.com	(5)<br />
															hk6***@n***.com	(5)<br />
															dec****@n****.com	(5)<br />
															pen*******@g****.com	(5)<br />
															mie*******@y****.com	(5)<br />
															rba*******@p******.net	(5)<br />
															sai*********@c******.net	(5)<br />
															pel******@g****.com	(5)<br />
															bor*******@n****.com	(5)<br />
															suu****@n***.com	(4)<br />
															hap*******@n***.com	(4)<br />
															ste******@a**.com	(4)<br />
															seb****@m**.com	(4)<br />
															ray********@m**.com	(4)<br />
															sam********@g****.com	(4)<br />
															win****@n****.com	(4)<br />
															kim****@g****.com	(4)<br />
															yom*******@g****.com	(4)<br />
															spa******@k****.ac.kr	(4)<br />
															sha********@g****.com	(4)<br />
															jam************@y****.com	(4)<br />
															mil************@m**.com	(3)<br />
															dba******@d***.net 	(3)<br />
															tnz*******@d***.net	(3)<br />
															osc**********@h******.com	(3)<br />
															sfl*****@y****.com	(3)<br />
															mik********@h******.com	(3)<br />
															zin***********@g****.com	(3)<br />
															ton********@n****.com	(3)<br />
															mid*****@g****.com	(3)<br />
															jho*****@h******.net	(3)<br />
															sal******@g****.com	(3)<br />
															sc5***@h******.net	(3)<br />
															snu***@r*****.com	(3)<br />
															nel*****@s********.net	(3)<br />
															reg*********@a**.com	(3)<br />
															nam******@h******.net	(3)<br />
															seo*****@n****.com	(3)<br />
															bro*********@n****.com	(3)<br />
															h-p*********@h******.net	(3)
								</td>
													</tr>
												</tbody>
											</table>
											<p className="lead animate-this">[ACARA Kedua] 3 pemegang Ethereum teratas di Akun WREX dari 3 negara teratas dengan Ethereum terdaftar<br /><span>* Detail acara hanya diungkapkan kepada para undangan</span></p>
										</div>
									</div>
								</div>


							</ul>
						</div>
					</label>

					<input id='nr6' name='radio' type='checkbox' />
					<label htmlFor='nr6'>
						<span className="th_faq">Pengumuman – WREX Mobile App, 2020-01-02</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota yang terhormat,<br />
									</p>
									WREX telah meluncurkan Aplikasi kami dan sekarang Anda dapat mengunduhnya dari Playstore dan Appstore. Anda dapat mengunjungi situs-situs ini dan mencari WREX dan mengunduhnya atau mengklik tautan di bawah ini;
<br />
									App Store (IOS)
<br />
									<a href='https://apps.apple.com/us/app/wrex/id1491276520?ls=1'>https://apps.apple.com/us/app/wrex/id1491276520?ls=1</a>
									<br />
									Google Play (Android)
<br />
									<a href='https://play.google.com/store/apps/details?id=com.wrex'>https://play.google.com/store/apps/details?id=com.wrex</a>
									<br />
									Aplikasi hanya tersedia dalam bahasa Inggris saat ini. Bahasa Korea dan Bahasa Indonesia akan tersedia menjelang akhir Januari.
<br />
									Terima kasih dan kami ucapkan Selamat Tahun Baru!
<br />
									Tim Manajemen WOTA / WREX
<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr5' name='radio' type='checkbox' />
					<label htmlFor='nr5'>
						<span className="th_faq">Pengumuman Penting, 24 Desember 2019</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota WREX yang terhormat!<br />
									</p>
									Kami dengan senang hati membawa BERITA HEBAT yang terjadi beberapa hari yang lalu!  WOTA / WREX menandatangani [MANAJEMEN ASET dan PERJANJIAN KUSTODIAL] dengan PERUSAHAAN TRUST FUND ASSET MANAGEMENT yang berbasis di Hong Kong. Kesepakatan itu meliputi;
<ul>
										<li>WOTA / WREX untuk mengelola hingga 100 juta Dolar Amerika Serikat senilai ETHEREUM atas nama TRUST FUND dalam platform kami (yaitu hingga 740.000 ETHEREUM TOTAL). </li>
										<li>Trust Fund akan mentransfer dana kepada kami untuk dikelola mulai dari Februari 2020. </li>
										<li>WOTA / WREX akan mengelola dana selama 24 bulan (dapat diperpanjang). </li>
									</ul>
									<br />
									<br />
									Dengan perjanjian dan keputusan investasi institusional ini akan membawa platform WREX ke tingkat berikutnya karena secara tidak langsung menunjukkan bahwa manajemen platform WREX dan algoritma perdagangan WOTA sekarang secara resmi diakui oleh investor institusional.
<br />
									<br />
									Namun ini berarti, WREX akan segera menangguhkan pendaftaran anggota baru setelah kami mencapai 1.000.000 ETHEREUM yang dikelola.  Ini untuk memastikan bahwa WREX terus membawa laba persentase dua digit setiap bulan karena meningkatnya  jumlah Ethereum akan menyulitkan kami untuk mempertahankan tingkat laba.  Dengan adanya Trust Fund ini, beberapa bulan berikutnya akan menjadi tahap terakhir pendaftaran ke platform kami kecuali kami kembali dengan algoritma BTC baru untuk memperluas produk dan peluang kami.
<br />
									<br />
									Terima kasih atas dukungan Anda!
<br />
									<br />
									Manajemen WOTA / WREX
<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr4' name='radio' type='checkbox' />
					<label htmlFor='nr4'>
						<span className="th_faq">Pengumuman Desember, 5 Desember 2019</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>

							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											Persentase Keuntungan Bulanan November <br />
											Kami telah menyelesaikan audit untuk Persentase Keuntungan Bulanan November yaitu – 10,07%
											Laporan audit akhir akan diunggah pada tanggal 17 Desember karena liburan lebih awal oleh auditor kami.
<br />
											<br />
										</li>
										<li>
											Acara Pembukaan ETHEREUM GIVEAWAY!<br />
											Hasil acara akan diumumkan dalam 2 minggu!
<br />
											<br />
										</li>
										<li>
											Penundaan pada Setoran Ethereum<br />
											Kami mengalami proses setoran yang lebih lambat karena peningkatan dalam sistem kami untuk mengakomodasi Istanbul Hardfork, selama 6 Desember hingga 9 Desember. Anda dapat dengan aman melakukan setoran dan penarikan tetapi karena hardfork, proses yang sebenarnya mungkin lebih lambat dibandingkan dengan transfer biasanya.
<br />
											<br />
										</li>
										<li>
											369 Produk<br />
											Kami sedang mengembangkan produk baru untuk W-REX dengan memperkenalkan 369 PLAN. Produk baru ini akan tersedia mulai dari kuartal pertama (Q1) tahun 2020.
<br />
											Produknya sama seperti 100 ETH PLAN, tetapi dengan jangka waktu keluar yang jauh lebih pendek, seperti 3 bulan, 6 bulan, dan 9 bulan, yang dapat Anda pilih dibandingkan dengan 40 bulan. Juga dalam 100 ETH PLAN, perusahaan dan investor berbagi 50:50 pada saat penghentian atau jatuh tempo, namun dalam 369 PLAN baru kami, untuk menjadikannya bermanfaat bagi investor kami, pembagiannya adalah 70:30 (Investor : Perusahaan). Anda hanya dapat membuka satu 369 PLAN, dan hanya dapat memilih satu diantara 3, 6 atau 9 bulan dan menyetor berapapun jumlah ETH.
<br />
											Akan ada pengumuman resmi dengan informasi terperinci tentang produk baru kami segera.
<br />
											<br />
											Manajemen W-REX
<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr3' name='radio' type='checkbox' />
					<label htmlFor='nr3'>
						<span className="th_faq">Pengumuman November 2019, 2019-11-08</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota WREX yang terhormat,<br />
									</p>
									<ol>
										<li>
											Laporan Audit<br />
											Pada tanggal 30 September, sehari sebelum PWC mengaudit akun kami, PWC telah mengirimi kami email resmi yang menyatakan bahwa mereka tidak dapat mengaudit akun kami karena PWC Internasional menyarankan mereka untuk tidak melakukan layanan apapun untuk industri terkait kripto karena mungkin terlihat bahwa PWC mempromosikan industri ini. Meskipun tidak ilegal tetapi di beberapa negara masih ada di wilayah abu-abu karena tidak ada undang-undang terkait yang diterbitkan dan ditegakkan.<br />
											Jelas ini adalah berita yang menghancurkan kami karena kami secara hukum terlibat dengan PWC tetapi harus menerima kenyataan bahwa PWC tidak lagi dapat melayani kami untuk memberikan laporan 'dapat dipercaya' tentang kinerja kami. Namun, sejak itu kami telah bekerja keras untuk menemukan opsi alternatif dan kami dengan bangga mengumumkan bahwa kami telah mengajak Tilly Baker Accounting and Audit firm, yang dikenal sebagai salah satu dari  10 firma akuntansi terbaik di dunia. <br />
											Tilly Baker telah melakukan audit bulan pertama kami (Laporan September) dan laporan itu sekarang tersedia untuk diunduh untuk informasi Anda. Mereka sekarang telah menyelesaikan audit Oktober dan laporannya akan segera keluar.<br />
											Laporan September mengkonfirmasi bahwa mereka telah memeriksa Akun, memeriksa Ethereum yang diterima dan diperdagangkan, semua perdagangan yang terjadi pada bulan September dan mengkonfirmasi persentase keuntungan bulanan yang kami umumkan sudah benar.<br />
											<br />Silahkan unduh laporan di Halaman Support.<br />
											<br />
										</li>
										<li>
											Kinerja Oktober<br />
											Kami juga mengumumkan laporan kinerja bulanan resmi kami untuk Oktober: 11,72%<br />
											Sekali lagi, 11,72% adalah angka yang diverifikasi oleh Tilly Baker dan laporan Oktober yang diterbitkan akan tersedia dalam beberapa hari. Pada tanggal 10 November, akun Anda akan dikreditkan dengan keuntungan 11,72% dan diperbarui sesuai dengan itu.<br />
											Sejak diluncurkannya WREX, <br />
											<br />
											% MP September: 16,52%<br />
											% MP Oktober: 11,72%<br />
											Rata-rata MP%: 14,12%<br />
											<br />
										</li>
										<li>
											Contoh Kinerja Bonus Afiliasi<br />
											Kami telah meminta dan menerima izin untuk mempublikasikan cuplikan contoh akun dari salah satu pemain TOP 5 kami dalam Bonus Afiliasi. Pada bulan September, ketika semua orang menerima keuntungan maksimum 16,52% di Akun WREX dan 100 ETH Plan mereka, ia telah menerima keuntungan 593,60% di Akun WREX-nya dan keuntungan 88,36% pada 100 ETH PLAN-nya.<br />
											Jika Anda ingin mengetahui lebih lanjut bagaimana dia melakukannya, silahkan kunjungi ‘Halaman Support’<br />
											<br />Download: <a href="/download/AffiliateBonusSample(IDN).pdf" target="_blank">AffiliateBonusSample(IDN).pdf</a><br />
											<br />Terima kasih semuanya!<br />
											WREX Support Team<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>


					<input id='nr2' name='radio' type='checkbox' />
					<label htmlFor='nr2'>
						<span className="th_faq">WREX Announces First Payouts, 2019-10-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Anggota WREX yang terhormat,<br />
									</p>
									<ol>
										<li>
											Pembayaran telah dieksekusi dan dikreditkan ke akun Anda. <br />
											Karena ini adalah eksekusi pembayaran pertama kami, kami mengharapkan banyak pertanyaan. <br />
											Harap diingat ini semua adalah pembayaran program backend yang dieksekusi, sehingga itu  berarti “jika satu kasus sudah benar, maka semuanya sudah benar; jika ada yang salah itu berarti semuanya salah ”.<br />
											Kesalahpahaman umum tentang pembayaran di  <font className="emphsize">bulan pertama </font> pertama tergantung pada hari apa di  bulan Anda membuat Akun WREX dan 100 ETH PLAN.  <br />
											<br />
											<font className="boldfont">Akun WREX (WREX ACCOUNT)</font>, ketika tanggal mulai Anda adalah;<br />
											Antara tanggal 1 September hingga 10 September - Anda menerima 50% dari laba bulanan<br />
											Antara tanggal 11 September hingga 20 September - Anda menerima 25% dari laba bulanan<br />
											Antara tanggal 21 September hingga hari terakhir September - Anda menerima 0% dari laba bulanan<br />
											<font className="emphsize">INI BERLAKU UNTUK BULAN PERTAMA ANDA SAJA! </font> (Dari bulan kedua dan seterusnya, Anda menerima 100% dari laba bulanan)<br />
											<br />
											<font className="boldfont">100 ETH PLAN</font>, , ketika tanggal mulai Anda adalah;<br />
											Antara hari pertama September hingga hari terakhir September - Anda menerima 0% dari laba bulanan<br />
											100 RENCANA ETH akan berlaku efektif pada hari pertama bulan berikutnya sejak Anda membuat rencana.<br />
											<br />
											Jika Anda telah membuat Akun WREX dan 100 ETH PLAN di bulan AGUSTUS, ANDA AKAN MENERIMA 100% dari laba bulanan di bulan September.<br />
											Bagi mereka yang telah menyiapkan Akun WREX dan 100 ETH PLAN di bulan SEPTEMBER, ANDA AKAN memenuhi syarat sebagaimana dijelaskan di atas tetapi akun Anda akan berhak menerima 100% dari laba bulan Oktober.<br />
										</li>
										<li>
											Bonus Afiliasi<br />
											<font className="boldfont">Bonus Afiliasi Akun WREX</font><br />
											(1) Akun Anda harus memiliki minimal 1 ETH dari hari pertama dan dipertahankan hingga hari terakhir dalam sebulan untuk menerima Bonus Afiliasi Akun WREX.<br />
											(2) Afiliasi Anda harus memiliki minimal 1 ETH dari hari pertama dan dipertahankan hingga hari terakhir dalam sebulan agar Anda menerima Bonus Afiliasi Akun WREX.<br />
											<font className="boldfont">Bonus Afiliasi 100 ETH PLAN</font><br />
											(1) Anda harus mengaktifkan minimal 100 ETH PLAN dari hari pertama dan dipertahankan hingga hari terakhir dalam sebulan untuk menerima Bonus Afiliasi 100 ETH PLAN.<br />
											(2) Afiliasi Anda harus mempertahankan satu atau lebih dari 100 ETH PLAN-nya dari hari pertama hingga hari terakhir dalam sebulan agar Anda menerima Bonus Afiliasi 100 ETH PLAN.<br />
											(3) Harap dicatat, semua Bonus Afiliasi 100 ETH PLAN akan masuk ke dalam 100 ETH PLAN terlama Anda jika Anda memiliki lebih dari satu 100 ETH PLAN.<br />
											<br />
											<font className="boldfont">Silahkan unduh BUKU PANDUAN – di website kami untuk informasi lebih lanjut tentang kelayakan.</font>
										</li>
										<li>
											LAPORAN Bulan Pertama Firma Akunting<br />
											Kami saat ini mengalami masalah internal menghasilkan Laporan Bulan Pertama dan kami dengan tulus meminta maaf atas ketidaknyamanan ini. Namun, yakinlah, keuntungan bulanan dihitung beberapa kali untuk menghindari kesalahan. Setelah kami mempercepat dan menerbitkan laporan, WREX akan sepenuhnya menjelaskan masalah internal masa lalu yang kami miliki.<br />
											Selain laporan yang tertunda, semuanya adalah bisnis seperti biasa dan kami ingin berbagi kabar baik dengan Anda semua, bahwa persentase laba Oktober sudah terlihat cukup bagus.<br />
											<br />
											Sekali lagi terima kasih atas dukungan Anda, dan silahkan menulis ke <a href="mailto:support@w-rex.com">support@w-rex.com</a> jikaada pertanyaan.<br />
											<br />
											Terimakasih semuanya!<br />
											<br />
											MANAJEMEN WREX<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr1' name='radio' type='checkbox' />
					<label htmlFor='nr1'>
						<span className="th_faq">Monthly Profit Notice, 2019-10-01</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Hai Anggota WREX,<br />
										Kami dengan bangga mengumumkan hasil tidak resmi kami untuk LABA BULANAN% (MP%) untuk bulan September - 16.52%.<br />
										Anggota kami yang berharga yang percaya kepada kami sejak awal akan menerima "laba yang luar biasa, tetapi hanya sedikit di atas rata-rata bagi WREX" dalam akun mereka segera.<br />
										<br />
										Jika Anda memiliki pertanyaan tentang MP%, atau pertanyaan lain, jangan ragu untuk mengirim email kepada kami: <a href="mailto:support@w-rex.com">support@w-rex.com</a><br /><br />
										1 Oktober 2019<br />
									</p>
								</li>
							</ul>
						</div>
					</label>
				</div>
			</div>

		)
	}
}

export default NewsID;


//export const API_ROOT = 'https://www.devel.w-rex.com/'
//export const API_ROOT = 'https://staging.w-rex.com/'
export const API_ROOT = 'https://www.w-rex.com/'

//export const LOUNGE_URL = 'https://lounge.devel.w-rex.com/competition/redirect'
//export const LOUNGE_URL = 'https://lounge.staging.w-rex.com/competition/redirect'
export const LOUNGE_URL = 'https://wlounge.w-rex.com/competition/redirect'

export const REFERRAL_LINK_PREFIX = 'https://www.w-rex.com/?ref='

export const DEFAULT_NETWORK_FEE =  0.00042;

export const MAX_DECIMAL_NUMBER=8;


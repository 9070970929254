/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

//import SideNav from '../components/SideNav';

import { isLogin, getToken } from '../helper/loginHelper';

import { renderErrorMessage } from '../components/ErrorMessage'

import { sendAccount100EPTerminate, sendAccount100EPList, API_SUCCESS } from '../actions'

import i18n from 'i18n-react';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Eth100TerminateContact extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      subscriptionId: "",
      cancelUrl: "",
      errorMessage: "",
    }
  }

  componentDidMount() {
    //console.log("ych Eth100TerminateContact");
    //window.addEventListener('resize', this.handleResize)
    document.title = "100 Eth Terminate Confirm - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    isLogin(this);

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const refString = params.get('ref'); // bar

    if (refString===null) {
      this.props.history.push("/my_assets")
    } else {
      const ref = parseInt(refString);

      this.setState({subscriptionId: ref})
      this.setState({cancelUrl: "100ETH_plan_1?p=0&ref="+ref})

    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleTerminate() {
    //console.log("handleTerminate");
    //console.log(this.state.subscriptionId);

    let token = getToken(this);
    var self = this;

    this.props.sendAccount100EPTerminate( token, {
      subscription_id: this.state.subscriptionId
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {

          if ((self.props.globalAccount!==undefined)&&(self.props.globalAccount.account!==undefined)) {
            let account = self.props.globalAccount.account;
            account.subscriptions = undefined;
          }

          self.props.sendAccount100EPList(token,{});
          //self.props.sendAccountInfo(token,{});
          self.props.history.push("/my_assets")
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
    })
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Eth100 Termination Error",this);
    }

    return (
      <div>
          <div className="crypro-login-container rex_rg">
				<div className="crypro-login-container__box">
				  <div className="crypro-login-container__box-inner">
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
						<img src="assets/images/logo_wrex.svg" alt="WREX" />
					</h2>
					<h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
					  <span className="crypto-widget__heading-text">{i18n.translate('confirmterminate')}</span>
					  <span className="crypto-widget__heading-border"></span>
					</h2>
                                        <p className="crypto-word-warp">{i18n.translate('contact_info_1')} 
                                            <Link to="#" 
                                                onClick={(e) => {
                                                    window.location = "mailto:support@w-rex.com";
                                                    e.preventDefault();
                                                }}>
                                            support@w-rex.com
                                            </Link> {i18n.translate('contact_info_2')}</p>

					<br/>
					<form className="crypto-login-form f_confirm_btn">
					  <Link to={this.state.cancelUrl} className=" mdc-button mdc-button--unelevated big-round-corner-button">{i18n.translate('ok')}</Link>
				   </form>
{ /*
                <span className="alert-message">
                  {this.state.errorMessage}
                </span>
*/ }
				  </div>
				</div>
			  </div>

      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalLogin: state.entities.login,
    globalAccount: state.entities.account,
})

export default withRouter(connect(mapStateToProps, {
  sendAccount100EPTerminate, sendAccount100EPList
})(Eth100TerminateContact))





/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Chart from 'chart.js'
import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { MAX_DECIMAL_NUMBER } from '../constants/ConfigValue.js';

import { withStyles } from "@material-ui/core/styles";

import Select, { Option } from '@material/react-select'

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

//import QRCode from 'react-qr-code';

import SideNav from '../components/SideNav';

import { renderErrorMessage } from '../components/ErrorMessage'

import i18n from 'i18n-react';


import PreLoaderWidget from '../components/Loader';

import { sendAccountInfo, API_SUCCESS } from '../actions'

import { getToken } from '../helper/loginHelper';

//import {isLogin} from '../helper/loginHelper';
import { getETHDisplayString, getTimeDisplayString } from '../helper/displayHelper'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  root: {
    backgroundColor: "transparrent",
  },

  paper: {
    backgroundColor: "#222",
    boxShadow: "none",
    overflow: "visible",
  },
};


class MyEthWallet extends Component {

  static propTypes = {
    // Injected by React Redux
    inputValue: PropTypes.string.isRequired,
    // Injected by React Router
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({
      'selector': PropTypes.string,
      'content': PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element,
        PropTypes.func,
      ]).isRequired,
      'position': PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'center']),
      'action': PropTypes.func,
      'style': PropTypes.object,
      'stepInteraction': PropTypes.bool,
    })),
  }

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();

    this.state = {
      filter: "0",
      errorMessage: "",
      setCanvas: false,
      isShowingAnnounce: false,
      AnnounceDay: 20210309,
      LanguageSetting: "",
    }

    const cookieLanguageSetting = cookies.get('language_setting');
//console.log("ych");
//console.log(cookieLanguageSetting);
    if (cookieLanguageSetting !== undefined) {
      if (cookieLanguageSetting === "KR") {
        this.state.LanguageSetting = "KR";
        this.state.AnnounceDay = 20211029;
      } else if (cookieLanguageSetting === "ID") {
        this.state.LanguageSetting = "ID";
        this.state.AnnounceDay = 20210309;
      } else {
        this.state.LanguageSetting = "EN";
        this.state.AnnounceDay = 20210309;
      }
    } else {
      this.state.LanguageSetting = "EN";
    }

    const cookieIsShowingAnnounce = cookies.get('isShowingAnnounce');
//console.log(cookieIsShowingAnnounce);
    if (cookieIsShowingAnnounce === undefined || cookieIsShowingAnnounce < this.state.AnnounceDay) {
      this.state.isShowingAnnounce = true;
    }
      //this.state.isShowingAnnounce = true;

  }

  setupMyChart(reference, dataArr) {
    //console.log("setupMyChart");
    //console.log(dataArr);

    reference.myChart = new Chart(reference.canvasRef.current, {
      type: 'pie',
      options: {
        maintainAspectRatio: false
      },
      data: {
        datasets: [{
          data: dataArr,
          backgroundColor: ['orange', 'cyan', 'pink']
        }],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: ['Wallet', 'WREX', '100ETH Plan']
      }
    });
  }

  componentDidMount() {
    //console.log("ych MyEthWallet");
    //window.addEventListener('resize', this.handleResize)

//console.log(this.state);
    document.title = "MyETH Wallet - WREX"

    document.body.className = "mdc-typography mdc-theme--background crypro-theme-gradient";


    if ((this.props.globalAccount !== undefined) && (this.props.globalAccount.account !== undefined) && (this.props.globalAccount.account.myeth !== undefined)) {
      let account = this.props.globalAccount.account;

      //console.log("got message from props");
      var totalAmount = parseFloat(account.myeth.value) + parseFloat(account.wrex.value) + parseFloat(account.ep100.value);

      totalAmount = totalAmount.toFixed(MAX_DECIMAL_NUMBER);
      //console.log(totalAmount);
      if (totalAmount > 0) {
        this.setState({ setCanvas: true })
        this.setupMyChart(this,
          [
            parseFloat(account.myeth.value),
            parseFloat(account.wrex.value),
            parseFloat(account.ep100.value)]
        );
      }
    } else {
      var self = this;
      let token = getToken(this);
      this.props.sendAccountInfo(token, {})
        .then(function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success === false) {
              self.setState({ errorMessage: i18n.translate(response.message) })
            } else {
              var totalAmount = parseFloat(response.myeth.value) + parseFloat(response.wrex.value) + parseFloat(response.ep100.value);

              totalAmount = totalAmount.toFixed(MAX_DECIMAL_NUMBER);
              //console.log(totalAmount);

              if (totalAmount > 0) {
                self.setState({ setCanvas: true })
                self.setupMyChart(self,
                  [
                    parseFloat(response.myeth.value),
                    parseFloat(response.wrex.value),
                    parseFloat(response.ep100.value)]
                );
              }
            }
          } else {
            self.setState({ errorMessage: i18n.translate('unknown_error') })
          }

        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({ errorMessage: i18n.translate('unknown_error') })
        })

    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }



  getHistoryAddress(address) {
    var b = address.split(/:/);
    return b[0];
  }

  getHistoryAddressName(address) {
    var b = address.split(/:/);
    return b[1];
  }

  getHistoryAddressUrl(address) {
    var b = address.split(/:/);
    return "https://etherscan.io/address/" + b[0];
  }

  getHistoryTXIDUrl(txid) {
    return "https://etherscan.io/tx/" + txid;
  }

  getHistoryTH0(amount) {
    if (parseFloat(amount) >= 0) {
      return "arrow_downward";
    } else {
      return "arrow_upward";
    }
  }

  getHistoryTH1(logType, description, amount) {
    //console.log(logType,description);
    if (logType === "deposit") {
      return this.getHistoryAddressName(description);
    } else if (logType === "withdrawal") {
      return this.getHistoryAddressName(description);
    } else if (logType === "wota_deposit") {
      return i18n.translate('wrex_account');
    } else if (logType === "wota_withdrawal") {
      return i18n.translate('wrex_account');
    } else if (logType === "wota_profit") {
      return i18n.translate('wrex_account');
    } else if (logType === "wota_referral_bonus") {
      return i18n.translate('wrex_account');
    } else if (logType === "ep100_subscribe") {
      return description;
    } else if (logType === "ep100_profit") {
      return description;
    } else if (logType === "ep100_referral_bonus") {
      return description;
    } else if (logType === "ep100_termination") {
      return description;
    } else if (logType === "ep100_mature") {
      return description;
    } else if (logType === "fee") {
      return "-";
    } else if (logType === "transfer") {
      return i18n.translate('internaluser');
    } else {
      return i18n.translate('unknown');
    }
  }

  getHistoryTH3(logType) {
    if (logType === "deposit") {
      return i18n.translate('deposit');
    } else if (logType === "withdrawal") {
      return i18n.translate('ca_withdraw');
    } else if (logType === "wota_deposit") {
      return i18n.translate('deposit');
    } else if (logType === "wota_withdrawal") {
      return i18n.translate('ca_withdraw');
    } else if (logType === "wota_profit") {
      return i18n.translate('ca_gains');
    } else if (logType === "wota_referral_bonus") {
      return i18n.translate('ca_bonus');
    } else if (logType === "ep100_subscribe") {
      return i18n.translate('ca_create');
    } else if (logType === "ep100_profit") {
      return i18n.translate('ca_gains');
    } else if (logType === "ep100_referral_bonus") {
      return i18n.translate('ca_bonus');
    } else if (logType === "ep100_termination") {
      return i18n.translate('ca_terminate');
    } else if (logType === "ep100_mature") {
      return i18n.translate('ca_mature');
    } else if (logType === "fee") {
      return i18n.translate('ca_fee');
    } else if (logType === "transfer") {
      return i18n.translate('transfer');
    } else {
      return "-";
    }
  }


  //handleFilterChange(event)  {
  handleFilterChange = (index, item) => {
    //console.log("handleFilterChange");
    //console.log(event);
    this.setState({ errorMessage: "" })

    if (item !== undefined) {
      const filterValue = item.getAttribute('data-value');
      this.setState({ filter: filterValue })
    }

  }

  /*
  myethaccount
  Deposit | Withdraw | Gains | Bonus | 100EP Plan | Wrex
  1         2          3       4       5            6  
  Deposit:  deposit
  Withdraw: withdraw
  Gains:    wota_profit, ep100_profit
  Bonus:    wota_referral_bonus, ep100_referral_bonus
  100EP Plan: ep100_subscribe, ep100_termination, ep100_mature
  Wrex: wota_deposit, wota_withdrawal
  
  wrex  account
  Deposit | Withdraw | Gains | Bonus |
  11        12         13      14
  Deposit:  wota_deposit
  Withdraw: wota_withdrawal
  Gains:    wota_profit
  Bonus:    wota_referral_bonus
  
  */

  handleClose() {
    this.setState({ isShowingAnnounce: false });
    cookies.set('isShowingAnnounce', this.state.AnnounceDay, { path: '/' });
  }

  render() {

//console.log(this.props);

    const { classes } = this.props;


    //not_available

    if (this.state.errorMessage !== "") {
      return renderErrorMessage("My ETH Wallet Error", this);
    }

    //console.log(this.props.globalAccount);
    //console.log(this.props.globalUser);
    var myeth = { value: "", valueUsd: "" }
    var myethHistory = [];
    //var wrex = {depositable:false, withdrawable: false};

    if ((this.props.globalAccount !== undefined) && (this.props.globalAccount.account !== undefined)) {
      let account = this.props.globalAccount.account;
      if (account.myeth !== undefined) {
        //myeth.value = parseFloat(account.myeth.value);
        //myeth.valueUsd = parseFloat(account.myeth.valueUsd);
        myeth.value = account.myeth.value;
        myeth.valueUsd = account.myeth.valueUsd;
      }
      //if (account.wrex !== undefined) {
        //wrex.depositable = account.wrex.depositable;
        //wrex.withdrawable = account.wrex.withdrawable;
      //}
      if (account.myethHistory !== undefined) {
        myethHistory = account.myethHistory;
      }
    }

    var self = this;
    var userInfo = { netDeposit: "", depositQuotaLeft: "" }
    if ((this.props.globalUser !== undefined) && (this.props.globalUser.user !== undefined)) {
      let user = this.props.globalUser.user;
      if (user.info !== undefined) {
        userInfo = user.info;
      }
    }

    var chartNode = i18n.translate("not_available");

    //console.log(this.state);
    if (this.state.setCanvas === true) {

      chartNode = "<canvas id='myChart' ref={this.canvasRef} />"

    }
    //this.myChart.data.datasets[0].data = 123;
    //this.myChart.update();
    //console.log(myethHistory);

    return (
      <div>
        { /* preloader */}
        {this.props.loading && <PreLoaderWidget />}

        <div className="crypto-wrapper">

          <SideNav
            ActiveTag="myeth_wallet"
          />

          <main className="crypto-main">

            <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

              <div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone">
                  <h6 className="f_breadcrumb">
                  </h6>
                  <h1 className="crypto-card__header--title">{i18n.translate('myethwallet')}</h1>
                  <h4 className="f_mode">
                  </h4>
                </div>
                <div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
                  <div className="crypto-card__header__heading f_divider">
                    <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(myeth.value)}<span>ETH</span></h1>
                  </div>
                </div>
                <div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                  <div className="f_control">
                    <Link to="myeth_withdraw" className="f_ybtn mdc-button"
                    > {this.state.LanguageSetting !== "KR" &&
                      <span className="_small">{i18n.translate('external')}</span>}<span>{i18n.translate('withdrawB')}</span></Link>
                    <Link to="myeth_transfer" className="f_ybtn mdc-button"
                    > {this.state.LanguageSetting !== "KR" &&
                      <span className="_small">{i18n.translate('internal')}</span>}<span>{i18n.translate('transferB')}</span></Link>
                    <Link to="myeth_deposit" className="f_ybtn mdc-button"
                    >{i18n.translate('deposit')}</Link>
                  </div>
                </div>
              </div>

              <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner">
                  <div className="f_rg_ptop f_centerbox2 mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">
                    <div className="crypto-widget">
                      <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
                        <span className="crypto-word-warp crypto-widget__heading-text">{i18n.translate('my_eth_definition')}</span>
                        <span className="crypto-word-warp crypto-widget__heading-border"></span>
                      </h2>
                      <div className="crypto-word-warp crypto-widget__content">
                        <ul className="li_notice">
                          <li>{i18n.translate('def1')}</li>
                          <li>{i18n.translate('def2')}</li>
                          <li>{i18n.translate('def3')}</li>
                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
                <div className="mdc-layout-grid__inner">
                  <div className="mdc-layout-grid__cell--span-1-desktop"></div>

                  <div className="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-4-phone">

                    <div className="crypto-widget">
                      <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">{i18n.translate('overview')}</h2>
                      <div className="crypto-widget__content text-center f_pie">
                        <canvas id='myChart' ref={this.canvasRef} />
                        { /*
{chartNode}
*/ }
                      </div>
                      <span className="crypto-widget__heading-text text-center crypto-word-warp">{i18n.translate('youhavedeposited')} {getETHDisplayString(userInfo.netDeposit)} ETH</span>
                      {/* <span className="crypto-widget__heading-text text-center crypto-word-warp">{i18n.translate('youhaveroom')} {getETHDisplayString(userInfo.depositQuotaLeft)} ETH</span> */}

                    </div>


                    <div className="mdc-layout-grid__inner">
                      <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-2-phone">
                        <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">{i18n.translate('history')}</h2>
                      </div>
                      <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-2-phone crypto-widget__actions">
                        <div id="toolbar" className="toolbar mdc-menu-surface--anchor">

                          <div className="f_control4 fx_mb">

                            { /*
									<select className="mdc-select__native-control fix1" id="filter"  dir="rtl"
                      								value={this.state.filter}
                      								onChange={this.handleFilterChange.bind(this)}
									>
										<option value="0">{i18n.translate('filter')}</option>
										<option value="1">{i18n.translate('deposit')}</option>
										<option value="2">{i18n.translate('withdraw')}</option>
										<option value="6">WREX</option>
										<option value="3">{i18n.translate('gains')}</option>
										<option value="4">{i18n.translate('bonus')}</option>
										<option value="5">100ETH Plan</option>
									</select>
*/ }
                            <Select className="mdc-select-box crypto-gender-select select-color2 crypto-w-100"

                              enhanced
                              value={this.state.filter}
                              onEnhancedChange={this.handleFilterChange.bind(this)}
                            >
                              <Option value="0">{i18n.translate('filter')}</Option>
                              <Option value="1">{i18n.translate('ch_deposit')}</Option>
                              <Option value="2">{i18n.translate('withdraw')}</Option>
                              <Option value="6">{i18n.translate('wrex_account')}</Option>
                              <Option value="3">{i18n.translate('gains')}</Option>
                              <Option value="4">{i18n.translate('bonus')}</Option>
                              <Option value="5">{i18n.translate('eth100_plan')}</Option>

                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="crypto-widget__content crypto-overflow-x-inherit">
                      <div className='swanky_wrapper'>

                        <div>
                          <label htmlFor='header'>
                            <span className="th_0"><i className="material-icons mdc-button__icon"></i></span>
                            <span className="th_1">{i18n.translate('externaladdress')}</span>
                            <span className="th_2">{i18n.translate('datetime')}</span>
                            <span className="th_3">{i18n.translate('action')}</span>
                            <span className="th_4">{i18n.translate('amount')}</span>
                            <div className='bar'></div>
                          </label>
                        </div>

                        {myethHistory.map(function (myHistory, index) {

                          //console.log(myHistory);
                          if ((self.state.filter === "1") && ((myHistory.logType !== "deposit") && (myHistory.logType !== "transfer"))) {
                            return (
                              <div key={index}></div>
                            );
                          } else if ((self.state.filter === "2") && ((myHistory.logType !== "withdrawal") && (myHistory.logType !== "transfer"))) {
                            return (
                              <div key={index}></div>
                            );
                          } else if ((self.state.filter === "3") && (myHistory.logType !== "wota_profit") && (myHistory.logType !== "ep100_profit")) {
                            return (
                              <div key={index}></div>
                            );
                          } else if ((self.state.filter === "4") && (myHistory.logType !== "wota_referral_bonus") && (myHistory.logType !== "ep100_referral_bonus")) {
                            return (
                              <div key={index}></div>
                            );
                          } else if ((self.state.filter === "5") && (myHistory.logType !== "ep100_subscribe") && (myHistory.logType !== "ep100_termination") && (myHistory.logType !== "ep100_mature")) {
                            return (
                              <div key={index}></div>
                            );
                          } else if ((self.state.filter === "6") && (myHistory.logType !== "wota_deposit") && (myHistory.logType !== "wota_withdrawal")) {
                            return (
                              <div key={index}></div>
                            );
                          }

                          if ((myHistory.logType === "deposit") || (myHistory.logType === "withdrawal")) {
                            //if (myHistory.logType === "withdrawal") console.log('jamie', myHistory);
                            return (
                              <div key={index}>
                                <input id={index} name='radio' type='checkbox' />
                                <label htmlFor={index}>
                                  <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
                                  <span className="th_1">{self.getHistoryTH1(myHistory.logType, myHistory.description, myHistory.amount)}</span>
                                  <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
                                  <span className="th_3">{self.getHistoryTH3(myHistory.logType)}</span>
                                  <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
                                  <div className='lil_arrow'></div>
                                  <div className='bar'></div>
                                  <div className='swanky_wrapper__content'>
                                    <ul>
                                      <li>ADDRESS : <a href={self.getHistoryAddressUrl(myHistory.description)}>{self.getHistoryAddress(myHistory.description)}</a></li>
                                      <li>TXID : <a href={self.getHistoryTXIDUrl(myHistory.txHash)}>{myHistory.txHash}</a></li>
                                    </ul>
                                  </div>
                                </label>
                              </div>
                            )
                          } else if ((myHistory.amount <= 0) && (myHistory.logType === "transfer")) {
                            // Withdrawl
                            if (self.state.filter === "1") {
                              return (
                                <div key={index}></div>
                              );
                            } else {
                              return (
                                <div key={index}>
                                  <input id={index} name='radio' type='checkbox' />
                                  <label htmlFor={index}>
                                    <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
                                    <span className="th_1">{self.getHistoryTH1(myHistory.logType, myHistory.description, myHistory.amount)}</span>
                                    <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
                                    <span className="th_3">{self.getHistoryTH3('withdrawal')}</span>
                                    <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
                                    <div className='lil_arrow'></div>
                                    <div className='bar'></div>
                                    <div className='swanky_wrapper__content'>
                                      <ul>
                                        <li>TO : {self.getHistoryAddressName(myHistory.description)}</li>
                                      </ul>
                                    </div>
                                  </label>
                                </div>
                              );
                            }
                          } else if ((myHistory.amount >= 0) && (myHistory.logType === "transfer")) {
                            // Deposit
                            if (self.state.filter === "2") {
                              return (
                                <div key={index}></div>
                              );
                            } else {
                              return (
                                <div key={index}>
                                  <input id={index} name='radio' type='checkbox' />
                                  <label htmlFor={index}>
                                    <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
                                    <span className="th_1">{self.getHistoryTH1(myHistory.logType, myHistory.description, myHistory.amount)}</span>
                                    <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
                                    <span className="th_3">{self.getHistoryTH3('deposit')}</span>
                                    <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
                                    <div className='lil_arrow'></div>
                                    <div className='bar'></div>
                                    <div className='swanky_wrapper__content'>
                                      <ul>
                                        <li>FROM : {self.getHistoryAddress(myHistory.description)}</li>
                                      </ul>
                                    </div>
                                  </label>
                                </div>
                              );
                            }
                          } else {
                            return (
                              <div key={index}>
                                <input id={index} name='radio' type='checkbox' />
                                <label htmlFor={index}>
                                  <span className="th_0"><i className="material-icons mdc-button__icon">{self.getHistoryTH0(myHistory.amount)}</i></span>
                                  <span className="th_1">{self.getHistoryTH1(myHistory.logType, myHistory.description, myHistory.amount)}</span>
                                  <span className="th_2">{getTimeDisplayString(myHistory.timeIssued)}</span>
                                  <span className="th_3">{self.getHistoryTH3(myHistory.logType)}</span>
                                  <span className="th_4">{getETHDisplayString(myHistory.amount)} ETH</span>
                                </label>
                              </div>
                            )
                          }
                        })}

                      </div>

                    </div>

                  </div>
                  <div className="mdc-layout-grid__cell--span-1-desktop"></div>
                </div>
              </div>
            </div>




          </main>

        </div>

        <Dialog open={this.state.isShowingAnnounce}
          maxWidth="xl"
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }
          }
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}

          onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>
          <div id="layer-noti">
            <div class="noti-contents">
              <h3>{i18n.translate('notice_title')}</h3>
              <p className="crypto-word-warp">{i18n.translate('notice_message')}</p>
              <br />
              <span className="layer-noti_highlight"><Link to="support#latest">{i18n.translate('notice_footer1')}</Link></span>
              <p className="float-right crypto-word-warp">{i18n.translate('notice_date')}</p>
              <br />

              <span onClick={this.handleClose.bind(this)} class="close-btn"><img src="https://www.w-rex.com/event/ic_close.svg" alt="Close" /></span>
            </div>
          </div>
        </Dialog>



      </div>

    )
  }



}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, {
  sendAccountInfo
})(MyEthWallet)))





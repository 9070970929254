/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
//import { resetErrorMessage } from '../actions'
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { sendLogin, LOGIN_SUCCESS } from '../actions'

import i18n from 'i18n-react'; 

//import { FormattedMessage } from 'react-intl';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

import { renderErrorMessage } from '../components/ErrorMessage'

import { getToken } from '../helper/loginHelper';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

const cookies = new Cookies();

class Login extends Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === 'production') {
      this.state = {
        emailAddress: "",
        password: "",
        emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder'),
        passwordPlaceHolder: i18n.translate('passwordPlaceHolder'),
        errorMessage: "",
	redirect_uri: "",
        isClickable: true,
      }
    } else {
      this.state = {
        emailAddress: "user1010@wrex.com",
        password: "test1234",
        emailAddressPlaceHolder: "",
        passwordPlaceHolder: "",
        errorMessage: "",
	redirect_uri: "",
        isClickable: true,
      }
/*
      this.state = {
        emailAddress: "yc.h@sundroid.net",
        password: "pass1234",
        emailAddressPlaceHolder: "",
        passwordPlaceHolder: "",
        errorMessage: "",
	redirect_uri: "",
      }
*/
    }
  }

  componentDidMount() {
    //console.log("ych Login");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Log in - WREX"

    document.body.className="wrex mdc-typography mdc-theme--background crypro-theme-gradient";

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const redirect_uri = params.get('redirect_uri'); // bar
    const logout_flag = params.get('logout_flag'); // bar
    if (redirect_uri!==null) {
        this.setState({ redirect_uri: redirect_uri });
        const myToken = getToken();
        if (myToken!==null) {
              const cookieExpire = cookies.get('expire');
              window.location.href = redirect_uri+"&token="+myToken+"&expire="+cookieExpire;
        }
    }
    if (logout_flag!==null) {
        cookies.set('token',"logout", {path: '/'});
        cookies.set('expire',"logout", {path: '/'});
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(){
    //console.log("componentDidUpdate()");
  }

  handleEmailAddressChange(event)  {
    //console.log("handleEmailAddressChange");
    //console.log(event);

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({emailAddressPlaceHolder: ""})
      } else {
        this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({emailAddress: event.target.value})
    }

  }

  handlePasswordChange(event)  {
    //console.log("handlePasswordChange");
    //console.log(event);

    if (event!==undefined){
      if (event.target.value!=="") {
        this.setState({passwordPlaceHolder: ""})
      } else {
        this.setState({passwordPlaceHolder: i18n.translate('passwordPlaceHolder')})
      }
      this.setState({password: event.target.value})
    }
  }

  handleLogin() {
    //console.log("handleLogin");
    //console.log(parameter);
    //this.props.sendLogin(parameter);

    if (this.state.isClickable===false) {
      //console.log("isClickable===false");
      return;
    }

    this.setState({errorMessage: ""})
    this.setState({isClickable: false})

    var self = this;
    const email=this.state.emailAddress.trim();
    this.props.sendLogin({email: email, password: this.state.password})
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === LOGIN_SUCCESS) {
        let response = success.response.entities.login.login;
        //console.log(response);
        if ((response.success===false)&&(response.message!=="two_fa_not_enabled")) {
          self.setState({errorMessage: i18n.translate(response.message)})
     
          if (response.message==="email_not_confirmed") {
            cookies.set('token',response.token, {path: '/'});
            cookies.set('expire',response.expire, {path: '/'});
            //self.props.history.push("/register-verify")
            self.props.history.push("/")
          }
        } else {
          cookies.set('token',response.token, {path: '/'});
          cookies.set('expire',response.expire, {path: '/'});

//console.log(response);
          if (self.state.redirect_uri!=="") {
              window.location.href = self.state.redirect_uri+"&token="+response.token+"&expire="+response.expire;
          } else {
              self.props.history.push("/myeth_wallet")
          }
        }
      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})
      }
      self.setState({isClickable: true})

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
      self.setState({isClickable: true})
    })
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Login Error",this);
    }


    return (
      <div>
			  <div className="crypro-login-container rex_rg">
          <div className="crypro-login-container__box">
            <div className="crypro-login-container__box-inner">
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
              <img src="assets/images/logo_wrex.svg" alt="WREX" />
            </h2>
            <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
              <span className="crypto-widget__heading-text">{i18n.translate('signin')}</span>
              <span className="crypto-widget__heading-border"></span>
            </h2>
            <form className="crypto-login-form">
              <div className="mdc-text-field mdc-text-field--fullwidth crypto-margin-bottom-big">
              <input type="text" 
                id="username-text-field" 
                className="mdc-text-field__input" 
                value={this.state.emailAddress} 
                onChange={this.handleEmailAddressChange.bind(this)}
              />
              <label htmlFor="username-text-field" className="mdc-floating-label">{this.state.emailAddressPlaceHolder}</label>
              <div className="mdc-line-ripple"></div>
              </div>
              <div className="mdc-text-field mdc-text-field--fullwidth ">
              <input required pattern=".{8,}"
                id="password-text-field"
                type="password"
                className="mdc-text-field__input"
                aria-controls="pw-validation-msg"
                autoComplete="current-password" 
                value={this.state.password}
                onChange={this.handlePasswordChange.bind(this)}
              />
              <label htmlFor="pw" className="mdc-floating-label">{this.state.passwordPlaceHolder}</label>
              <div className="mdc-line-ripple"></div>
              </div>

{ /*
              <span className="alert-message">
              {this.state.errorMessage}
              </span>
*/ }
              <div className="mdc-form-field float-right">
                <Link to="forgot-password">{i18n.translate('forgotpassword')}?</Link>
              </div>

              <div className="crypto-display-flex crypto-display-flex--space-between crypto-margin-bottom-big f_rg_ptop">
              <div className="mdc-form-field">
{ /*
              {i18n.translate('notyetonwrex')}
*/ }
{ /*
                <Link to="register" className="f_rgnew">{i18n.translate('Register')}</Link>
*/ }
              </div>
              </div>
        
              <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button"
                onClick={() => this.handleLogin()}
              >
                {i18n.translate('continue')}
              </span>
            </form>
            </div>
          </div>
			  </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalLogin: state.entities.login,
  }
}

export default withRouter(connect(mapStateToProps, {
  sendLogin
})(Login))



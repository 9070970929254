import React, { Component } from 'react';

/**
 * Renders the preloader
 */
class NewsKR extends Component {

	render() {
		return (
			<div>
				<div className="f_single fx_single_tbl">
					<div className="tabset">
						<label htmlFor="tab2">
							<span className="mdc-tab__content fx_titlefont">
								<span className="mdc-tab__text-label">Announcements & News</span>
							</span>
						</label>
					</div>
				</div>

				<div className='swanky_wrapper faq'>
				<input id='latest' name='radio' type='checkbox' />
					<label htmlFor='latest'>
						<span className="th_faq">[공지사항] - 2021년 10월 29일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								WREX 회원님!회원 여러분 안녕하세요?
								<br /><br />
								최근 상황에 대해서 공지를 올려드리니 참고해 주시기 바랍니다.
								<br /><br />
								. 2021년 10월 26일 (화)부터 29일 (금)일까지 WREX 웹사이트 및 앱 접속이 안되는 현상이 벌어졌습니다. 지금 문제는 해결되었지만, 원인에 대한 조사가 계속 진행되고 있습니다. 추후, 명확한 원인 조사가 종결되면 공지를 통해 공유해 드리도록 하겠습니다.
								<br/>불편을 끼쳐드린 점 깊이 사과 드립니다

								<br /><br />
								. 1번 공지 내용 관련해서, 원인에 대한 조사가 종결될 때까지 지금까지 시범 운영해온 [이더리움 가격 맞추기 게임]을 잠시 중단합니다. 이는 일시적인 중단으로 새롭게 다시 찾아 뵐 것을 약속 드립니다.

								<br /><br />
								. 2021년 12월 31일 기준으로 저희 WREX 상품에 가입하셨고, 유지하시는 분들 그리고 타임캡슐에 가입하신 회원님들에게 특별한 연말 보너스를 준비하고 있습니다. 이는, 최근 WREX의 저조한 실적에도 불구하고, 믿고 맡겨 주신 분들에게 회사가 할 수 있는 것을 고민한 바 – 아주 특별한 선물을 구상하고 있고, 조금 구체화되면 11월 말 전에 공개할 예정입니다.

								<br/>
								<br /><br />
								감사합니다. 궁금하신 점은 support@w-rex.com 으로 문의해 주십시오.
								<br /><br />
								WREX 올림<br />

							</li></ul>
						</div>
					</label>

				<input id='nr22' name='radio' type='checkbox' />
					<label htmlFor='nr22'>
						<span className="th_faq">[공지사항] - 2021년 4월 23일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								WREX 회원님!
								<br /><br />
								[공지사항] 2021년 4월 23일
								<br /><br />
								[1] WREX 홈페이지 내용 수정 및 [100 이더리움 플랜] 수정
								<br /><br />
								[2] WLOUNGE 신규 개설 – “이더리움 가격 맞추기 게임”

								<br /><br /><br />
								1.	WREX 홈페이지 내용 수정 및 [100 이더리움 플랜] 수정
								<br /><br />
								WREX 홈페이지 내용에 대한 수정이 있습니다. 수정 내용이 보이지 않으실 경우, CHROME에서 재로딩을 하시거나, “F12” 버튼을 누르시고 오른쪽에 창이 뜨면, “Ctrl+Shift+R”을 누르시면 정상적으로 재로딩이 됩니다. 
								<br /><br />
								[100 이더리움 플랜]의 상품은 더 이상 신규 가입되지 않습니다. 기존의 [100 이더리움 플랜] 가입 상품은 바뀐 명칭 [TIME CAPSULE]외에는 변동 사항이 없이 유지하실 수 있습니다.
								<br /><br />
								신규 가입 회원은 2021년 4월 22일자로 [TIME CAPSULE] 상품에 가입하실 수 있습니다. 기존의 [100 이더리움 플랜]과 조건은 동일하나, 24개월의 의무유지기간이 추가되었습니다.
								<br /><br />
								“의무유지기간” – 2년 가까운 WREX 운영기간 동안 저희가 경험한 것으로는, ‘의무유지가간’ 동안 만이라도, “출금요청에 대한 대응”을 줄이거나 제거할 수 있다면, WREX에서 더 많은 수익을 얻을 수 있다는 결론에 도달했습니다. 현재는 전체 운용 이더리움 수량의 10-15%가 출금을 대비해서 포트폴리오의 재진입을 못하고 있는 실정이고, 이는 중장기적/보수적으로 운용하는 저희 포트폴리오 실적에 실제 악영향을 끼치고 있습니다.
								<br /><br />
								타임캡슐의 사전적 의미는 “미래의 어느 시점에서 다시 개봉하는 것을 전제로 그 시대의 대표적인 물건 등을 모아 묻는 용기”입니다. WREX [TIME CAPSULE]에 회원님의 꿈을 담아 WREX와 ‘함께’ 이루는 팀플레이 상품입니다.
								<br /><br /><br />
								2.	WLounge – “이더리움 가격 맞추기 게임”
								<br /><br />
								4월 23일자로 “이더리움 가격 맞추기 게임”을 신설했습니다.
								<br /><br />
								[게임 참여 – 무료!]<br/>
								기존 WREX 회원이시면, 또는 WREX에 신규 가입하시면, 누구나 무료로 계정당 1일 1회 [무료]로 참여할 수 있습니다
								<br /><br />
								[게임 참여 방법]<br/>
								지금 현재는 wlounge.w-rex.com 링크로 오셔서; 아래 3단계를 진행해주시면 제출이 완료됩니다.<br/>
								(1)	“제출 번호” 기입<br/>
								(2)	“제출” 버튼 클릭<br/>
								(3)	WREX 회원 ID/PW 입력<br/>
								<ul>
									<li>제출 후에도 위 단계를 다시 밟으시면, 수정 제출할 수 있습니다. 최종 제출 번호가 회원님의 참여 숫자입니다. </li>
									<li>제출 마감 시간은 한국시간 오후 6시입니다.</li>
								</ul>
								<br/>
								[경품]<br/>
								당첨 번호는 한국시간 오후 9시 정각의 홍콩 소재 BITFINEX 거래소의 이더리움 1개의 미국 달러 가격입니다. 
								<br /><br />
								당첨 번호 (4자리 숫자)를 정확히 맞추신 회원께는 “경품”이 제공되고, 게임 규칙에 대한 자세한 사항은 WLounge내의 “이더리움 가격 맞추기 PLAY BOOK”을 참고해 주시기 바랍니다.
								<br /><br />
								감사합니다.
								<br /><br />
								WREX 올림<br />

							</li></ul>
						</div>
					</label>

					<input id='nr210' name='radio' type='checkbox' />
					<label htmlFor='nr21'>
						<span className="th_faq">[회원 여러분께 드리는 말씀] - 2021년 3월 9일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<br /><br />
								첫 방문하신 여러분 반갑습니다! 그리고 회원 여러분 안녕하십니까?
								<br /><br />
								4월 중순경을 목표로 WREX에 "회원 라운지" 페이지가 신설될 예정입니다. 라운지 페이지에는 이더리움과 암호화폐에 관한 정보 및 교육 자료, WREX에 대한 소개 및 정보, 그리고 재미있게 참여하실 수 있는 [이더리움 가격 맞추기 대회]도 열립니다.
								<br /><br />
								특히 그때 신설될 [이더리움 가격 맞추기 대회]는 매일 개최되며, 모든 WREX 회원이면 무료로 참여하실 수 있으며, 매일 상금이 있어 재미와 행운의 기회를 동시에 누리실 수 있습니다.
								<br /><br />
								마지막으로 WREX에 대해 궁금하신 점이 있으시거나, 어떻게 최선의 투자를 할 수 있는지 등에 대한 궁금한 점 있으시면, 텔레그램 앱에서 @wrexfounder를 추가하셔서 말씀해 주시면 됩니다. 특히, 저희가 진행하고 있는 사업에 동참을 원하시거나 회원님이 갖고 계신 좋은 사업 아이템이 있다면 언제든 주저하지 마시고 제안해 주시기 바랍니다.

								<br /><br /><br />
								감사합니다.
								<br /><br />
								WREX 임원진 올림<br />

							</li></ul>
						</div>
					</label>

					<input id='nr20' name='radio' type='checkbox' />
					<label htmlFor='nr20'>
						<span className="th_faq">[공지] 2021년 3월 8일 - WREX 계좌 제한 해제에 대한 공지</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<br />
								회원 여러분께,
								<br /><br />
								이더리움 가격이 올라서 다음과 같이 WREX 계좌의 제한을 변경하오니 참고해 주시기 바랍니다.
									<br /><br />
									[입금 수량 제한 해제]
									<br />
									과거에는 이더리음 최소 1개 이상 입금을 제한 했으나 앞으로는 소수점 수량도 입금할 수 있습니다.
									<br /><br />
									[최소 수량 유지 제한]
									<br />
									과거에는 WREX 계좌를 유지하려면 이더리움 1개 이상을 유지해야 했지만 앞으로는 이런한 최소 수량 유지가 필요 없습니다.
									<br /><br />
									[출금 수량 제한]
									<br />
									과거에는 출금 후 1개 이상을 유지 하는 수량만 출금할 수 있었으나 이 제한 역시 해지됩니다. 앞으로는 1개 이하를 남기시는 수량의 출금도 가능합니다.
									<br /><br />
									다만 큰 수량의 출금 액수의 경우, 상황에 따라 출금이 지연될 수 있는 점에 대해 미리 양해를 구합니다. 저희가 출급을 일부러 어렵게 만드려는 것이 아니고 저희 운영 포트폴리오가 중장기 투자 성격이 강해 모든 투자가의 수익을 보호하기 위한 불가피한 조치입니다. WREX 계좌는 중장기 투자 목적의 운영 상품이지 은행 계정처럼 입출금을 하는 곳이니 이점 유의하셔서 투자하시기를 권장합니다.
									<br /><br />
									감사합니다.
									<br />
								<br />
									WREX 운영진
									<br />
							</li></ul>
						</div>
					</label>

					<input id='nr19' name='radio' type='checkbox' />
					<label htmlFor='nr19'>
						<span className="th_faq">[W-Rex Promotion] 왜 WREX와 함께 해야 할까요? 이더리움 가격이 1백50만원인데 좋은 결정일까요?</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>

							<br /><br />
							&lt;WREX와 함께하는 투자: 오늘 이더리움 가격에서 자유로워져야 합니다&gt;
<br /><br />
WREX 규칙 1 – 이더리움 가격에서 자유로워지세요!
<br /><br />
1년 전 가격은 2십만원이었죠. 5-6년 전만 하더라도 이더리움 가격은 800원대였습니다. 지금 이더리움 가격은 2백만원을 넘어섰다 다시 조정기간을 맞고 있습니다.
<br /><br />
투자의 수익을 극대화하는데 있어서 방해요소는 바로 진입 가격입니다. 진입 가격이 비싸서 시작을 못하는 겁니다. 이런 분들에게 저희는 과감하게 말씀드립니다. 이더리움 2개로 WREX를 시작해 보세요.
<br /><br />
좋은 투자는 좋은 투자처에 투자하고 투자 수익을 극대화하는 것입니다. 암호화폐 투자에 있어서 수익을 내기 위해서는 두 가지 방법이 있습니다. (1) 낮게 사서 비싸게 파는 방법이 있고 (2) 이더리움 수량을 늘려서 내가 산 가격보다 비싸게 파는 겁니다.
<ul className="li_simple"><li>
								(1) 낮게 사서 비싸게 파는 방법
	<br />
백전백승 한다면 굳이 저희 WREX 서비스를 이용하실 필요가 없습니다. 꾸준한 수익을 40개월 이상 내고 계신다면, 저희 WREX 서비스를 이용하실 필요가 없습니다. 아래 중 해당 사항이 있다면 WREX 서비스를 이용하시면 됩니다.
<br />가. 거래소에 아직 남겨 놓은 암호화폐자산이 있다
<br />나. 내가 사면 떨어지고, 팔면 올라가더라 (거래에 소질이 없다)
<br />다. 24시간 돌아가서 잠을 설치거나 불안하다
<br /><br />
회원님이나 저희는 이더리움 가격을 조정할 수 없습니다. 즉, 직접 거래를 하신다면 다들 한번씩은 경험했을 겁니다. “낮게 사서 높은 가격에 팔기가” 생각보다 쉽지 않다 라는 것을 것.
<br /><br />
저희의 많은 경험상, 개인이 낮게 사서 비싸게 파는 방법으로 사고 팔고를 반복하다 보면 100% 잃게 되어 있습니다. 저희 사업의 목적은 (1) 보다 (2) 이더리움 수량 늘리기에 초점이 맞춰져 있습니다.
</li><li>(2) 이더리움 수량 늘리기 – WREX 사업
<br />
저희 WREX는 40개월 이상 매월 수익을 냈습니다. 그리고 매월 수익의 일정 %를 투자자에게 그리고 투자를 권한 소개자에게 나눠드립니다. 새벽에 자주 깨고, 불안해하고, 사면 떨어지고, 팔면 올라가는 것에서 자유로워지세요.
<br /><br />
저희는 3단계 알고리즘 시스템으로 중장기 투자를 통해 이더리움 수량을 매달 늘려 24개월 후 또는 36개월 후 회원님의 이더리움 수량을 늘려드리는 일을 하고 있습니다. 시장 상황이 좋던 나쁘던 상관없이 매월, 40개월 이상, 연속으로 수익을 내고 있습니다.
</li></ul>
WREX 투자는 초단타, 단타가 아닌 중장기형 포트폴리오를 운영합니다. 그래서 적절한 수량의 이더리움을 투자하실 것을 권장합니다. 24개월/36개월을 투자하세요. 그 사이에 이더리움 가격은 올라갔다 ‘내려 갔다’를 반복할 겁니다. 아무도 이더리움의 최고 가격을 모릅니다. 24개월동안 저희가 이더리움 수량을 늘려드리는 동안 회원님은 그 사이의 이더리움 최고 가격을 확인하고, 24개월이 지나면 그 최고 가격이 회원님의 “희망 출구 가격”이 되는 겁니다. 그 가격이 3백만원이 될 수도 5백만원 그 이상이 될 수도 있습니다. 24개월 후 그 희망 출구 가격에 못 미치는 가격이 형성되어 있을 수 있습니다. 그렇다면 더 기다리십시오. 24개월 기다렸는데 6개월, 12개월, 그 이상 또 못 기다릴 이유는 없습니다. 왜냐면, 24개월이 지나고 희망 출구 가격이 다시 올때까지 기다리는 동안에도 매달 수익율에 따라 이더리움 가격은 늘어나기 때문입니다.
<br /><br />
&lt;기다리는 자만이 수익을 극대화합니다&gt;
<br />
WREX 규칙 2 – 여유 자금만을 투자하십시오.
<br /><br />
내가 앞으로 24개월 동안 필요 없는 자금 만을 투자하십시오.
중간에 가격이 올라도, 중간에 가격이 폭락해도, 어떠한 상황이 오더라도 걱정할 필요가 없습니다. 24개월 동안 가격과 상관없이 매월 수익율을 확인하면 됩니다. 이더리움 가격 20만원에 WREX 회원이 되신 분들 중에 40만원에 그리고 80만원대에 출금하신 분들이 많습니다.
<br /><br />
결국은 여유로운 자금을 투자하신 분들만 아직도 계속 저희 서비스를 이용하고 계십니다. 저희의 목표는 수익을 극대화하는 겁니다. 시간이 오래되면 될수록 이더리움 수량을 계속 늘어납니다. 파는 시점은 WREX를 시작한 시점부터 24개월이 지난 시점 사이에 형성되는 “이더리움의 가장 높은 가격”이 “희망 출구 가격”이 되어야 합니다.
<br /><br />
&lt;오늘부터 WREX와 함께 하세요&gt;
<br /><br />
이더리움의 가격은 우리가 결정할 수 없지만, WREX 최초 투자시점에서 24개월 사이의 이더리움 최고 가격을 “희망 출구 가격”으로 정할 수 있습니다. 그리고 “여유” 자금을 투자했을 때만, 기다릴 수 있는 여유로움이 생깁니다. 위 2가지 규칙을 지키고 매월 불어나는 이더리움을 보면서 수익 극대화를 경험해 보십시오.
<br /><br />
수익의 극대화를 위해서는 보다 많은 주변 분들께 저희 WREX를 추천하는 방법입니다. 매월 총 수익에 5%를 추천한 이더리움 수량에 따라 추천인에게 드립니다. 지금까지 가장 많은 추천 보너스를 받고 있는 WREX 회원은 월평균 이더리움 2.67개를 추천 보너스로 받고 있습니다. 현재 시세로 치면 4백5십만원 수준입니다.
<br /><br />
WREX Team
<br /><br />
어떻게 하면 추천보너스를 받을 수 있는지 확인해 보시고 궁금한 점이 있으면 언제든지 <a href="mailto:support@w-rex.com" class="animate-this">support@w-rex.com</a> 으로 연락 주시기 바랍니다.


						</div>
					</label>

					<input id='nr18' name='radio' type='checkbox' />
					<label htmlFor='nr18'>
						<span className="th_faq">[공지사항] 2021.02.03</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원님께,
								</p>

									드디어 가장 어려웠던 시점을 뒤로 하고, 앞으로는 조금씩 개선된 실적으로 찾아 뵐 수 있을 것 같습니다.
									<br /><br />
									저희 WREX가 시작되고, 11.01%의 월 평균수익률 실적을 기록했으나, 코로나 사태 이후 질병의 장기화로 인하여 실적이 월평균 3.12%로 곤두박질 쳤습니다.
									<br /><br />
									이러한 실적 저하는 두가지 원인에 기인합니다. 첫째, 코로나 사태 장기화로 인하여 회원님들의 출금 요청이 신규 가입 내지는 입금 보다 훨씬 많았습니다. 초기부터 저희 투자 포트폴리오는 중장기 포지션에 수익을 극대화하는 전략으로 진행되었으나, 많아진 출금 요청을 대응하는 과정에서 포트폴리오의 주요 자산을 조기 청산하여 수익율이 좋아질 수가 없었습니다.
									<br /><br />
									둘째 이유로는 이더리움의 급격한 가격 상승에 있었습니다. 저희가 WREX 프로젝트를 시작했을 당시만 해도 이더리움의 가격이 USD 100불대였는데, 최근 들어 급격하게 가격이 상승해 현재는 USD 1,300불대에 형성되어 많은 회원님들이 차익을 실현하기 위한 출금 요청 또한 많았습니다.
									<br /><br />
									그리고 위 두가지가 복합적으로 작용하여 코로나사 태 장기화로 인해 가계의 수입 저조와 이더리움의 가격 상승으로 인해 가계의 수입 확보 차원에서의 출금 역시 많았던 것으로 파악되었습니다.
									<br /><br />
									이러한 시대적 상황을 겪으면서 저희 WREX 역시 진화하고 있습니다. 내부 규정을 변경하고, 투자 전략을 수정하여 앞으로의 비슷한 상황이 전개되더라도 저희가 운영하는 포트폴리오에 영향을 덜 받으며, 안정적인 수익율을 유지할 수 있게 전략을 바꾸었습니다.

									<br />
									마지막으로 당부 드리고 싶은 말씀은 “WREX에 투자는 반드시 없어도 되는 여유자금 만을 투자 하십시오” 입니다. 사회에서 심심찮게 코인이나 주식에 대출까지 받아서 일확천금을 노리는 묻지마식 투자가 성행하는데, 저희 WREX는 적은 자본으로 장기적 투자를 통해 비교적 안정적이나 많은 투자 수익을 실현할 수 있는 시스템은 맞습니다. 그러나, 장기적이라는 조건부 투자이기 때문에 당장 그리고 미래에 필요 없는 여유 자금으로 투자를 하는 것이 적절하다고 권장 드립니다.
									<br />
									이러한 관점에서 몇 가지를 공유 드리고자 합니다.
									<br />
								<ol>
									<li>
										2010년 5월 22일은 그 유명한 비트코인 피자의 날입니다. 당시 Hanyecz가 비트코인을 이용해서 첫 상업거래를 성사시킨 역사적인 날이기도 하죠. 그는 2판의 피자를 구입하는데 10,000개의 비트코인을 지불했습니다. 12년이 지난 오늘 시세로 환산하면 무려 3천8백억원이란 말도 안되는 비용을 지불했죠. 누가 이렇게 오를 것이라고 상상이나 했겠습니까?
										<br />
										만약 오늘 날 시세가 3천8백억원이라고 알았다면 그가 피자 두 판에 10,000개의 비트코인을 지불 했을까요? 여러분은 어떠시겠습니까?
										<ul>
											<li>
												실제로 미래의 일은 우리가 알 수 없습니다. 불확실성이 너무 크죠. 하지만 비트코인, 이더리움 그리고 다른 기타 주요 알트코인들은 시간이 지나면 지날수록 가치는 상승할 것이라고 많은 전문가들은 이야기 합니다. 그에 대한 근거는 단순합니다. 희소성 (코인의 제한된 수량)과 수요와 공급이라는 법칙에 의해 지속적으로 상승할 것이라는 겁니다.
												<br /><br />
												그렇다면, 작은 투자, 즉 저희가 말씀드리는 “감당할 수 있을 정도의 투자”, 순수하게 내가 가지고 있는 자신 중에 5년, 나아가 10년은 없어도 되는 자금을 은행에 예치하는 것 보다는 이더리움을 매입해서 WREX에 투자하는 것을 생각해 보시라는 겁니다.

											<br /><br />
											이더리움이 5십만원 되었다고 파는 것이 아니고, 1백만원이 되었다고 파는 것이 아닙니다. 최소 3년에서 5년은 보유하십시오. 그리고 정말 어디가 끝인지 기다려 보세요. 1천만원이 되는 시대 또는 그 이상의 가치를 가질 수 있는 시대가 올 수도 있다는 것 입니다. 지금 그리고 앞으로 필요하지 않은 자금을 투자하는 이유가 바로 이 기다림을 유지할 수 있는 원동력입니다.
											<br />
											</li>
										</ul>
									</li>
									<li>
										빌 게이츠, 제프 베조로스, 일론 머스크 등 억만장자들이 그 들의 자산을 어떻게 형성했을까요? 그 들의 부에 가장 큰 비중을 차지하는 것은 그 들이 보유한 그들의 회사의 주식입니다. 주식의 가치와는 상관없이 보유하고 있기 때문입니다. 빌게이츠가 1불하던 주식을 10불 되었다고 다 팔아 버렸으면, 당시 현금을 많이 가지고 있을 수 있으나 지금의 부를 형성할 수 있었을까요?
										<br />
									</li>
									<li>
										WREX를 만들었던 창업자도 형편없는 투자가 였습니다. 그가 코인을 사면 가격이 떨어졌으며, 팔면 오르고. 여러분은 어떠신가요? 그래서 그는 WREX를 생각하게 되었던 것입니다. 그리고 이 분야에 전문가 집단인 WOTA와 협업하여 WREX를 런칭했습니다. 그리고 더 이상 그래프를 보며 분석한다고 시간을 투자할 일이 없어졌습니다. 그냥 편하게 다른 일에 집중할 시간이 더 많아졌고 삶의 질도 향상되었다고 합니다.
										<br /><br />
										창업자는 WOTA에 WREX를 런칭하기 전부터 투자금을 위탁해 왔습니다. 2019년 초에 그는 100개의 이더리움을 매입하여 WOTA에 위탁을 했고, WREX가 런칭한 9월에 WREX로 이관되어 지금까지 그의 자산도 여러분의 자산과 같이 운영되고 있습니다.
										<br /><br />
										그가 시작할 당시 100개의 이더리움은 현재 245개로 늘어났고, 당시 1개의 이더리움을 15만원에 구매했으니 초기 투자금액은 약 1천5백만원 정도였습니다. 현재의 창업자의 WREX 자산 가치는 약 3억 5천만원으로 증가했죠. 불과 2년도 안된 시점에서 말입니다!
										<br /><br />
										그에게 지금 시점에서 20배가 넘는 수익을 실현하지 않는 이유를 물어보니 대답은 단순합니다. 그는 “어차피 2년 전 1천5백만원은 내가 가용할 수 있는 여유 자금이었고, 지금도 그 자금이 필요하지는 않다. 굳이 지금까지 기다렸는데 앞으로 2-3년을 더 못 기다리겠냐. 2-3년 후 이더리움이 개당 1천만원이 넘어가면 그때 한번 고려해 보겠다.
										<br /><br />
										그리고 지금의 가격 상승은 기분은 좋으나 떨어진다고 해도 기분이 나쁠 이유는 없다. 어차피 떨어진 것은 올라가고, 올라가는 것은 떨어지는 것은 당연한 것 아닌가. 이더리움은 충분한 가치가 있다고 생각한다. 지금부터 3년 후, 현재의 245개가 5백개 또는 6백개가 되고, 이더리움 가격이 1천만원이 된다면 최소 50억원이란 자산이 된다. 지금은 다른 일에 집중하고, 이 자산은 노후 자금이라고 생각하면 마음이 편하다.” 라고 말한다.
										<br /><br />
										2019년 초에 투자한 1천5백만원이 2021년 1월 기준 3억5천만원이 됐고, 2024년 1월에 정말 50억원이 된다면, 아니 최소한 15억원만 되더라도 5년 사이에 100배의 수익률인데 이것을 굳이 지금 바꿀 이유가 전혀 없다는 그의 이야기이다.
										<br /><br />
										그는 지난 해 말 코로나 사태와 이더리움 급작스러운 가격 폭등으로 그와 투자를 함께 했던 친구들이 일부 5십만원대에 청산하고, 일부는 80만원대 청산을 했다고 한다. “안타깝다. 조금만 기다렸으면 1백50만원에도 팔 수 있었는데… 하지만 이것 또한 결과론적인 것이어서 그는 의미 없는 이야기라고 말한다. 만약 이더리움이 10만원대로 폭락했다면 그들은 분명 승자였을 것이다.
										<br /><br />
										투자란 불로소득이다. 나는 직장에 다니며, 또는 사업을 하는 동안, 또는 집에서 쉬는 동안 돈이 돈을 만드는 것이다. 즉, 이더리움이 10만원에서 100만원이 되고 1천만원이 될 수 있듯이 다시 10만원대로 떨어질 수도 있다. 그래서 내 판단에 의해 저점에서 사고 고점에서 팔고를 고민할 시간에 차라리 다른 일을 하는 것이 좋겠다고 생각했다. 내가 원하는 것은 5년 동안 이더리움의 최고점이 어디까지 갈지 궁금해 하는 것이다. 1천만원을 찍고 다시 떨어진다 해도 언젠가는 다시 1천만원에 가까운 또는 그것을 넘어가는 시점은 반드시 온다. 그때 청산해도 늦지 않다는 것이고, 그게 1년이 걸리던 5년이 걸리던 기다리면 된다. WREX는 그 기다리는 시간 동안 내 이더리움 수량을 불려주는 역할을 한다. 기다림, 이것이 내가 보는 투자의 관점이다. 그리고 말했듯이 기다림의 원동력은 여유이다. 절대 [빌린 돈], [써야할 돈], [예정되어 있는 돈]을 투자하면 안된다. 5년이고 10년이고 없어도 되는 돈을 투자하는 것이 이 기다림의 과정에서 나를 지켜줄 수 있는 원동력이다”


										</li>

								</ol>

								<br />
								** 위 내용은 WREX 창업자는 투자자문 전문가가 아니며, 이더리움 투자와 WREX 사업에 대한 개인적 생각일 뿐이고, 위 내용은 투자 자문이 아님을 명시합니다. 투자를 고려하신다면 여러 전문가의 조언을 바탕으로 스스로 공부를 많이 하시길 추천 드립니다.
								<br />
								W-Rex Management<br />

							</li></ul>
						</div>
					</label>

					<input id='nr17' name='radio' type='checkbox' />
					<label htmlFor='nr17'>
						<span className="th_faq">[공지사항] 2020.11.10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									회원 여러분 안녕하십니까,
								</p>

								<ul>
									<li>
										이번 달 성적표가 회계법인의 마지막 감사 절차를 밟고 있고 하루 이틀 안에 발표되며 바로 귀하의 계정에 수익이 적용될 예정입니다. 내부적으로 약 2개월 전부터 출금요청이 줄어들 것으로 예상한 것과 반대로 10월 그리고 11월에도 출금요청 수량이 계속 유지되면서 현재 운영중인 포트폴리오에 좋지 않은 영향을 끼치고 있습니다. 일전에도 공지를 통해 말씀드린 것처럼 코로나 사태와 이더리움 가격 상승으로 인해 많은 투자가들이 출금요청을 했으며, 실제로 이러한 현상을 통해 신규투자는 계속 줄어들며, 출금이 많아지는 현상으로 인해 포트폴리오를 조기 청산해야 하는 상황이 연속되다 보니 수익율에 많은 영향을 끼치고 있습니다.
									<br />
									</li>
									<li>
										하지만, 최근 좋은 소식을 만들어 가기 위해 내부적으로 준비하는 것들이 있습니다. 좋은 소식이란 건 단순하죠. 월 수익율을 두자리로 만드는 것 만큼 좋은 소식이 없겠죠? 그리고 내부적으로는 포트폴리오가 건강하게 성장할 수 있도록 제도적 장치를 마련하는 것입니다. 내년 1월을 기점으로 실제 WREX의 2단계 성장기를 맡게 될 예정입니다. 현재 2곳의 자산 운용사와 화이트라벨 사업을 제안 받아 진행 중에 있습니다. 신규 투자금이 아주 많이 유입될 예정입니다. 신규 자금이 중요한 것이 아니라, 이 자금은 단타성 자금이 아닌 장기적인 예치 자금으로 포트폴리오의 지대한 안정성을 제공할 수 있기 때문에 보다 안정적인 수익을 제공할 수 있을 것이기 때문에 모두에게 좋은 소식이 될 수 있도록 최선을 다하고 있습니다. 그리고 앞으로는 매달 두자리수 수익을 위해 많은 노력을 기울이도록 하겠습니다.
									<br />
									</li>
									<li>
										작년 9월 런칭과 함께 저희를 믿고 투자해 주신 분들의 자산이 이미 3-4배가 되신 것으로 파악됩니다. 이 분들이 올해 중반에 수익실현 하셨으면 2-3배 정도에 청산하셨을 것이고, 지금 시점에 청산하시면 3-4배가 될 것입니다. 그러나 저희는 이더리움의 지금 가격은 아주 저평가된 가격이라고 믿고 있습니다. 아직은 청산할 시점이 아니라는 점을 강조 드립니다.
									<br />
									</li>
								</ul>
								내년 3월부터는 자산 운용사의 화이트라벨 사업과 포트폴리오의 안정성을 더해 더 많은 월별 수익을 창출할 수 있도록 계속 노력하겠습니다.
								<br />
								<br />
								감사합니다.<br />
							</li></ul>
						</div>
					</label>

					<input id='nr16' name='radio' type='checkbox' />
					<label htmlFor='nr16'>
						<span className="th_faq">[공지사항] 2020.08.20 </span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원 여러분!
								</p>

								<ol>
									<li>
										2020년 7월 수익률은 회계법인을 통해 3.71%로 확정되었고, 이미 10일에 반영되었습니다. 코로나 사태를 기점으로 WREX의 수익률이 저조한 것을 의아해하고 계시리라 생각되어, 저희 운영팀에서는 어떻게 하면 수익률을 만족할 만한 수준으로 끌어 올리고, 그리고 안정적으로 운영할 수 있는지에 대해 고민을 하였고 다음과 같이 공지 드립니다.
									<br />
									문제점: 월 수익률 저조<br />
									이유: 코로나 사태로 인한 신규 입금 보다 인출이 많아 지면서 중장기 투자 플랫폼인 WREX에 수익률을 지속적으로 악화시키고 있음<br />
									또한, 차후 다른 외부 요인으로 인한 다량의 이더리움 출금으로 이어질 경우, 또 수익률에 영향을 끼칠 것으로 판단되어, 해결책으로 신규 가입 회원들의 약관을 아래와 같이 수정하여 시행할 계획입니다.
									<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td colspan="2" scope="Ranking">코로나 발생 이전</td>
													<td colspan="2" scope="Prize">코로나 발생 이후</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 9월</td>
													<td data-label="Prize">16.52%</td>
													<td data-label="Ranking">2020년 2월</td>
													<td data-label="Prize">3.94%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 10월</td>
													<td data-label="Prize">11.72%</td>
													<td data-label="Ranking">2020년 3월</td>
													<td data-label="Prize">4.71%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 11월</td>
													<td data-label="Prize">10.07%</td>
													<td data-label="Ranking">2020년 4월</td>
													<td data-label="Prize">3.31%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 12월</td>
													<td data-label="Prize">6.80%</td>
													<td data-label="Ranking">2020년 5월</td>
													<td data-label="Prize">3.48%</td>
												</tr>
												<tr>
													<td data-label="Ranking">Jan 2020</td>
													<td data-label="Prize">9.92</td>
													<td data-label="Ranking">2020년 6월</td>
													<td data-label="Prize">5.29%</td>
												</tr>
												<tr>
													<td data-label="Ranking"></td>
													<td data-label="Prize"></td>
													<td data-label="Ranking">2020년 7월</td>
													<td data-label="Prize">3.71%</td>
												</tr>
											</tbody>
										</table><br />
									</li>
									<li>
										저희 플랫폼은 중·장기적인 계획을 가지고 운영하여 매월 수익을 창출하여 [수익]을 배분하는 목적을 가지고 있습니다. 절대, 어떠한 경우도, 다른 회사들과 달리, 투자금에 일부를 투자유치 성과금 또는 수당으로 배분하지 않음을 다시 한번 말씀드립니다. 투자금이 원금이 되어, 운영하고 되고, 운영으로 인한 수익을 배분하여, 다음 달 배분된 수익이 포함된 총 이더리움 수량이 다시 ‘원금’이 되어 운영되는 시스템입니다.
									<br />
									저희가 매월 수익을 창출한다면, 저희는 매달 성장하는 시스템입니다. 지난 40개월을 운영하면서 단 한번도 마이너스 수익을 기록한 적이 없습니다. 그리고 매월 세계 10대 회계법인을 통해 감사를 받아 저희 사업이 안정적으로 진행되는지를 확인하실 수 있습니다.
									<br /><br />
									</li>
									<li>
										저희는 중·장기적인 투자의 관점을 두고 운영을 하는 회사이기에, 급작스럽게 많은 량의 투자금이 인출되면, 인출되는 것에 대해 문제가 없으나, 기존 포트폴리오의 수익률에 영향을 주고 있으며, 남은 투자가들이 그 피해를 고스란히 떠안고 있는 실정입니다.
									<br /><br />
									이에 저희는 급작스러운 출금을 막기 위해 기존 [72시간 출금]에서 [최대 한달]이 걸릴 수 있다고 과거 공지를 통해 고지하고 약관을 수정했으며,
									<br />
									코로나 사태로 인해 또는 향후 있을 수 있는 집단 인출에 기존의 포트폴리오와 회원들의 수익을 보호하고자 추가적인 약관 수정을 안내해 드리겠습니다.
									<br /><br />
									</li>
									<li>
										수정될 내용은 다음과 같으나, 기존의 회원들에게는 적용되지 않으며, 2020년 8월 1일부로 개설된 회원 또는 상품에만 적용이 됩니다.
									<br />
										<ol>
											<li>
												Wrex 계정
									<table className="simpeltable">
													<tbody>
														<tr>
															<td className="emphsize boldfont" scope="Ranking">수정 전</td>
															<td className="emphsize boldfont" scope="Ranking">수정 후</td>
														</tr>
														<tr>
															<td scope="Ranking">WREX 계정에 최소 1 이더리움 투자</td>
															<td scope="Ranking">WREX 계정은 이더리움 오직 1개만 투자 가능</td>
														</tr>
													</tbody>
												</table>
									** 중요: 기존 회원들의 계정 역시, 9월 1일부로 WREX 계정에 신규 이더리움을 추가 입금하실 수 없습니다.
									</li>
											<li>
												100 이더리움 플랜
									<table className="simpeltable">
													<tbody>
														<tr>
															<td className="emphsize boldfont" scope="Ranking">수정 전</td>
															<td className="emphsize boldfont" scope="Ranking">수정 후 (2020년 8월 1일부)</td>
														</tr>
														<tr>
															<td scope="Ranking">100 이더리움 플랜은 오직 1개의 이더리움만 투자 가능. 생성 후 추가 입금은 불가능</td>
															<td scope="Ranking">100 이더리움 플랜 생성시 최소 1개에서 최대 100개까지 투자 가능하나, 생성후 추가 입금은 불가능</td>
														</tr>
														<tr>
															<td scope="Ranking">40개월 만기</td>
															<td scope="Ranking">24개월 만기</td>
														</tr>
														<tr>
															<td scope="Ranking">조기 해지 패널티 없음</td>
															<td scope="Ranking">조기 해지 패널티 있음<br /> (패널티: 회원이 받는 수익의 75%) </td>
														</tr>
													</tbody>
												</table>
											</li>
										</ol>
										<br />
									앞으로도 좋은 수익률로 찾아 뵐 수 있도록 항상 노력하는 회사가 되겠습니다.
									</li>
								</ol>
								<br />
								감사합니다.
								<br />
								<br />
								WREX 경영진 올림<br />
							</li></ul>
						</div>
					</label>

					<input id='nr15' name='radio' type='checkbox' />
					<label htmlFor='nr15'>
						<span className="th_faq">[공지사항] 2020.06.10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									Wrex 회원 여러분,
								</p>

								<ul>
									<li>
										드디어 금주 월요일부터 지난 3개월간 코로나 바이러스 확산 방지로 인한 사회적 거리두기 및 이동금지령이 해제된 후 진행되고 있습니다. 오늘 중으로 끝날 것으로 예상했으나, 하루 정도 지연될 예정이어서 이렇게 공지사항을 통해, 5월 수익 배분이 11일경에 집행될 것임을 알려드립니다.
									<br />
									</li>
									<li>
										저희 WREX는 공정하고 투명한 운영을 위해 매달 외부 회계법인의 감사를 진행하고 있으며, 이 과정에서 (1) 입출금 계정에 대한 감사 (2) 거래 내역 확인 및 감사 (3) 매달 수익률 확정 의 절차를 진행하고 있습니다. 코로나 사태로 인해 못한 감사 및 감사보고서는 11일 경 5월 수익 배분과 함께 사이트 내의 지원 페이지에 업로드 될 예정입니다.
									<br />
									</li>
								</ul>
								하루 지연 예정에 대해 송구스럽게 생각합니다. 아울러 회원 여러분의 많은 양해를 구합니다.
								<br />
								감사합니다.
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr14' name='radio' type='checkbox' />
					<label htmlFor='nr14'>
						<span className="th_faq">[공지사항] 2020.05.11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									W-Rex 회원님들께,
								</p>

								<ul>
									<li>
										항상 W-REX 팀은 회원님과 회원님의 가족들의 건강과 행복을 기원드립니다. 지난달 공지에도 현재 COVID19 (코로나)의 여파로 인해 3월 수익률에 대해 회계 감사를 못 받고 있다고 전달드렸고,, 이번 달 역시 회계감사 없이 4월 수익률을 공지를 올리게 되었습니다. 그나마 다행인 것은 본사가 위치한 말레이시아에 “이동금지령”이 다소 완화되었고, 앞으로도 점차 적으로 완화 조치가 확대될 것으로 기대되고 있습니다. 그렇다고 이동의 자유가 100% 해소된 것은 아니지만, 이 정도 분위기 라면 6월에는 이동금지령이 완전히 철회되고 예전처럼 돌아갈 것으로 기대하고 있습니다.
									<br />
									</li>
									<li>
										현재 저희는 회계법인과 함께 3월과 4월 회계 감사를 진행하고 보고서가 나오는 대로 따로 공지와 함께 홈페이지에 개제할 것을 약속드립니다.
									<br />
									</li>
									<li>
										4월의 월 수익률은 3.31%를 기록했습니다. 이는 저희가 목표로 하고 있는 월평균 수익률 15%에 못 미치는 수치로 코로나 이전에 평균 10%를 웃돌던 평균이 코로나 사태 이후 3~5%대를 기록하며 전체적으로 부진한 실적으로 나타나고 있습니다. 이는 코로나 사태가 저희 투자팀 업무에 직 간접적 영향을 주고 있는 점과 둔화된 시장 환경에도 이유가 있는 것으로 파악되고 있습니다. 이는 코로나 사태를 극복하고, 이동 제한이 해제되어, 정상적인 일상 업무 복귀가 가능한 시점과 시장의 재활성화를 통해 만회할 수 있도록 최선을 다하겠습니다.
									<br />
									</li>
									<li>
										지금까지 보여주신 응원과 성원을 앞으로 몇 달간 지켜봐 주시기를 부탁드립니다.
									<br />
									** 이번 주 목요일에 저희가 준비하고 있는 WOTA 코인에 대한 전체적인 공지를 할 예정입니다.
									</li>
								</ul>
								감사합니다.
								<br />
								<br />
								WREX 팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr13' name='radio' type='checkbox' />
					<label htmlFor='nr13'>
						<span className="th_faq">[공지사항] 2020.04.10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
								</p>

								<ol>
									<li>
										코로나19 사태에 항상 건강하시길 기원 드립니다.
									<br />
									</li>
									<li>
										지난 번 공지에서 말씀드린 바와 같이, 이번 달은 회계법인의 회사 방문 및 감사 없이 일단 내부 수익률을 계산해서 금일 23시59분 전에 반영할 예정이며, 본사의 국가 비상시국 “이동 금지령”이 해제되면 감사를 통해 감사보고서를 올리도록 하겠습니다.
										<br />
									</li>
									<li>
										금일 반영되는 3월 수익률은 4.71%입니다.
									<br />
									지난 달과 같이 저희 기준에서는 수익률이 저조한 편입니다. 이는 실제 코로나19가 시작한 후부터 직원들의 위축 그리고 지금의 이동금지령까지 이어지면서 효율적인 거래가 이루어지기 힘들었던 부분도 있었음을 말씀드립니다.
									이 점은 점차 개선되고 있으며, 4월 수익율은 훨씬 좋은 성적으로 찾아 뵐 수 있도록 노력하겠습니다.
									<br />
									참고로, 2월과 3월에 저희 수익률을 제외하고 타 시장 (증권, 금, 석유 등 거의 모든 상품 시장)은 막대한 마이너스 성장을 기록하며 제자리 걸음 또는 손실을 초래했습니다. 저희 자산은 그나마 매달 5%내외의 성장율을 기록하고 있다는 점을 알려드립니다.
									<br />
									</li>
								</ol>
								감사합니다.
								Best Regards,
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr12' name='radio' type='checkbox' />
					<label htmlFor='nr12'>
						<span className="th_faq">[공지사항] 2020.04.06</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원님께,
								</p>
								<br />
								안녕하십니까? WREX 회원님
								<ol>
									<li>
										코로나바이러스로 인하여 세계 여러 지역으로 확산되고 있는 지금 현재 말레이시아는 신종 코로나바이러스 확산 방지를 위해 엄격한 이동제한 명령이 내려졌습니다.
										[소방, 경찰, 군, 보건 관련 공무원 외 전부 자택근무, 모든 사기업 자택근무, 병원, 약국, 슈퍼마켓을 제외한 모든 소상공인 매장 폐쇄 등]
									<br />

									</li>
									<li>
										이번 이동 제한 조치로 인해 (1) 직접 계정 감사를 위해 저희 사무실 내방이 필수인 Baker Tilly의 회계 감사 결과 보고서 및 월 수익률 산정이 지연될 예정이며,
										(2) 수익률은 내부 산정치로 먼저 10일 반영하여 배분하고, 추후 이동제한 조치가 해제되어 회계 감사가 실행되면 그때 보고서를 기준으로 조정이 필요하면 조치하도록 하겠습니다.
									<br />
									코로나바이러스(코로나19) 때문에 걱정이 많아지는 요즘 비록 힘들고 어려운 시기이지만 이러한 위기들도 철저한 예방과 지침으로 잘 이겨낼 수 있을 것입니다.
									<br />
									</li>
									<li>
										코로나바이러스(코로나19) 때문에 걱정이 많아지는 요즘 비록 힘들고 어려운 시기이지만 이러한 위기들도 철저한 예방과 지침으로 잘 이겨낼 수 있을 것입니다.
									<br />
									감사합니다.
									<br />
									아래 링크는 말레이시아 이동제한 명령에 대한 기사입니다.
									<br />
										<a href="https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown">
											https://www.voanews.com/science-health/coronavirus-outbreak/malaysia-arrests-thousands-amid-coronavirus-lockdown</a>
										<br />
									</li>
								</ol>
								<br />
								W-REX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr11' name='radio' type='checkbox' />
					<label htmlFor='nr11'>
						<span className="th_faq">[공지사항] 2020.03.17</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									WREX 회원님께,
								</p>
								<ol>
									<li>
										2019년 9월 1일로 시작한 저희 WREX 서비스는 2020년 2월에 월 수익률 3.94%를 기록하였습니다. 이는 지금까지의 월수익을 비교하면 최저치입니다.
										<br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">WREX 기간</td>
													<td scope="Prize">월 수익률 </td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 9월</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 10월</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 11월</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2019년 12월</td>
													<td data-label="Prize">6.80%</td>
												</tr>
												<tr>
													<td data-label="Ranking">2020년 1월</td>
													<td data-label="Prize">9.92</td>
												</tr>
												<tr>
													<td data-label="Ranking">2020년 2월</td>
													<td data-label="Prize">3.94%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											평균적으로는 6개월 동안, 매달 9.828%를 기록했습니다.
										</p>
									</li>
									<li>
										위 월평균 수익률 수치는 회원님께서 1 이더리움을 WREX 계정에 적립하여 48개월 (4년)동안 유지하시면, 7.6이더리움으로 증가되는 수치입니다. (누적수익률 760%)
										<br />
										100이더리움 계정에 적립하여 48개월 (4년)을 유지하시면 2,100%의 누적 수익률입니다.
										<br />
										다른 그 어떤 투자와 비교할 수 없는 수치입니다.
										<br />
									</li>
									<li>
										비록 2월 한달 저희 기대 수익률에 못 미치는 수치를 기록했지만, 앞으로 좀 더 향상된 수익률로 찾아 뵐 수 있도록 노력하겠습니다.
									<br />
									</li>
								</ol>
								감사합니다. <br />
								<br />
								WREX 운영팀<br />
							</li></ul>
						</div>
					</label>

					<input id='nr10' name='radio' type='checkbox' />
					<label htmlFor='nr10'>
						<span className="th_faq">[공지사항] 2020.02.12</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										안녕하십니까 WREX 회원님!<br />
									</p>
									<ol>
										<li>
											Baker Tilly의 회계 감사 결과 1월 월수익률은 9.92%로 기록되었습니다. 금일 Baker Tilly는 월 수익률 보고에 대한 발표가 늦어진 것에 대해 사과를 했고 공식 보고서는 Baker Tilly로부터 보고를 받는 대로 게재될 것 입니다.
										<br /><br />
										</li>
										<li>
											2월 첫째 주에 홍콩 신탁 기금의 첫 번째 자금이 집행되었습니다. 이제 우리는 귀중한 회원님의 자금 뿐만 아니라 홍콩에 기반을 둔 신탁 기금의 자금도 관리하게 되었습니다. 홍콩 신탁 기금은 주 단위로 최대 1억 달러 상당의 이더리움으로 입금될 예정입니다.
										<br /><br />
										</li>
										<li>
											변경된 정책 발표입니다. WREX가 출시 된 이래로 WREX는 단 6번의 출금 요청만 있었습니다. 변경 전 출금은 모두 72 시간 내에 처리되었습니다. 그러나 우리는 거래 부서로부터 이러한 출금으로 인해 월별 실적이 떨어질 수 있다는 우려를 받았으며,
											이러한 출금이 전체 월별 실적에 부정적인 영향을 미치지 않도록 하고자 합니다. 따라서 내부적으로 출금 정책을 논의하고 변경을 진행하도록 할 것입니다.
										<br />
										</li>
									</ol>
									<br />변경 전: WREX 상품 또는 100ETH 플랜 상품에서 나의 이더리움 지갑으로의 출금 요청은 최대 72 시간이 소요될 수 있습니다.<br />
									<br />변경 후: WREX 상품 또는 100ETH 플랜 상품에서 나의 이더리움 지갑으로의 출금 요청은 최대 4주가 소요될 수 있습니다.<br />
									<br />그러나 회원님의 출금액이 50이더리움 미만인 경우 72시간 내에 출금이 가능하다는 것을 강조하고 싶습니다. 단일 출금 요청으로 50이더리움 이상일 경우 또는 일주일에 100이더리움 이상일 경우,
									최대 4주가 소요될 수 있습니다. 보통은 1주일 내에 출금 준비가 되어야 하지만, 솔직히 말해서 2월 첫 2주 같은 경우에는 갑작스러운 출금 요청으로 인한 월별 수익률에 부정적인 영향이 있었습니다.
									따라서 출금 정책 변경을 통해 WREX의 모든 회원님의 손해를 최소화하고 수익을 극대화하려고 하는 방향입니다.
									<br />
									<br />출금 요청 시 1일 - 7일 사이 업데이트되는 출금 요청 정보를 받아 고객 지원팀에서 이메일을 통해 연락을 드릴 것입니다. 신뢰는 회원님과 WREX 사이의 가장 중요한 가치입니다.
									<br />
									<br />감사합니다.<br />
									<br />
									WREX 운영팀
									<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr9' name='radio' type='checkbox' />
					<label htmlFor='nr9'>
						<span className="th_faq">[공지사항] 2020.02.11</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										안녕하십니까? WREX 회원님 <br />
									</p>
									회계감사 담당자의 개인 사정상 감사 보고서 및 월 수익률 산정이 지연되고 있습니다. 월 수익률은 내일쯤 따로 공지 예정이며 보고서는 몇일 지연되어 업로드 될 예정입니다.
									다시 한번 불편을 끼쳐드려 죄송합니다.

									<br />
									W-REX 운영팀<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr8' name='radio' type='checkbox' />
					<label htmlFor='nr8'>
						<span className="th_faq">[공지사항] 2020.01.15</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul><li>
								<p className="crypto-word-warp">
									2019년을 뒤로하고 2020년 향한 첫 발걸음을 저희 W-REX와 함께!<br /><br />
									먼저 저희 W-REX 팀원들을 대신하여 모든 회원님들께 새해 인사 올립니다. 지난 2019년도는 저희에게 크나큰 도약의 해였습니다. 9월 1일에 프리런칭을 성공적으로 시행했으며, 오늘까지 오면서 작고 큰 고난과 어려움이 있었으나 회사의 발전과 회원님들의 성공을 생각하며 힘차게 매진하여 왔습니다.  <br />
									2020년은 정말 저희 W-REX와 회원 여러분들께는 잊을 수 없는 한 해로 만들도록 하겠습니다. 일단, 2월 첫째주부터 홍콩의 신탁펀드로부터 자금을 위탁 받아 운영할 예정이며, 앞으로도 많은 투자회사들과 마주 앉아 자금 운영에 대해 적극적으로 영업을 할 예정입니다. 저희는 앞으로 투입될 풍요한 자금력을 바탕으로 한 회사 계획이 회원님들께도 이득이 돌아갈 수 있도록 노력할 것입니다. <br />
								</p>
								<ol>
									<li>
										W-REX 투자 수익률, <br />
										<table className="simpeltable">
											<tbody>
												<tr>
													<td scope="Ranking">월</td>
													<td scope="Prize">월 수익률 % </td>
												</tr>
												<tr>
													<td data-label="Ranking">9월</td>
													<td data-label="Prize">16.52%</td>
												</tr>
												<tr>
													<td data-label="Ranking">10월</td>
													<td data-label="Prize">11.72%</td>
												</tr>
												<tr>
													<td data-label="Ranking">11월</td>
													<td data-label="Prize">10.07%</td>
												</tr>
												<tr>
													<td data-label="Ranking">12월</td>
													<td data-label="Prize">6.80%</td>
												</tr>
											</tbody>
										</table>
										<p className="crypto-word-warp">
											위 수익률에 의하면, 회원님께서 [WREX 계정]에 1 이더리움을 9월 1일부로 투자하셨다면, 현재 약 1.182252 이더리움으로 증액되어 18%의 최종 수익을 얻으셨고;<br />
											[100 이더리움 플랜]에 1 이더리움을 9월 1일부로 투자하셨다면, 현재 약 1.384929 이더리움으로 증액되어 38%의 수익을 얻으셨습니다.<br />
										</p>
									</li>
									<li>
										편리한 모바일 앱 출시<br />
										구글 플레이 스토어(안드로이드)나 앱 스토어(애플 아이폰)에 가셔서 WREX로 검색하시면 모바일 앱을 다운로드 받으실 수 있습니다.<br />
									</li>
									<li>
										WOTA 코인 출시 예정!<br />
										저희 WOTA/WREX는 올해에 75만개의 이더리움을 운영할 예정이며, 막대한 자금력과 수익률을 기반으로 WOTA 코인을 출시하고, 바로 세계 3대 거래소에 상장할 예정입니다.<br />
										저희 WREX 회원님들께만, 세계 3대 거래소 상장이 확정되고 상장 직전에 프리세일 기회를 드릴 예정입니다. 이에 앞서 WREX에 WOTA 코인 지갑 서비스를 시작할 예정이며, 동시에 현재 이더리움으로만 제공하던 저희 투자 상품을 WOTA 코인으로도 확대하고, 신규 플랜 (예. 369 플랜 등)을 통해 보다 파격적인 상품들을 기존 WREX 회원님들에게만 제고할 예정입니다. <br />
										동 프로모션에 대해서는 앞으로 공지를 통해 추가 안내하도록 하겠습니다.<br />
									</li>
								</ol>
								감사합니다. <br />
								<br />
								WOTA/WREX 운영진<br />
							</li></ul>
						</div>
					</label>

					<input id='nr7' name='radio' type='checkbox' />
					<label htmlFor='nr7'>
						<span className="th_faq">오프닝 이벤트 당첨자, 2020-01-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>

								<div className="row result-wrap">
									<div className="col-full">
										<div className="result">
											<p className="crypto-word-warp">[이벤트 하나!] 추천 수 상위 100명에게 드리는 특별 이벤트 총 420개 이더리움 에어드랍!<br /><span>* 실제 당첨 상품 지급일은 24일로 예정되었으나, 내부 시스템 사정으로 인하여 2020년 1월 10일 WREX 계정으로 일괄 지급됨을 양해 말씀드립니다.</span></p>

											<table className="simpeltable">
												<thead>
													<tr>
														<th scope="col">순위 </th>
														<th scope="col">상품 </th>
														<th scope="col">당첨자 (추천수) </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td data-label="순위 ">TOP 1</td>
														<td data-label="상품 ">100이더리움</td>
														<td data-label="당첨자 (추천수) ">mil***************@y****.com (51)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 2</td>
														<td data-label="상품 ">50이더리움</td>
														<td data-label="당첨자 (추천수) ">pau*******@g****.com (48)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 3</td>
														<td data-label="상품 ">30이더리움</td>
														<td data-label="당첨자 (추천수) ">chs***@g****.com (46)</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 4~5</td>
														<td data-label="상품 ">각각 20이더리움</td>
														<td data-label="당첨자 (추천수) ">
															biz********@n****.com (42)<br />
															yse****@g****.com (42)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 6~10</td>
														<td data-label="상품 ">각각 10이더리움</td>
														<td data-label="당첨자 (추천수) ">
															sea*******@s*******.ca (40)<br />
															jju****@h******.net (39)<br />
															mif*****@y****.com (37)<br />
															sal*******@g****.com (37)<br />
															sam*****@h******.com (37)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 11~25</td>
														<td data-label="상품 ">각각 5이더리움</td>
														<td data-label="당첨자 (추천수) ">
															coo*******@g****.com	(36)<br />
															mid*********@y****.com	(30)<br />
															ste******@a**.com	(28)<br />
															seb***********@h******.com	(26)<br />
															pat*********@y****.com	(26)<br />
															nes*******@y****.com	(25)<br />
															pat**********@y****.com	(25)<br />
															rjh*******@y****.ca	(24)<br />
															rya*****@l****.com	(24)<br />
															s_h****@h******.com	(24)<br />
															uni******@g****.com	(23)<br />
															ost*****@y****.com	(22)<br />
															pic*********@g****.com	(22)<br />
															des*********@g****.com	(22)<br />
															sar*@h******.com	(21)
								</td>
													</tr>
													<tr>
														<td data-label="순위 ">TOP 26~100</td>
														<td data-label="상품 ">각각 1이더리움</td>
														<td data-label="당첨자 (추천수) ">
															myf*****@h******.com	(19)<br />
															raz*@a**********.com	(18)<br />
															mic********@y****.com	(18)<br />
															chu*********@n****.com	(17)<br />
															por*******@y****.com	(17)<br />
															raq*@y****.com	(15)<br />
															ozc*****@n****.com	(15)<br />
															bli*********@y****.com	(14)<br />
															pau*****@h******.com	(14)<br />
															sdw*****@h******.com	(14)<br />
															air******@h******.net	(13)<br />
															ea-****@d***.net	(13)<br />
															dms*****@n****.com	(13)<br />
															mig************@y****.com	(13)<br />
															ren**********@g****.com	(12)<br />
															jrk**@m**.com	(12)<br />
															jes*********@g****.com	(11)<br />
															sno******@g****.com	(11)<br />
															Lil*********@g****.com	(10)<br />
															jhl**@g****.com	(10)<br />
															jom*****@d***.net 	(9)<br />
															kws****@n****.com	(8)<br />
															ssd******@g****.com	(7)<br />
															pro**************@g****.com	(7)<br />
															han*********@h******.net	(7)<br />
															sha********@g****.com	(7)<br />
															kig*******@n****.com	(6)<br />
															jin*****@n****.com	(6)<br />
															mia******@g****.com	(6)<br />
															mil*******@a**.com	(6)<br />
															sen***@y****.com	(6)<br />
															esp*******@g****.com	(6)<br />
															mot*******@g****.com	(6)<br />
															rit**********@h******.com	(6)<br />
															sal***********@y****.com	(5)<br />
															lsi**@n***.com	(5)<br />
															hk6***@n***.com	(5)<br />
															dec****@n****.com	(5)<br />
															pen*******@g****.com	(5)<br />
															mie*******@y****.com	(5)<br />
															rba*******@p******.net	(5)<br />
															sai*********@c******.net	(5)<br />
															pel******@g****.com	(5)<br />
															bor*******@n****.com	(5)<br />
															suu****@n***.com	(4)<br />
															hap*******@n***.com	(4)<br />
															ste******@a**.com	(4)<br />
															seb****@m**.com	(4)<br />
															ray********@m**.com	(4)<br />
															sam********@g****.com	(4)<br />
															win****@n****.com	(4)<br />
															kim****@g****.com	(4)<br />
															yom*******@g****.com	(4)<br />
															spa******@k****.ac.kr	(4)<br />
															sha********@g****.com	(4)<br />
															jam************@y****.com	(4)<br />
															mil************@m**.com	(3)<br />
															dba******@d***.net 	(3)<br />
															tnz*******@d***.net	(3)<br />
															osc**********@h******.com	(3)<br />
															sfl*****@y****.com	(3)<br />
															mik********@h******.com	(3)<br />
															zin***********@g****.com	(3)<br />
															ton********@n****.com	(3)<br />
															mid*****@g****.com	(3)<br />
															jho*****@h******.net	(3)<br />
															sal******@g****.com	(3)<br />
															sc5***@h******.net	(3)<br />
															snu***@r*****.com	(3)<br />
															nel*****@s********.net	(3)<br />
															reg*********@a**.com	(3)<br />
															nam******@h******.net	(3)<br />
															seo*****@n****.com	(3)<br />
															bro*********@n****.com	(3)<br />
															h-p*********@h******.net	(3)
								</td>
													</tr>
												</tbody>
											</table>
											<p className="lead animate-this">[이벤트 둘!] WREX 계정에 위탁된 이더리움 수량 기준 상위 3개국의 최상위 3분께 드리는 특별 이벤트<br /><span>* 이벤트 일정 및 주요 내용은 초대 받는 분들께 이메일로 개별 전송해드립니다.</span></p>
										</div>
									</div>
								</div>

							</ul>
						</div>
					</label>

					<input id='nr6' name='radio' type='checkbox' />
					<label htmlFor='nr6'>
						<span className="th_faq">WREX - 모바일 앱 출시, 2020-01-02</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께, <br />
									</p>
									모바일로도 편리하게 이용할 수 있는 WREX 앱이 출시되었습니다.
									아래 버튼을 클릭하셔서 WREX 앱을 다운로드 받아보세요.
<br />
									App Store (IOS)
<br />
									<a href='https://apps.apple.com/us/app/wrex/id1491276520?ls=1'>https://apps.apple.com/us/app/wrex/id1491276520?ls=1</a>
									<br />
									Google Play (안드로이드)
<br />
									<a href='https://play.google.com/store/apps/details?id=com.wrex'>https://play.google.com/store/apps/details?id=com.wrex</a>
									<br />
									현재 버전의 WREX 앱은 영어로만 서비스가 되며, 빠른 시일 내에 한국어와 인도네시아어가 추가 될 예정입니다.
<br />
									감사합니다.
<br />
									WOTA/WREX 운영팀
<br />
								</li>
							</ul>
						</div>
					</label>

					<input id='nr5' name='radio' type='checkbox' />
					<label htmlFor='nr5'>
						<span className="th_faq">12월 중대 발표, 2019년 12월 24일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									지난 주 금요일 WOTA/WREX는 홍콩의 한 신탁펀드회사와의 [펀드 위탁에 관한 계약서]를 체결하였습니다. 이 계약서의 주요 내용은;
<ul>
										<li>신탁펀드사는 WOTA/WREX 사업에 최대 미화 1억불 (한화 1,200억원)의 내부 자금 운용을 위탁한다 (총 740,000 이더리움)</li>
										<li>신탁펀드사는 2020년 2월부터 자금을 투입한다</li>
										<li>운용 펀드는 본계약에 따라 24개월간 운용하며 상호 필요시 연장할 수 있다</li>
									</ul>
									<br />
									<br />
									홍콩의 신탁펀드사와 계약을 통해 WOTA/WREX는 한단계 도약할 수 있는 발판을 마련했습니다. 이는 드디어 개인 투자가가 아닌 한 기관의 투자회사가 여러 차례 방문과 감사를 통해 저희 WOTA 플랫폼의 장점을 인정하고, WREX 알고리즘 트레이딩의 실체를 파악한 후, 수익성이 충분하게 보장된 다는 점을 높이 평가하여 투자를 결정했다는 점에서 저희 역시 열광하지 않을 수 없는 상황입니다.
<br />
									<br />
									이와 관련, 초기 WREX 플랫폼 기획 단계에서 내부에서 정한, [최대 1백만개의 이더리움]을 선착순으로 위탁 운영한다는 내부 방침에 따라, 본 계약을 통해 이 시점이 한층 앞당겨질 수 있음을 동시에 공지할 수 밖에 없는 상황에 대해 회원님들의 양해를 미리 구합니다. 저희 WREX는 위탁 운용하는 이더리움 수량이 1백만개가 되는 시점부터 부득이하게 신규 회원을 더 이상 받지 않을 것을 공지합니다. 이는 저희가 초기에 약속한 매달 평균 두 자리 수 수익률을 유지하기 위해서는 너무 많은 수량의 이더리움을 운용하기 보다는 적정한 수량과 매달 평균 수익률을 유지하기 위해서 내부에서 사전에 정한 수량 (1백만 이더리움)을 달성하면 부득이하게 신규 회원을 제한하여 기존 회원들의 수익율을 보장하기 위함임을 양해해 주시기 바랍니다.
<br />
									<br />
									감사합니다.
<br />
									<br />
									WOTA/WREX 운영팀
<br />
								</li>
							</ul>
						</div>
					</label>


					<input id='nr4' name='radio' type='checkbox' />
					<label htmlFor='nr4'>
						<span className="th_faq">12월 공지사항, 2019년 12월 5일</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										Dear WREX Members,<br />
									</p>
									<ol>
										<li>
											11월 월수익율 <br />
											Tilly Baker사의 회계감사가 완료되었고 11월 월수익률은 10.07%로 집계되었으며, 12월 10일에 11월 월수익률 분이 적용될 예정입니다. 다만, 이번 회계감사 보고서는 회계법인의 휴가 계획으로 통상 8일이 아닌 17일에 홈페이지게 개제될 것 입니다. 이점 양해를 바랍니다.
<br />
											<br />
										</li>
										<li>
											오프닝 이벤트 이더리움 에어드랍<br />
											오프닝 이벤트에 참여해 주신 회원님들에게 감사드리며, 2주 정도 후에 공식으로 오프닝 이벤트에 대한 결과를 공지하도록 하겠습니다.
<br />
											<br />
										</li>
										<li>
											이스탄불 하드포크로 인한 입금/출금 지연 안내<br />
											이더리움 재단은 12월 7일경 이스탄불 하드포크를 진행할 예정으로 공시하였으며, 저희 내부 이더리움 지갑 역시 그 시점에 맞추어 업그레이드를 진행할 예정입니다. 고로, 12월 6일부터 9일 사이에 입금 또는 출금 요청을 하실 경우에 통상적인 네트워크 보다 전송시간이 지연될 것으로 예상되며, 이는 일시적인 현상인 것을 미리 사전 공지 드립니다.
<br />
											<br />
										</li>
										<li>
											369 플랜 상품<br />
											저희 W-REX는 회원님들의 중단기 상품이 필요하다는 지적에 2020년 1사분기 이내에 새로운 상품인 369 플랜 상품을 출시할 예정입니다.
<br />
											369 플랜 상품은 100 이더리움 상품과 유사한 방법으로 구상되었으며, 다만 1계정당 1개의 상품만을 생성하실 수 있습니다. 입금하실 이더리움 수량은 제한이 없으나, 만기일을 3개월, 6개월 또는 9개월 중에 선택하실 수 있습니다.
<br />
											100 이더리움 플랜처럼 회사의 수익분을 회원님의 369플랜과 함께 투자되며, 100 이더리움 플랜의 경우 해지 또는 만기 시 5대 5로 배분하던 것을, 369플랜에서는 만기 시 7대 3으로 배분하여 회원님들의 수익을 극대화 하였습니다.
<br />
											100 이더리움 플랜의 경우 만기까지 40개월이 걸려 다소 지루하실 수 있는 점을 최단 3개월 그리고 최장 9개월 플랜을 통해 중단기적 투자 계획도 세워보실 수 있도록 만들었습니다. 자세한 사항은 상품의 최종 개발 및 출시에 맞추어 다시 공지하도록 하겠습니다.
<br />
											<br />
											감사합니다.
<br />
											W-REX 운영팀
<br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr3' name='radio' type='checkbox' />
					<label htmlFor='nr3'>
						<span className="th_faq">2019년 11월 WREX 소식, 2019-11-08</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									<ol>
										<li>
											<br />
											PWC 회계법인이 WREX 계정에 대한 감사를 진행하기 하루 전날인 2019년 9월 30일에 저희에게 공식 이메일을 보내와 저희 계정에 대한 감사를 진행하지 못함을 알린 봐 있습니다. 이유는 PWC 인터네셔널 (본사)에서 암호화화폐 관련 회사에 대한 감사는 마치 PWC가 암호화폐 산업에 대해 지지의사를 밝히는 인상을 줄 수 있기 때문에 암호화폐에 대한 법과 규정이 아직 확립이 안된 국가에서의 관련 산업에 대한 회계 감사를 자제해 달라는 지시를 받았기 때문이라고 밝혔습니다. <br />
											저희는 당시 PWC와 계약을 완료하여 대안책이 없는 상태에서 큰 실망감을 감출 수 없었습니다. 하지만 그 이후 바로 PWC를 대체하여 기존 계약 업무를 진행할 수 있는 다수의 회계법인과의 협의를 진행했고, 다행히 세계 10대 회계법인 중 하나인 Tilly Baker 회계감사법인과 계약을 진행했습니다. <br />
											Tilly Baker 회계감사법인은 이미 저희 9월 실적 및 회계 감사를 진행했으며, [지원] 페이지에서 영문 원본 감사보고서를 보실 수 있습니다. 어제까지 10월분에 대한 감사 역시 진행했으며, 10월 보고서는 다음 주 초에 홈페이지에 추가 업로드하도록 하겠습니다.<br />
											9월 보고서는 9월에 진행된 저희 ETH 계정의 모든 활동을 감사하며, 9월의 모든 거래내역을 전수 검사하여 입금된 ETH 수량, 9월 수익 ETH 수량을 산출하여 9월 수익률인 16.52%를 확인하는 감사 보고서입니다. <br />
											<br />[지원] 페이지에서 9월 감사 보고서를 다운로드 하실 수 있습니다.<br />
											<br />
										</li>
										<li>
											10월 거래 실적 관련<br />
											현재 Tilly Baker에서 10월 실적에 대한 감사를 마쳤으며, 보고서가 나오기 전이지만 확인된 바로는 10월 실적은 11.72% 입니다.<br />
											10월 감사 보고서는 10일 경에 홈페이지에 개제될 예정입니다.<br />
											<br />
											그리고 10일 경에 회원님의 계정에 10월 실적이 반영될 예정입니다. <br />
											<br />
											WREX 9월 실적: 16.52%<br />
											WREX 10월 실적: 11.72%<br />
											WREX 평균 실적: 14.12%<br />
											<br />
										</li>
										<li>
											추천인 보너스 실적 샘플<br />
											저희 WREX 회원 중 가장 많은 추천인 보너스를 수령한 탑5 계정 중 한 회원님께서 허락해 주셔서 그분의 수익률을 공개해 드립니다. 9월의 월 수익률 16.52%를 기록했을 때, 그리고 대부분의 회원들이 최대 16.52%의 수익을 받았을 때, 이 회원의 경우, <br />
											WREX 계정 – 593.60%<br />
											100 이더리움 플랜 – 88.36%<br />
											의 수익률을 기록했습니다.<br />
											어떻게 저러한 수익을 얻을 수 있는지 궁금하시면, [지원] 페이지에서 이분의 샘플 페이지를 참고해 주시기 바랍니다.<br />
											<br />다운로드: <a href="/download/AffiliateBonusSample(KOR).pdf" target="_blank">AffiliateBonusSample(KOR).pdf</a><br />
											<br />감사합니다.<br />
											WREX 지원팀 <br />
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr2' name='radio' type='checkbox' />
					<label htmlFor='nr2'>
						<span className="th_faq">WREX Announces First Payouts, 2019-10-10</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										WREX 회원 여러분께,<br />
									</p>
									<ol>
										<li>
											첫 달 수익률 배분이 이루어졌습니다! <br />
											앞으로도 매월 10일에 수익률이 반영됩니다. 오늘이 첫 수익의 배분이 이루어진 날로 궁금한 것들이 많으실 겁니다.<br />
											먼저 말씀 올리고자 하는 것은, 모든 계산은 프로그래밍에 의해 자동 정산되며, 이 의미는 “한 계정의 계산이 맞는다면 모든 계산이 맞을 것이고, 한 계정의 계산이 틀렸다면 모든 계정의 연산이 잘못 되었다”는 것입니다.<br />
											첫 달이 계산이 가장 복잡한 이유는 회원님께서 9월 어느 시점에 WREX 상품과 100 이더리움 플랜 상품에 가입하셨냐에 따라 월수익률의 배분 비율이 조금씩 상이하기 때문입니다. (<font className="emphsize">첫 달만 그렇고, 다음 달 부터는 정상적인 배분 비율이 적용됩니다</font>)  <br />
											<br />
											<font className="boldfont">만약 회원님께서 WREX 상품을;</font><br />
											9월1일부터 10일 사이에 가입하셨다면, 9월 수익률의 50%가 반영됩니다.<br />
											9월 11일부터 20일 사이에 가입하셨다면, 9월 수익률의 25%가 반영됩니다.<br />
											9월 21일부터 30일 사이에 가입하셨다면, 9월 수익률의 0%가 반영됩니다.<br />
											<br />
											<font className="boldfont">만약 회원님께서 100 이더리움 플랜에;</font><br />
											9월 1일부터 30일 사이에 가입하셨다면, 9월 수익률에 대해서는 받으실 수 없습니다. <br />
											왜냐하면, 모든 100 이더리움 플랜은 가입시점과 관계없이 다음 달 1일부터 계약 효력을 발생하기 때문입니다. <br />
											<br />
											만약 회원님께서 WREX 상품과 100 이더리움 플랜을 8월에 가입 완료하셨다면, 9월 월 수익률인 16.52%가 100% 반영될 것입니다. <br />
											9월에 위 상품에 가입하신 회원분들은 가입시점에 따른 월수익률 배분 비율을 적용하시면 됩니다. 10월부터는 배분 비율 없이 월수익률 그대로 적용됩니다. <br />
										</li>
										<li>
											추천인 제도<br />
											<font className="boldfont">WREX 상품 추천인 제도</font><br />
											(1)	회원님께서 최소 1 이더리움을 월 1일부터 마지막날까지 유지해야만 추천 제도 혜택을 받으실 수 있습니다.<br />
											(2)	회원님께서 추천하신 회원분들 역시 최소 1 이더리움을 월 1일부터 마지막날까지 유지해야만 회원님께서 추천 보너스를 받으실 수 있습니다.<br />
											<font className="boldfont">100 이더리움 플랜 추천인 제도</font><br />
											(1)	회원님께서 최소 한 개의 100 이더리움 플랜을 1일부터 마지막 달까지 유지해야 추천인 보너스를 수령할 수 있습니다.<br />
											(2)	회원님께서 추천한 회원들 역시 100 이더리움 플랜을 1일부터 마지막 달까지 유지해야 회원님께서 추천인 보너스를 수령할 수 있습니다.<br />
											(3)	유의: 모든 100 이더리움 플랜의 추천인 보너스는 회원님의 가장 오래된 100 이더리움 플랜에 입금됩니다.<br />
											<br />
											<font className="boldfont">저희 홈페이지 시작페이지 중단 부분에 국문 가이드북이 있으니 참조해 주시기 바랍니다.</font>
										</li>
										<li>
											회계법인의 첫 달 운영계정 감사 리포트<br />
											현재 운영과 상관없는 내부의 문제로 인해 첫 달 운영 계정 감사 리포트가 지연되고 있는 점은 깊이 사과 드립니다. 하지만 이 것은 운영과 상관없는 문제임을 확인 드리며, 일단 내부에서 몇 번을 거듭해서 산출한 월수익률로 변동이 없을 것을 약속 드립니다. 또한, 최대한 빠른 시일내에 보고서 공개를 통해 투명하게 운영될 것임을 첨언합니다. <br />
											보고서 지연 말고는 모든 계정 운영이 정상적으로 진행되고 있으며, 참고로 말씀드리자면, 10월 실적 역시 순조롭게 진행되고 있으니 불필요한 확대해석 내지는 걱정하지 않으시 길 부탁드립니다.<br />
											<br />
											항상 여러분들의 응원에 감사드리며 궁금하신 사항이 있으시면, <a href="mailto:support@w-rex.com">support@w-rex.com</a> 으로 남겨 주시기 바랍니다.<br />
											<br />
											<br />
											WREX 운영팀 올림<br />

										</li>
									</ol>
								</li>
							</ul>
						</div>
					</label>

					<input id='nr1' name='radio' type='checkbox' />
					<label htmlFor='nr1'>
						<span className="th_faq">Monthly Profit Notice, 2019-10-01</span>
						<div className='lil_arrow'></div>
						<div className='bar'></div>
						<div className='swanky_wrapper__content'>
							<ul>
								<li>
									<p className="crypto-word-warp">
										회원님들께<br />
										9월 비공식 수익률이 집계되어 공개해드립니다. 9월실적: 16.52%
										저희를 믿고 저희 서비스를 처음부터 이용해주신 여러분들은 곧 본인 계정에 "믿기 힘든, 하지만 WREX 기준으로는 평균보다 조금 높은" 수익률이 반영될 예정입니다.
<br />
										수익률에 대한 질문이나 또한 여러가지 의문사항 있으시면, 언제든지 <a href="mailto:support@w-rex.com">support@w-rex.com</a>으로 연락주시면 답변 드리도록 하겠습니다.<br />
										감사합니다.
			<br /><br />
									</p>
								</li>
							</ul>
						</div>
					</label>

				</div>

			</div>

		)
	}
}

export default NewsKR;

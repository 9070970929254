import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import {LOUNGE_URL} from '../constants/ConfigValue.js';

import { sendUserLogout, sendUserInfo, sendUserWithdrawAddresses, API_SUCCESS } from '../actions'
import { sendAccountInfo, sendAccountMyEthHistory, sendAccountWrexMonthlyPerformance, sendAccountWrexInvestHistory, sendAccount100EPList, sendUserAffiliateStatus} from '../actions'

import {isLogin, getToken} from '../helper/loginHelper';

import Cookies from 'universal-cookie';

import i18n from 'i18n-react';


import { withStyles } from "@material-ui/core/styles";

//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
//import { catchClause } from '@babel/types';

const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  root: {
    backgroundColor: "transparrent",
  },

  paper: {
  //  backgroundColor: "#111",
    boxShadow: "none",
    overflow: "hidden"
  },
};

class SideNav extends Component {
  //constructor(props) {
    //super(props);
    //this._isMounted = false;

  //}

  state = {
    errorMessage: "",
    dialogOpen: false,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }



  handleClickOpen() {
    //setOpen(true);
    this.setState({dialogOpen: true})
  }

  handleClose() {
    //setOpen(false);
    this.setState({dialogOpen: false})
  }

  componentDidMount() {
    //this._isMounted = true;

    //console.log("ych SideNav");

    //console.log(this.props.globalUser);
    //console.log(this.props.globalAccount);

    isLogin(this);
    let token = getToken(this);

    if (token!==null) {
      var self = this;
      if ((this.props.globalUser===undefined)||(this.props.globalUser.user===undefined)) {
        this.props.sendUserInfo(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.user.user;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
              if (response.message==="email_not_confirmed") {
                self.props.history.push("/register-verify")
              } else if (response.message==="login_required") {
                self.props.history.push("/login")
                cookies.set('token',"logout", {path: '/'});
                cookies.set('expire',"logout", {path: '/'});
                if ((self.props.globalLogin!==undefined)&&(self.props.globalLogin.login!==undefined)) {
                  self.props.globalLogin.login.success=false;
                  self.props.globalLogin.login=undefined;
                }
                if ((self.props.globalUser!==undefined)&&(self.props.globalUser.user!==undefined)) {
                  self.props.globalUser.user=undefined;
                }
                if ((self.props.globalAccount!==undefined)&&(self.props.globalAccount.account!==undefined)) {
                  self.props.globalAccount.account=undefined;
                }

              } else {
                self.props.history.push("/")
              }
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
            self.props.history.push("/")
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendUserWithdrawAddresses(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.user.user;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendUserAffiliateStatus(token,{detail:true})
        .then (function (success) {
          //console.log("success affliiateStatus");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.user.user;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })
/*
        this.props.sendUserAffiliateCodes(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.user.user;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })
*/

      }

      if ((this.props.globalAccount===undefined)||(this.props.globalAccount.account===undefined)) {

        this.props.sendAccountInfo(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendAccountMyEthHistory(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendAccountWrexMonthlyPerformance(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendAccountWrexInvestHistory(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

        this.props.sendAccount100EPList(token,{})
        .then (function (success) {
          //console.log("success");
          //console.log(success);
          if (success.type === API_SUCCESS) {
            let response = success.response.entities.account.account;
            if (response.success===false) {
              self.setState({errorMessage: i18n.translate(response.message)})
            } else {
              // get
            }
          } else {
            self.setState({errorMessage: i18n.translate('unknown_error')})
          }
 
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
          self.setState({errorMessage: i18n.translate('unknown_error')})
        })

      }

    }

  }

  componentWillUnmount() {
    //this._isMounted = false;
  }

  handleLogout() {
    //console.log("handleLogout");
    this.setState({dialogOpen: true})

    let token = getToken(this);
    //console.log(token);
    cookies.set('token',"logout", {path: '/'});
    cookies.set('expire',"logout", {path: '/'});
    if ((this.props.globalLogin!==undefined)&&(this.props.globalLogin.login!==undefined)) {
      this.props.globalLogin.login.success=false;
      this.props.globalLogin.login=undefined;
    }
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {
      this.props.globalUser.user=undefined;
    }
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      this.props.globalAccount.account=undefined;
    }

    if (token!=null) {
      var self = this;
      this.props.sendUserLogout(token,{})
      .then (function (success) {
        //console.log("success");
        //console.log(success);
  
        self.props.history.push("/")
      })
      .catch(function (error) {
        console.log("error");
        console.log(error);
  
        self.props.history.push("/")
      })
    } else {
  
      this.props.history.push("/")
    }
  }   

  openWlounge() {
//console.log(LOUNGE_URL);
        const myToken = getToken();
        if (myToken!==null) {
              const cookieExpire = cookies.get('expire');
              window.open(LOUNGE_URL+"?token="+myToken+"&expire="+cookieExpire,'_blank');
        }

  }

  render() {

    const { classes } = this.props;


    const cookieLanguageSetting = cookies.get('language_setting');
    let isShowLounge = false;
    if (cookieLanguageSetting!==undefined) {
      if (cookieLanguageSetting==="KR") {
        isShowLounge = true;
      }
    }

//console.log(cookieLanguageSetting);

    var activeTag = this.props.ActiveTag;
    //console.log(activeTag);

    return (
      <div className="crypto-sidenav">

        <nav className="crypto-navigation">
          <Link to="index_login" className="crypto-navigation__logo">
            <img src="assets/images/logo_wrex.svg" alt="WREX" />
          </Link>
          <ul className="crypto-navigation__list">

            <li className={(activeTag==='myeth_wallet')? 'crypto-navigation__list-item mdc-ripple-surface crypto-navigation__list-item--active':'crypto-navigation__list-item mdc-ripple-surface'}>
            <Link to="myeth_wallet">
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">account_balance_wallet</i>          
              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline">
              {i18n.translate('wallet')} 
              </span>
            </Link>
            </li>

            <li className={(activeTag==='invest')? 'crypto-navigation__list-item mdc-ripple-surface crypto-navigation__list-item--active':'crypto-navigation__list-item mdc-ripple-surface'}>
            <Link to="my_assets">
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">timeline</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline">
              {i18n.translate('assets')} 
              </span>
            </Link>
            </li>

            <li className={(activeTag==='referral')? 'crypto-navigation__list-item mdc-ripple-surface crypto-navigation__list-item--active':'crypto-navigation__list-item mdc-ripple-surface'}>
            <Link to="affiliate_program">
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">device_hub</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline crypto-word-warp">
              {i18n.translate('affiliate')} 
              </span>
            </Link>
            </li>

{ isShowLounge===true &&
            <li style={{cursor: 'pointer'}} className="crypto-navigation__list-item mdc-ripple-surface ">
            <span onClick={()=>this.openWlounge()}>
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">blur_on</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline crypto-word-warp">
              {i18n.translate('wlounge')} 
              </span>
</span>
            </li>
}

            <li className={(activeTag==='support')? 'crypto-navigation__list-item mdc-ripple-surface crypto-navigation__list-item--active':'crypto-navigation__list-item mdc-ripple-surface'}>
            <Link to="support">
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">info</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline">
              {i18n.translate('support')} 
              </span>
            </Link>
            </li>

            <li className={(activeTag==='profile')? 'crypto-navigation__list-item mdc-ripple-surface crypto-navigation__list-item--active':'crypto-navigation__list-item mdc-ripple-surface'}>
            <Link to="profile">
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">person</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline">
              {i18n.translate('account')} 
              </span>
            </Link>
            </li>

            <li className="crypto-navigation__list-item mdc-ripple-surface logout-icon" >
            <a 
                onClick={() => this.handleClickOpen()} 
            >
              <i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">power_settings_new</i>

              <span className="crypto-navigation__list-item__label mdc-theme--on-surface mdc-typography--overline"
              >
              {i18n.translate('logout')} 
              </span>
            </a>
            </li>

          </ul>
        </nav>   

      <Dialog
        open={this.state.dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose.bind(this)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
           root: classes.root
          }
         }
        }
        PaperProps ={{
          classes: {
           root: classes.paper
          }
        }}
      >
                                <div className="dialog__box">
                                  <div className="crypro-error-container__box-inner">
                                        <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary logo">
                                                <img src="assets/images/logo_wrex.svg" alt="WREX" />
                                        </h2>
                                        <h2 className="crypto-widget__heading mdc-typography--subtitle1 mdc-theme--primary">
                                          <span className="crypto-widget__heading-text">{""}</span>
                                          <span className="crypto-widget__heading-border"></span>
                                        </h2>
                                        <p className="crypto-word-warp">{i18n.translate("confirmedLogoutDesc")}</p>
                                        <br/>
                                        <form className="crypto-login-form">
                                          <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button" onClick={this.handleClose.bind(this)} >{i18n.translate('cancel')}</span>
                                          <div className="space_25"></div>
                                          <span className="f_signin mdc-button mdc-button--unelevated big-round-corner-button" onClick={() => this.handleLogout()} >{i18n.translate('logout')}</span>
                                        </form>
                                  </div>
                                </div>

      </Dialog>


      </div>   
    );
  }
}

//export default SideNav

const mapStateToProps = (state, ownProps) => {
  return {
    globalLogin: state.entities.login,
    globalUser: state.entities.user,
    globalAccount: state.entities.account,

  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, {
  sendUserLogout, sendUserInfo, sendUserWithdrawAddresses, 
  sendAccountInfo, sendAccountMyEthHistory, sendAccountWrexMonthlyPerformance, sendAccountWrexInvestHistory, sendAccount100EPList, sendUserAffiliateStatus
})(SideNav)))



/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import i18n from 'i18n-react';
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

//import { getETHDisplayString, getAdd3ETHDisplayString, getYearMonthString }  from '../helper/displayHelper'
//import { getETHDisplayString, getAdd3ETHDisplayString }  from '../helper/displayHelper'
import { getETHDisplayString } from '../helper/displayHelper'

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Invest extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      detailFlag: false
    };

    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.getDetailStyleName = this.getDetailStyleName.bind(this);

  }

  componentDidMount() {
    //console.log("ych Invest");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Assets - WREX"

    document.body.className = "wrex mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleDetailClick() {
    this.setState({ detailFlag: !this.state.detailFlag })
  }

  getDetailStyleName() {
    if (this.state.detailFlag === true) {
      return { display: 'block' };
    } else {
      return { display: 'none' };
    }
  }

  /*
    get100ETHLinkString = (inputValue, type, monthsLeft) => {
      if ((type===1) && (monthsLeft>1)) {
        return "100ETH_plan_1?p=1&ref="+inputValue.toString();
      } else {
        return "100ETH_plan_1?p=0&ref="+inputValue.toString();
      }
    }
  */
  get100ETHLinkString = (inputValue, terminateWithPenalty) => {
    if (terminateWithPenalty === true) {
      return "100ETH_plan_1?p=1&ref=" + inputValue.toString();
    } else {
      return "100ETH_plan_1?p=0&ref=" + inputValue.toString();
    }
  }

  get100ETHOldPlanString = (inputValue) => {
    if (inputValue === 0) {
      return i18n.translate('oldep100');
    } else {
      return "";
    }
  }



  render() {

    //console.log(this.props.globalAccount);
    //console.log(this.props.globalUser);
    var userInfo = { limitMax100ep: 0, limitMaxDeposit: 0 };
    if ((this.props.globalUser !== undefined) && (this.props.globalUser.user !== undefined)) {
      let user = this.props.globalUser.user;
      if (user.info !== undefined) {
        userInfo = user.info;
      }
    }
    var accountWrex = {
      lastMonthProfit: "", monthlyChange: "",
      reinvest: false, value: ""
    };
    //var myEth = 0;
    var subscriptions = [];
    var summary = {
      lastMonthGrowth: "",
      totalDeposit: "",
      totalWithdrawal: "",
      netDeposit: "",
      totalProfit: "",
      totalProfitWota: "",
      totalProfitEp100: "",
      totalProfitBonus: "",
      monthsSinceJoined: "",
      totalAssets: ""
    };
    if ((this.props.globalAccount !== undefined) && (this.props.globalAccount.account !== undefined)) {
      let account = this.props.globalAccount.account;
      //console.log(account);
      if (account.wrex !== undefined) {
        accountWrex = account.wrex;
        //accountWrex.value = parseFloat(account.wrex.value);
        accountWrex.value = account.wrex.value;
        accountWrex.lastMonthProfit = parseFloat(account.wrex.lastMonthProfit);
      }
      if (account.summary !== undefined) {
        summary = account.summary;
      }
      //if (account.myeth!==undefined) {
      //myEth = parseFloat(account.myeth.value);
      //}
      if (account.subscriptions !== undefined) {
        subscriptions = account.subscriptions;
        //console.log(subscriptions);
      }
    }

    //console.log(accountWrex);
    const myWrexNode = ((accountWrex.value === "") || (accountWrex.value === 0)) ? (
      <li className="crypto-border-list__item crypto-border-list__item--large crypto-wallet f_seldot">
        <div className="mdc-theme--on-surface fx_grid">
          <h4 className="crypto-wallet__name mdc-typography--overline"> </h4>
          <h2 className="crypto-wallet__value mdc-typography--headline3">
            {i18n.translate('wrex_account')}
            <Link to="/wrex_deposit_create" className="mdc-button mdc-button--outlined f_wrexnew">{i18n.translate('create')}</Link>
          </h2>
          <span className="crypto-coin__border mdc-theme--primary-600-bg"></span>
          <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
        </div>
      </li>
    ) : (
        <li className="crypto-border-list__item crypto-border-list__item--large crypto-wallet ">
          <Link to="wrex_account" className="mdc-theme--on-surface">
            <h4 className="crypto-wallet__name mdc-typography--overline">{i18n.translate('wrex_account')}</h4>
            <h2 className="crypto-wallet__value mdc-typography--headline3">{getETHDisplayString(accountWrex.value)} ETH
            <div className="crypto-wallet__value mdc-typography--overline-sub">({i18n.translate('lastmonth')}: {getETHDisplayString(accountWrex.lastMonthInvest)} ETH)</div>
            </h2>
            <span className="crypto-wallet__statsxx crypto-wallet__stats--up mdc-typography--caption fx_valign">
              {i18n.translate('my_profit')} <br />
              <b>{accountWrex.monthlyChange}</b>
            </span>
          </Link>
        </li>
      )

    // 刪除 <span>Since {getYearMonthString(mySubscription.planBegin)}</span>
    //console.log(subscriptions);
    //<h2 className="crypto-wallet__value mdc-typography--headline3">{getAdd3ETHDisplayString(mySubscription.profit,mySubscription.principal,mySubscription.referralBonus)} ETH <br/><h4 className="crypto-wallet__name mdc-typography--overline">({i18n.translate('totaleth')}:{getETHDisplayString(mySubscription.total)} ETH)</h4></h2>
    const subscriptionNodes = subscriptions.map((mySubscription, index) =>
      <li key={index} className="crypto-border-list__item crypto-border-list__item--large crypto-wallet ">
        <Link to={this.get100ETHLinkString(mySubscription.id, mySubscription.terminateWithPenalty)} className="mdc-theme--on-surface">
          <h4 className="crypto-wallet__name mdc-typography--overline">{i18n.translate('eth100_plan')}<font color="red">{this.get100ETHOldPlanString(mySubscription.type)}</font> <br />"{mySubscription.name}"</h4>
          <h2 className="crypto-wallet__value mdc-typography--headline3">{getETHDisplayString(mySubscription.userTotal)} ETH <br /><h4 className="crypto-wallet__name mdc-typography--overline">({i18n.translate('totaleth')}:{getETHDisplayString(mySubscription.total)} ETH)</h4></h2>
          <span className="crypto-wallet__statsxx crypto-wallet__stats--up mdc-typography--caption fx_valign">
            {i18n.translate('my_profit')} <br />
            <b>{mySubscription.change}</b> <br />({i18n.translate('total')}: {mySubscription.totalChange})
      </span>
          <span className="crypto-coin__border mdc-theme--primary-600-bg"></span>
          <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
        </Link>
      </li>
    )

    //console.log(subscriptions.length);
    //const myNew100ETHPlan = ((subscriptions.length < userInfo.limitMax100ep)&&(myEth>0)) ? (
    const myNew100ETHPlan = (subscriptions.length < userInfo.limitMax100ep) ? (
      <li className="crypto-border-list__item crypto-border-list__item--large crypto-wallet f_seldot">
        <div className="mdc-theme--on-surface fx_grid">
          <h4 className="crypto-wallet__name mdc-typography--overline">new</h4>
          <h2 className="crypto-wallet__value mdc-typography--headline3">
            {i18n.translate('eth100_plan')}
            <Link to="/100ETH_create" className="mdc-button mdc-button--outlined f_wrexnew">{i18n.translate('create')}</Link>
          </h2>
          <span className="crypto-coin__border mdc-theme--primary-600-bg"></span>
          <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
        </div>
      </li>
    ) : (
        <li></li>
      )


    return (
      <div>
        { /* preloader */}
        {this.props.loading && <PreLoaderWidget />}

        <div className="crypto-wrapper">

          <SideNav
            ActiveTag="invest"
          />



          <main className="crypto-main">

            <div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

              <div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
                  <h6 className="f_breadcrumb">
                  </h6>
                  <h1 className="crypto-card__header--title">{i18n.translate('assets')} </h1>
                </div>
                <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
                </div>
              </div>


              <div className="mdc-layout-grid">
                <div className="mdc-layout-grid__inner ">
                  <div className="f_centerbox mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                    <div className="crypto-widget">
                      <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
                        <span className="crypto-word-warp crypto-widget__heading-text">{i18n.translate('availablefunds')}</span>
                      </h2>
                      <p className="fx_support_p crypto-word-warp">{i18n.translate('is7')}</p>

                      <Link to="support" className=""><div>
                        <h2 className="crypto-widget__heading2 crypto-wallet-sub">{i18n.translate('lastmonth_profit')}: {summary.lastMonthGrowth} </h2>
                      </div>
                      </Link>

                      <div style={{ margin: "auto", }}>
                        <button onClick={() => this.handleDetailClick()} className="btn-d"
                          style={{
                            backgroundColor: "#ffce61",
                            color: "#000",
                            padding: "10px 15px",
                            fontSize: "13px",
                            fontWeight: "bold",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            borderRadius: "36px",
                          }}
                        >{i18n.translate('view_detail')}</button></div>

                      <div id="detailDIV" style={this.getDetailStyleName()}>
                        <ul className="li_detail">
                          <li className="major">{i18n.translate('view_detail_1')}{summary.monthsSinceJoined} {i18n.translate('view_detail_9')}</li>
                          <li className="major">{i18n.translate('view_detail_2')}{getETHDisplayString(summary.netDeposit)} {i18n.translate('view_detail_10')}</li>
                          <li className="major">{i18n.translate('view_detail_3')}{getETHDisplayString(summary.totalProfit)} {i18n.translate('view_detail_10')}</li>
                          <li className="major">{i18n.translate('view_detail_4')}</li>
                          <li className="minor">{i18n.translate('view_detail_5')}{getETHDisplayString(summary.totalProfitWota)} {i18n.translate('view_detail_10')}</li>
                          <li className="minor">{i18n.translate('view_detail_6')}{getETHDisplayString(summary.totalProfitEp100)} {i18n.translate('view_detail_10')}</li>
                          <li className="minor">{i18n.translate('view_detail_7')}{getETHDisplayString(summary.totalProfitBonus)} {i18n.translate('view_detail_10')}</li>
                          <li className="major">{i18n.translate('view_detail_8')}{getETHDisplayString(summary.totalAssets)} {i18n.translate('view_detail_10')}</li>
                        </ul>
                      </div>



                      <div className="crypto-widget__content f_invest">
                        <ul className="crypto-border-list">
                          {myWrexNode}
                          {subscriptionNodes}
                          {myNew100ETHPlan}
                        </ul>
                      </div>

                      <div className="f_rg_ptop f_centerbox mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                        <div className="crypto-widget">
                          <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
                            <span className="crypto-widget__heading-text">{i18n.translate('notices')}</span>
                            <span className="crypto-widget__heading-border"></span>
                          </h2>
                          <div className="crypto-widget__content">
                            <ul className="li_notice">
                              <li>{i18n.translate('is1')}</li>
                              <li>{i18n.translate('is2')}</li>
                              <li>{i18n.translate('is3')}</li>
                              <li>{i18n.translate('is4')}{i18n.translate('is41')}{i18n.translate('is42')}</li>
                              <li>{i18n.translate('is5')}</li>
                              <li>{i18n.translate('is6')}</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>



              </div>

            </div>
          </main>

        </div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
  }
}

export default withRouter(connect(mapStateToProps, {
})(Invest))





/* eslint-disable no-undef */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';

import '@material/react-select/dist/select.css'
import '../styles/mdc.css'
//import Button from '@material-ui/core/Button';
//import StyledMenu  from '@material-ui/core/Menu';
//import StyledMenuItem from '@material-ui/core/MenuItem';

import i18n from 'i18n-react';

import {DEFAULT_NETWORK_FEE} from '../constants/ConfigValue.js';
//import { getETHDisplayString, getAddressString2 }  from '../helper/displayHelper'
import { getETHDisplayString }  from '../helper/displayHelper'

import { renderErrorMessage } from '../components/ErrorMessage'

import SideNav from '../components/SideNav';
import PreLoaderWidget from '../components/Loader';
//import { withStyles } from '@material-ui/styles';

const cookies = new Cookies();

/*
const StyledButton = withStyles({
  root: {
    backgroundColor: '#ffce61',
    textTransform: 'none',
  }
})(Button);
*/

class WrexTransfer extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorEl : false,
      addresses : [],
      transferAmount: "",
      transferAddress: "",
      canRequest: false,
      errorMessage: "",
      windowWith: window.innerWidth,
      isMobile: window.innerWidth < 479,
      transferAddressHolder: i18n.translate('transferWrexAccountHolder'),
    }
    
  }

  componentDidMount() {
    //console.log("ych MyEthWithdraw");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Transfer - WREX Account"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

    //console.log(this.props);

    window.addEventListener('resize', this.handleResize)

    const cookieSubmitError = cookies.get('submit_error');
    if ((cookieSubmitError!==undefined)&&(cookieSubmitError!=="")) {
        this.setState({errorMessage: i18n.translate(cookieSubmitError)})

        cookies.set('submit_error', "", {path: '/'});

        const cookieTransferAddress = cookies.get('transfer_address');
        const cookieTransferAmount = cookies.get('transfer_amount');

        this.setState({transferAddress: cookieTransferAddress});
        this.setState({transferAmount: cookieTransferAmount});
        this.setState({transferAddressHolder: ''});
    }

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.handleResize);

  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleResize = (event) => {
    this.setState({ windowWith: window.innerWidth });
    this.setState({isMobile: this.state.windowWith < 479});
  }


  handleWrexTransferRequest() {
    //console.log("handleWrexTransferRequest");
    this.setState({errorMessage: ""})

    if (this.state.canRequest===false) {
      this.setState({errorMessage: i18n.translate('invalid_wrex_transfer_amount')})
      //console.log("can not request");
      //return;
    } else if (this.state.transferAddress==="") {
      this.setState({errorMessage: i18n.translate('err_addressEmpty')})
    } else if (!this.validateEmail(this.state.transferAddress)) {
      this.setState({errorMessage: i18n.translate('err_emailFormatError')})
    } else {
      if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {
        let user = this.props.globalUser.user;

        cookies.set('transfer_address', this.state.transferAddress, {path: '/'});
        cookies.set('transfer_amount', this.state.transferAmount, {path: '/'});

        if ((user.info!==undefined)&&(user.info.totpEnabled===0)) {
          this.props.history.push("/require2fa?ref=wrex_transfer")
        } else {
          this.props.history.push("/to2fa?ref=wrex_transfer")
        }
      } else {
        this.props.history.push("/my_assets")
      }

    }


  }


  handleTransferEmailSelect = (index, item) => {

    console.log("handleTransferAddressChange");
//console.log(item);
    this.setState({errorMessage: ""});

    if (item!==undefined){
      this.setState({transferAddress: item.getAttribute('data-value')})
      this.setState({transferAddressHolder: ""})
      //console.log("dddd: ", item);
    }

  }

  handleAccountClick (address) {  
    console.log("handleTransferAddressChange");
//console.log(address);
    this.setState({errorMessage: ""});

    this.setState({transferAddressHolder: ""})
    this.setState({transferAddress: address})
    this.setState({anchorEl : !this.state.anchorEl});
  }

  handleTransferWrexAccountChange (event) {  
    //console.log("handleTransferAddressChange");
//console.log(event.target.value);
    this.setState({errorMessage: ""});

    if (event.target.value!=="") {
      this.setState({transferAddressHolder: ""})
      
    } else {
      this.setState({transferAddressHolder: i18n.translate('enteraddressholder')})
    }
    this.setState({transferAddress: event.target.value})
  }

  handleTransferAmountChange(event)  {
    //console.log("handleTransferAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    var accountWrex = {value: 0};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.wrex!==undefined) {
        accountWrex.value = parseFloat(account.wrex.value);
      }
    }

    if (event!==undefined){
      this.setState({transferAmount: event.target.value})
      if (event.target.value!=="") {
        const transferAmount = parseFloat(event.target.value);
        if (transferAmount>accountWrex.value) {
          //this.setState({errorMessage: i18n.translate('err_overWrexValue')})
          this.setState({canRequest: false})
        //} else if (transferAmount<DEFAULT_NETWORK_FEE) {
          //this.setState({errorMessage: i18n.translate('err_lessThenNetworkFee')})
          //this.setState({canRequest: false})

        } else {
          this.setState({canRequest: true})
        }
      } else {
        this.setState({canRequest: false})
      }
    }

  }

  
  handleSelectWrexAccount = (event) => {
//console.log(this.state.anchorEl);
    this.setState({anchorEl : !this.state.anchorEl});
  }

  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Wrex Transfer Error",this);
      //return renderErrorMessage(i18n.translate(this.state.errorMessage),this);
    }
    //let self = this;
    var wrex = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.wrex!==undefined) {
        wrex.value = parseFloat(account.wrex.value);
      }
    }

    // console.log("j: ", this.props.globalUser);
    //console.log(this.props.globalAccount);

    return (
      <div>
						{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	
				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="wrex"
                                        />

				

				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">

						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><a href="wrex_account">{i18n.translate('wrex')}</a><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('transfer')}</h1>
							</div>
              <div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-5-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(wrex.value)}<span>ETH</span></h1>
								</div>
							</div>
						</div>
		 

						<div className="mdc-layout-grid">
							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('wt1')}</span>
								  </h2>
								</div>
							</div>
              </div>

							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-0-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-10-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
							                              <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
											<p className="f_formtitle">{i18n.translate('amount')}</p>
											<div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
													<input type="text" id="withdraw-amount-text-field" className="mdc-text-field__input" 
                       value={this.state.transferAmount}
                       onChange={this.handleTransferAmountChange.bind(this)}
                     />
											</div>
                      </div>
                      <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div> 
                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
                      <div className="mdc-layout-grid__inner f_mbottom25">
											<div className="mdc-layout-grid__cell--span-5-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-2-phone">
											<p className="f_formtitle" style={{ 'margin-top': '5%'}}>{i18n.translate('transferto')}</p> 
                      </div>
											<div className="mdc-layout-grid__cell--span-7-desktop mdc-layout-grid__cell--span-5-tablet f_txtr mdc-layout-grid__cell--span-2-phone">
                      </div></div>
                      <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
                      <input type="text" 
                          id="withdraw-address-text-field" 
                          className="mdc-text-field__input" 
                          value={this.state.transferWrexAccount} 
                          onChange={this.handleTransferWrexAccountChange.bind(this)}
                      />
                      <label htmlFor="withdraw-address-text-field" className="mdc-floating-label">{this.state.transferAddressHolder}</label>
                      </div>
											</div>
                      <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet">
                      </div>

                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											<div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone f_btncenter ">
											<span className="mdc-button mdc-button--unelevated big-round-corner-button" 
                                                                                            onClick={() => this.handleWrexTransferRequest()}
                                                                                          >
                                                                                            {i18n.translate('send')}
                                                                                          </span>
											</div>
                      <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
											</div>
										</form>
									  </div>

									</div>
								  </div>
								</div>

                <div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-1-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="f_rg_ptop mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('wrextransfernotices')}</span>
									<span className="crypto-widget__heading-border"></span>
								  </h2>
								  <div className="crypto-widget__content">
										<ul className="li_notice">
											<li>{i18n.translate('wt2')}</li>
											</ul>
								  </div>
								</div>
							  </div>
							  </div>


							  </div>
              </div>

							
						</div>


					</div>

				  </main>

				</div>
      </div>

    )
  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    globalUser: state.entities.user,
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,

  }
}

export default withRouter(connect(mapStateToProps, {
  //sendAccountTransfer
})(WrexTransfer))





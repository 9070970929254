/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import i18n from 'i18n-react';

//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import countryList from 'react-select-country-list'
import { refineISOCountryList } from '../helper/countrylistHelper'

//import { getAddressString }  from '../helper/displayHelper'

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class Profile extends Component {

  static propTypes = {
  }

  //constructor(props) {
    //super(props);

    //this.state = {
      //errorMessage: "",
    //}

  //}

  componentDidMount() {
    //console.log("ych Profile");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Profile - WREX"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  render() {
    var userInfo = {accountType: "", countryCode: "", email: "", id: "", level: ""}
    if ((this.props.globalUser!==undefined)&&(this.props.globalUser.user!==undefined)) {   
      let user = this.props.globalUser.user;
      if (user.info!==undefined) {
        userInfo = user.info;
      }
		}
		
		this.countryList = refineISOCountryList(countryList);

    return (
      <div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}	

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="profile"
                                        />


				  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
								<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-4-phone">
									<h6 className="f_breadcrumb">
                                                                        </h6>
									<h1 className="crypto-card__header--title">{i18n.translate('account')} </h1>
									<h4 className="f_mode">
                                                                        </h4>
								</div>
							</div>
		  

						  <div className="mdc-layout-grid fx_padding">
							<div className="mdc-layout-grid__inner">
							  <div className="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<ul className="crypto-border-lisxxt">
									  <li>
										<span className="crypto-border-list__item"
                                                                                >
										  <span className="crypto-border-list__text mdc-theme--text-primary-on-background">{i18n.translate('myinfo')}</span>
										  <span className="crypto-border-list__border mdc-theme--primary-bg"></span>
										  <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
										</span>
									  </li>
									  <li>
										<Link to="account_security" className="crypto-border-list__item"
                                                                                >
										  <span className="crypto-border-list__text mdc-theme--text-primary-on-background">{i18n.translate('securitysettings')}</span>
										  <span className="crypto-border-list__border mdc-theme--primary-bg"></span>
										  <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
										</Link>
									  </li>
									  <li>
										<Link to="account_address" className="crypto-border-list__item"
                                                                                >
										  <span className="crypto-border-list__text mdc-theme--text-primary-on-background">{i18n.translate('addressmanagement')}</span>
										  <span className="crypto-border-list__action"><i className="material-icons">chevron_right</i></span>
										  <span className="crypto-border-list__border mdc-theme--primary-bg"></span>
										  <span className="crypto-border-list__border-hover mdc-theme--secondary-bg"></span>
										</Link>
									  </li>
									</ul>
								  </div>
								</div>
							  </div>
							  <div className="mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">


											<div className="mdc-layout-grid__cell--span-12">
											  <div className="crypto-margin-bottom-big crypto-w-100">
												<div className="crypto-widget">
												  <div className="crypto-widget__content">
													<div className="crypto-profile">
													  <span className="crypto-profile__image mdc-elevation--z16">
														<img  src="assets/images/avatar-demo.png" width={80} alt="Crypto" />
													  </span>
													  <span className="crypto-price-list__coin-name">{i18n.translate('userlevel')}</span>
													  <span className="">{userInfo.level}</span>
													  <span className="crypto-price-list__coin-name">{i18n.translate('usertype')}</span>
													  <span className="">{userInfo.accountType}</span>
													</div>
												  </div>
												</div>
											  </div>
											</div>

											<div className="mdc-layout-grid__cell--span-12">
												<p className="f_formtitle">{i18n.translate('email')}</p>
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="email" id="email-text-field" className="mdc-text-field__input" value={userInfo.email} disabled />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>
											<div className="mdc-layout-grid__cell--span-6">
											  <p className="f_formtitle">{i18n.translate('nationality')}</p>
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="first-name-text-field" className="mdc-text-field__input" value={refineISOCountryList()().getLabel(userInfo.countryCode)} disabled />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>
					
										  </div>
										</form>
									  </div>

								  </div>
								</div>
							  </div>
							</div>
						  </div>


					</div>
					</div>
				  </main>

	

			</div>

				        </div>

    )
  }






}




const mapStateToProps = (state, ownProps) => ({
    globalUser: state.entities.user,
})

export default withRouter(connect(mapStateToProps, {
})(Profile))





/* eslint-disable no-undef */

import React, { Component } from 'react'
//import { Container, Row, Col } from 'reactstrap'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
//import Cookies from 'universal-cookie';
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { getETHDisplayString }  from '../helper/displayHelper'

import { sendAccountWREXWithdraw, sendAccountInfo, API_SUCCESS } from '../actions'

import { renderErrorMessage } from '../components/ErrorMessage'

import i18n from 'i18n-react';

import {getToken} from '../helper/loginHelper';

import PreLoaderWidget from '../components/Loader';
import SideNav from '../components/SideNav';

//import '../assets/css/custom.css';
//import '../assets/css/night-gold-orange.css';

//import 'pace-js'

//import 'pace-js/themes/blue/pace-theme-minimal.css'

//import logo_wrex from '../assets/images/logo_wrex.svg';
//import kv_banner from '../assets/images/kv_banner.jpg';

//const cookies = new Cookies();
class WrexWithdraw extends Component {
  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      fee: "",
      get: "",
      errorMessage: "",
      isClickable: true,
    }

  }

  componentDidMount() {
    //console.log("ych WrexWithdraw");
    //window.addEventListener('resize', this.handleResize)
    document.title = "Withdraw - WREX Account"

    document.body.className="mdc-typography mdc-theme--background crypro-theme-gradient";

  }

  componentWillUnmount() {
    //window.removeEventListener('resize', this.handleResize);
  }

  handleAmountChange(event)  {
    //console.log("handleAmountChange");
    //console.log(event);
    this.setState({errorMessage: ""})

    if (event!==undefined){
      if (event.target.value!=="") {
        //this.setState({emailAddressPlaceHolder: ""})
      } else {
        //this.setState({emailAddressPlaceHolder: i18n.translate('emailAddressPlaceHolder')})
      }
      this.setState({amount: event.target.value})
      if (event.target.value!=="") {
        this.setState({fee: event.target.value*0.01})
        this.setState({get: event.target.value*0.99})
      } else {
        this.setState({fee: ""})
        this.setState({get: ""})
      }
    }

  }

  handleAccountWREXWithdraw() {
    //console.log("handleAccountWREXWithdraw");

    if (this.state.isClickable===false) {
      //console.log("isClickable===false");
      return;
    }
    //} else {

      //this.props.history.push("/wrex_withdraw_contact?ref=wrex_withdraw")
//return;
    //}
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {  
        if (this.props.globalAccount.account.wrex.withdrawable===true) {
//console.log("OK");
        } else {
            this.props.history.push("/wrex_withdraw_contact?ref=wrex_withdraw")
	    return;
        }
    } else {
        this.props.history.push("/wrex_withdraw_contact?ref=wrex_withdraw")
	return;
    }

    this.setState({errorMessage: ""})
    var self = this;

    let token = getToken(this);

/*
    var wrex = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {  
      let account = this.props.globalAccount.account;
      if (account.wrex!==undefined) {
        wrex.value = parseFloat(account.wrex.value);
        if ((this.state.amount>wrex.value)||((wrex.value-this.state.amount<1)&&(wrex.value-this.state.amount>0))) {
          this.setState({errorMessage: i18n.translate('invalid_wrex_withdraw_amount')})
          return;
        }
      }
    }
*/

    this.setState({isClickable: false})

    this.props.sendAccountWREXWithdraw(token, { amount: this.state.amount
    })
    .then (function (success) {
      //console.log("success");
      //console.log(success);
      if (success.type === API_SUCCESS) {
        let response = success.response.entities.response.response;
        //console.log(response);
        if (response.success===false) {
          self.setState({errorMessage: i18n.translate(response.message)})
        } else {
          self.props.sendAccountInfo(token,{});
          self.props.history.push("/wrex_withdraw_request")
        }


      } else {
        self.setState({errorMessage: i18n.translate('unknown_error')})

      }
      self.setState({isClickable: true})

    })
    .catch(function (error) {
      console.log("error");
      console.log(error);
      self.setState({errorMessage: i18n.translate('unknown_error')})
      self.setState({isClickable: true})
    })
  }


  render() {

    if (this.state.errorMessage!=="") {
      return renderErrorMessage("Wrex Withdraw Error",this);
    }

    //console.log(this.props.globalAccount);
    var wrex = {value: ""};
    if ((this.props.globalAccount!==undefined)&&(this.props.globalAccount.account!==undefined)) {
      let account = this.props.globalAccount.account;
      if (account.wrex!==undefined) {
        wrex.value = parseFloat(account.wrex.value);
      }
    }

    return (
      <div>
				{ /* preloader */}
                    {this.props.loading && <PreLoaderWidget />}					

				<div className="crypto-wrapper">

				         <SideNav
                                        ActiveTag="wrex"
                                        />

								  <main className="crypto-main">

					<div className="crypto-main__content mdc-theme--text-primary-on-background f_tpadding_0">
 
						<div className="f_header mdc-elevation--z24 mdc-layout-grid__inner">
							<div className="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-4-phone">
							  <h6 className="f_breadcrumb"><Link to="wrex_account">{i18n.translate('wrex')}</Link><i className="material-icons crypto-navigation__list-item__icon" aria-hidden="true">arrow_right</i></h6>
							  <h1 className="crypto-card__header--title">{i18n.translate('withdraw')}</h1>
							</div>
              <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-card__header__heading f_divider">
								  <h1 className="mdc-typography--headline2 crypto-margin-none crypto-display-flex mdc-theme--primary crypto-text-shadow crypto-wallet-overview__balancexx">{getETHDisplayString(wrex.value)}<span>ETH</span></h1>
								</div>
							</div>
						</div>
		 

						  <div className="mdc-layout-grid">
							<div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div>
							  <div className="mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <h2 className="crypto-widget__heading2 crypto-widget__heading--fullwidth  mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('sendtomyethwallet')}</span>
								  </h2>
								</div>
							</div>
              </div>

              <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div> 
                <div className="f_formlarge mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
								<div className="crypto-widget">
								  <div className="crypto-widget__content">
									<div className="settings-form-panels">
									  <div className="crypto-settings-form-panel crypto-settings-form-panel--active">
										<form className="crypto-general-form">
										  <div className="mdc-layout-grid__inner">
											<div className="mdc-layout-grid__cell--span-12">
                        <p className="f_formtitle">{i18n.translate('amount')}</p>
											  <div className="mdc-text-field mdc-text-field--transparent crypto-margin-bottom-big crypto-w-100">
												<input type="text" id="amount-text-field" className="mdc-text-field__input" 
                              value={this.state.amount}
                              onChange={this.handleAmountChange.bind(this)}
                        />
												<div className="mdc-line-ripple"></div>
											  </div>
											</div>
											<div className="mdc-layout-grid__cell--span-12 f_btncenter">
											  <span className="mdc-button mdc-button--unelevated big-round-corner-button f_btnfull"
 											onClick={() => this.handleAccountWREXWithdraw()}

											>{i18n.translate('request')}</span>
											</div>
										  </div>
										</form>
									  </div>
									</div>
								  </div>
								</div>
                </div>
                </div>

                <div className="mdc-layout-grid__inner">
                <div className="mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-1-tablet"></div> 
                <div className="f_formlarge mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-4-phone">
									<div className="mdc-layout-grid__cell--span-12">
										<h4>{i18n.translate('transferfee')} : {getETHDisplayString(this.state.fee)} ETH</h4>
										<h4>{i18n.translate('youwillget')} : {getETHDisplayString(this.state.get)} ETH</h4>
									</div>
								  <div className="crypto-widget">
								  <h2 className="crypto-widget__heading crypto-widget__heading--fullwidth mdc-theme--primary">
									<span className="crypto-widget__heading-text">{i18n.translate('manualwithdrawpolices')}</span>
									<span className="crypto-widget__heading-border"></span>
									</h2>
									<div className="crypto-widget__content">
											<ul className="li_notice">
												<li className="crypto-word-warp">{i18n.translate('ww1')}</li>
												<li className="crypto-word-warp">{i18n.translate('ww2')}</li>
												<li className="crypto-word-warp">{i18n.translate('ww3')}</li>
												<li className="crypto-word-warp">{i18n.translate('ww4')} </li>
												</ul>
										</div>
									</div>
								</div>
							  </div>

							</div>
						  </div>
				  </main>

				</div>
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
    globalAccount: state.entities.account,
    globalLogin: state.entities.login,
})

export default withRouter(connect(mapStateToProps, {
  sendAccountWREXWithdraw, sendAccountInfo

})(WrexWithdraw))




